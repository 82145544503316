$footer-border-color: rgba($white, .2);

.app-footer {
    width: 100%;
    min-height: 80px;

    // margin-top: 24px;
    .b-l {
        border-left: solid 1px rgba($secondary-fg-color, .2);

        &:lang(ar) {
            border-left: none;
            border-right: solid 1px rgba($secondary-fg-color, .2);
        }
    }

    .copyright {
        font-size: $font-size-s;
        line-height: 1.2;
        color: rgba($secondary-fg-color, .5);
        margin-top: 10px;
        margin-bottom: 15px;

        @media (max-width: 767px) {
            float: right;
        }
    }
}

.footer-top {
    padding: 20px 35px;
    background-color: #071544;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        padding: 15px;
    }
}

.footer-bottom {
    padding: 10px 35px;
    background-color: #13296F;

    .row {
        align-items: center;
    }

    @include media-breakpoint-down(md) {
        padding: 15px;
    }
}

.footer-logo {
    width: 60px;

    img {
        width: 100%;
        object-fit: contain;
        display: inline-block;
        filter: grayscale(100%);

        &:hover {
            filter: none
        }
    }

    @media (max-width: 767px) {
        width: 48px;
        float: left;
    }
}

.footer-list-head {
    color: $secondary-fg-color;
}

.footer-link-list {
    list-style: none;

    &:not(.last) {
        padding: 0;
    }

    margin: 0;

    >li,
    span {
        margin: 5px 0;

        &:first-child {
            // margin-top: 0;
        }

        >a {
            font-size: $font-size-s;
            line-height: 20px;
            color: $secondary-fg-color;
            text-decoration: none;
            display: table;
            text-transform: uppercase;

            &:lang(mal) {
                font-size: 10px;
            }

            &:hover {
                // text-decoration: underline;
                color: $red;
            }

            &.active {
                color: $red;
                text-decoration: underline;
            }
        }
    }

    @media (max-width: 767px) {
        // display: none;
    }
}

.social-navigation {
    .social-nav-lbl {
        display: block;
        color: rgba($white, .6);
        font-size: $font-size-xs2;
        line-height: 1.6;

        @include media-breakpoint-up('sm') {
            font-size: $font-size-s;
            float: left;
        }
    }

    >a {
        display: inline-block;
        width: 24px;
        height: 24px;
        color: rgba($white, .6);
        margin-right: 10px;
        text-align: center;
        font-size: 18px;
        line-height: 28px;

        @include media-breakpoint-up('sm') {
            float: left;
            margin: 0 10px;
        }
    }
}

.vinfotech-logo {
    width: 117px;
    display: block;
    color: rgba($white, .5);
    float: right;

    &:lang(ar) {
        float: left;
    }

    .lbl {
        font-size: $font-size-xs2;
        margin-bottom: 3px;
    }

    .icon-vinfotech {
        font-size: 20px;
        color: rgba($white, .5);
        transition: color .1s ease-out;

        &:hover {
            color: rgba($white, .7);
        }
    }

    // @include media-breakpoint-up('sm') {
    //     float: right;
    // }
}

.credit-text-wrap {
    text-align: center;
    margin-top: 10px;
    padding-top: 10px;
    border-top: solid 1px rgba($white, .2);

    br {
        display: none;
    }

    span {
        display: inline-block !important;
    }
}

.footer-sm {
    @include media-breakpoint-down(md) {
        font-size: 10px;
    }
}

.footer-btm {
    color: #9C9C9C;

    a {
        color: #9C9C9C;
    }

    i {
        font-size: 20px;
        margin-left: 20px;
        cursor: pointer;
    }
}

.wtmart-remove-res {
    @include media-breakpoint-down(md) {
        display: none;
    }
}

// color: $white;
// font-family: "Exo 2";
// font-size: 14px;
// letter-spacing: 0;
// line-height: 20px
.h-o-s {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.footer-top-sm {
    display: flex;
    justify-content: start;
    padding: 0 20px;
    position: relative;

    .fc {
        margin-right: 60px !important;
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.footer-rt-img {
    position: absolute;
    background-image: url('../img/hexagon.svg');
    top: 20%;
    right: 0;
    bottom: unset;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    filter: invert(14%) sepia(52%) saturate(2640%) hue-rotate(220deg) brightness(94%) contrast(20%);
    opacity: 0.3;
    pointer-events: none;
    rotate: 60deg;
    width: 350px;
    height: 350px;
}

.footer-bottom-sm {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(md) {
        display: none;
    }
}