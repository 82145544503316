.roster-filter-wrap {
    position: relative;
    // border:  solid 1px $gray-col;
    min-height: 107px;
    border-radius: 5px 5px 0 0;
    padding: 20px 0;
    background: $white;

    .tb-row {
        display: flex;
        margin-top: 8px;
        padding: 0 15px;
        align-items: center;

        &:first-child {
            margin-top: 0;
        }
    }

    .tb-cell {
        flex: 1 1;
        padding: 0 5px;


        &:first-child {
            max-width: 146px;
        }

        &.full-width {
            max-width: 100% !important;
        }
    }

    .filter-btn {
        width: 44px;
        height: 44px;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid $primary;
        font-size: 24px;
        color: $primary;
        // color: $secondary-fg-color; ---> for light theme
        padding: 5px;
        margin: 21px 5px 0;

        &.open {
            background-color: $primary;
            border: solid 1px $primary;
            color: $white;
        }

        @media (max-width:767px) {
            width: 36px;
            height: 36px;
            font-size: 20px;
            margin: 20px 5px 0;
        }
    }

    .filter-lbl {
        min-height: 21px;
        font-size: $font-size-xs;
        padding-bottom: 3px;
        color: #666666;
        &:lang(mal) {
            font-size: 11px;
        }
    }
}

.filter-search {
    position: relative;
    margin-top: 21px;

    .form-group {
        margin: 0;
    }

    .filter-dropdown-toggle {
        background: #EEEEEE;
        outline: none;
        padding: 4px 30px 4px 13px;
        width: 100%;
        height: 44px;
        text-transform: capitalize;
        box-shadow: none;
        border: none;
        font-size: $font-size-s;
        color: #333;
        border-bottom: 1px solid #999999;

        &::placeholder {
            color: #333333 !important;
        }
    }

    .icon-search {
        pointer-events: none;
        position: absolute;
        color: #999999 !important;
        top: 11px;
        right: 11px;
        font-size: 20px;

        &:lang(ar) {
            right: auto;
            left: 11px;
        }
    }
}

.filter-extend {
    margin-top: 8px;
    padding: 15px 0;
    background: #EEEEEE;
}


.range-slider-wrap {
    padding: 5px 18px 20px 18px;
    width: 78%;

    // .rc-slider-handle {
    //     z-index: 1;
    // }
    .rc-slider {
        width: 80%;
        margin: 0 auto;
    }

    .rc-slider-mark {
        .rc-slider-mark-text {
            color: #212121;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 14px;
            left: calc(100% + 35px) !important;
            top: -17px;

            &:first-child {
                left: -48px !important;
                // top: -18px;
            }
        }
    }
}

// Player List
.player-list {
    background-color: $white;
    overflow-y: auto;
    max-height: 760px;
    position: relative;
    border: 1px solid rgba($secondary-fg-color, 0.2);
    border-radius: 0 0 9px 9px;
    padding: 10px 0;
    min-height: 4px;

    &.m-t {
        margin-top: 15px;
    }
    @media (max-width:767px) {
        max-height: 645px;
    }

    @media (max-width:420px) {
        max-height: 565px;
    }

}

.player-item {
    cursor: pointer;
    display: flex;
    // border-top: solid 1px #E9E9E9;
    color: $dark-color;
    align-items: center;
    height: 60px;

    &:first-child {
        border-top: none;
    }

    >* {
        flex: 1 1;
    }

    .pl-rank {
        text-align: center;
        font-weight: $font-weight-semibold;
        font-size: $font-size-m;
        max-width: 40px;
        min-width: 40px;

        &+.thumb-wrap {
            margin-left: 0;
        }
    }

    .thumb-wrap {
        min-width: 37px;
        max-width: 37px;
        position: relative;
        margin-left: 15px;
    }

    .thumb {
        width: 37px;
        height: 37px;
        object-fit: cover;
        object-position: center top;
        border-radius: 100%;
    }

    .flag {
        width: 15px;
        height: 15px;
        object-fit: contain;
        position: absolute;
        bottom: 0;
        right: -7px;

        &:lang(ar) {
            right: auto;
            left: -7px;
        }
    }

    .player-info {
        padding-left: 19px;
        line-height: 1.3;
        min-width: 0;
    }

    .player-name {
        font-weight: $font-weight-semibold;
        font-size: $font-size-s;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .player-team {
        font-size: $font-size-xs;
        text-transform: uppercase;
        color: $gray-500;
    }

    .cell {
        font-weight: 500;
        font-size: $font-size-s;
        max-width: 50px;
        min-width: 50px;
        text-align: center;
        color: #666666;
        &.salary{
            min-width: 58px;
            max-width: 58px;
        }
    }

    .player-toggle {
        width: 28px;
        height: 28px;
        border-radius: 3px;
        border: solid 1px rgba($gray-col, .25);
        background-color: transparent;
        font-size: 14px;
        color: $primary;
        padding: 4px;
        cursor: pointer;

        &.selected {
            background-color: $primary;
            color: $white;
            border-color: darken($primary, 8%);
        }
    }
}

.player-list-header {
    background: $white;
    display: flex;
    align-items: center;
    height: 30px;
    // margin: 20px 0 10px;
    color: $primary;
    font-weight: $font-weight-semibold;
    font-size: $font-size-xs;

    @include media-breakpoint-down(md) {
        height: 40px;
        // margin: 20px 0 0px;
    }

    >* {
        flex: 1 1;
    }

    .pl-rank {
        text-align: center;
        max-width: 40px;
        min-width: 40px;

        &+.player-info {
            margin-left: 0;
        }
    }

    .player-info {
        margin-left: 15px;
    }

    .cell {
        // max-width: 60px;
        // min-width: 50px;
        // text-align: center;
        max-width: 85px;
        min-width: 75px;
        text-align: left;

        @include media-breakpoint-down(md) {
            max-width: 89px;
            min-width: 60px;
            text-align: center;
        }
    }
}

.bottom-pitch-control {
    text-align: center;
}


.player-list-wrap {
    position: relative;
    min-height: 107px;
}

.tac {
    text-align: center;
    // color: #000;
    margin-top: -12px;
    font-weight: 500;
    font-size: 12px;
    opacity: 0.7;

    // background-color: rgba(0,0,0,0.3);
    @include media-breakpoint-down(md) {
        margin-top: 0 !important;
        display: none;
    }
}

.bg-clr-fx {
    .react-select__control {
        background: transparent !important;
    }
}

.flex-for-price {
    display: block;
    //align-items: center;
    //justify-content: space-between;

    .filter-lbl {
       // height: 33px;
        padding-bottom: 5px;
        span {
            color: #212121;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 14px;
        }
    }
    .range-slider-wrap{
        width: 100%;
        padding: 0;
        .rc-slider{
            width: 60%;
        }
        .rc-slider-mark {
            .rc-slider-mark-text{
                min-width: 50px;
                +.rc-slider-mark-text{
                    margin-left: 12px;
                }
            }
        }
    }
}

.tp-atl {
    @include media-breakpoint-down(md) {
        text-align: left !important;
    }
}

.h-fix-pl {
    max-height: 680px;
}
.player-status{
    text-transform: uppercase;
    font-size: 11px;
    margin-left: 6px;
    border-radius: 2px;
    letter-spacing: 0;
    line-height: 13px;
    padding: 1px 6px;
    font-weight: 500;
    &.red {
        background-color: rgba($red, 1);
        font-size: 9px;
        color: $white;
    }
    &.gray {
        background-color: rgba($gray-999, 1);
        color: $white;
    }
    &.yellow {
        background-color: rgba($yellow-light, 1);
        color: $black;
    }
}
.player-status-icon{
    position: absolute;
    top: -7px;
    left: -10px;
    color: #F6C426;
}