@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-Bold.eot');
    src: url('Inter18pt-Bold.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Bold.woff2') format('woff2'),
        url('Inter18pt-Bold.woff') format('woff'),
        url('Inter18pt-Bold.ttf') format('truetype'),
        url('Inter18pt-Bold.svg#Inter18pt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-Black.eot');
    src: url('Inter18pt-Black.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Black.woff2') format('woff2'),
        url('Inter18pt-Black.woff') format('woff'),
        url('Inter18pt-Black.ttf') format('truetype'),
        url('Inter18pt-Black.svg#Inter18pt-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-ExtraBoldItalic.eot');
    src: url('Inter18pt-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-ExtraBoldItalic.woff2') format('woff2'),
        url('Inter18pt-ExtraBoldItalic.woff') format('woff'),
        url('Inter18pt-ExtraBoldItalic.ttf') format('truetype'),
        url('Inter18pt-ExtraBoldItalic.svg#Inter18pt-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-BoldItalic.eot');
    src: url('Inter18pt-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-BoldItalic.woff2') format('woff2'),
        url('Inter18pt-BoldItalic.woff') format('woff'),
        url('Inter18pt-BoldItalic.ttf') format('truetype'),
        url('Inter18pt-BoldItalic.svg#Inter18pt-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-ExtraBold.eot');
    src: url('Inter18pt-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-ExtraBold.woff2') format('woff2'),
        url('Inter18pt-ExtraBold.woff') format('woff'),
        url('Inter18pt-ExtraBold.ttf') format('truetype'),
        url('Inter18pt-ExtraBold.svg#Inter18pt-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-BlackItalic.eot');
    src: url('Inter18pt-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-BlackItalic.woff2') format('woff2'),
        url('Inter18pt-BlackItalic.woff') format('woff'),
        url('Inter18pt-BlackItalic.ttf') format('truetype'),
        url('Inter18pt-BlackItalic.svg#Inter18pt-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-MediumItalic.eot');
    src: url('Inter18pt-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-MediumItalic.woff2') format('woff2'),
        url('Inter18pt-MediumItalic.woff') format('woff'),
        url('Inter18pt-MediumItalic.ttf') format('truetype'),
        url('Inter18pt-MediumItalic.svg#Inter18pt-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-Light.eot');
    src: url('Inter18pt-Light.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Light.woff2') format('woff2'),
        url('Inter18pt-Light.woff') format('woff'),
        url('Inter18pt-Light.ttf') format('truetype'),
        url('Inter18pt-Light.svg#Inter18pt-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-Italic.eot');
    src: url('Inter18pt-Italic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Italic.woff2') format('woff2'),
        url('Inter18pt-Italic.woff') format('woff'),
        url('Inter18pt-Italic.ttf') format('truetype'),
        url('Inter18pt-Italic.svg#Inter18pt-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-Medium.eot');
    src: url('Inter18pt-Medium.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Medium.woff2') format('woff2'),
        url('Inter18pt-Medium.woff') format('woff'),
        url('Inter18pt-Medium.ttf') format('truetype'),
        url('Inter18pt-Medium.svg#Inter18pt-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-ExtraLightItalic.eot');
    src: url('Inter18pt-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-ExtraLightItalic.woff2') format('woff2'),
        url('Inter18pt-ExtraLightItalic.woff') format('woff'),
        url('Inter18pt-ExtraLightItalic.ttf') format('truetype'),
        url('Inter18pt-ExtraLightItalic.svg#Inter18pt-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-LightItalic.eot');
    src: url('Inter18pt-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-LightItalic.woff2') format('woff2'),
        url('Inter18pt-LightItalic.woff') format('woff'),
        url('Inter18pt-LightItalic.ttf') format('truetype'),
        url('Inter18pt-LightItalic.svg#Inter18pt-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-ExtraLight.eot');
    src: url('Inter18pt-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-ExtraLight.woff2') format('woff2'),
        url('Inter18pt-ExtraLight.woff') format('woff'),
        url('Inter18pt-ExtraLight.ttf') format('truetype'),
        url('Inter18pt-ExtraLight.svg#Inter18pt-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-SemiBold.eot');
    src: url('Inter18pt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-SemiBold.woff2') format('woff2'),
        url('Inter18pt-SemiBold.woff') format('woff'),
        url('Inter18pt-SemiBold.ttf') format('truetype'),
        url('Inter18pt-SemiBold.svg#Inter18pt-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-Regular.eot');
    src: url('Inter18pt-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Regular.woff2') format('woff2'),
        url('Inter18pt-Regular.woff') format('woff'),
        url('Inter18pt-Regular.ttf') format('truetype'),
        url('Inter18pt-Regular.svg#Inter18pt-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-Black.eot');
    src: url('Inter24pt-Black.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-Black.woff2') format('woff2'),
        url('Inter24pt-Black.woff') format('woff'),
        url('Inter24pt-Black.ttf') format('truetype'),
        url('Inter24pt-Black.svg#Inter24pt-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-ExtraBoldItalic.eot');
    src: url('Inter24pt-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-ExtraBoldItalic.woff2') format('woff2'),
        url('Inter24pt-ExtraBoldItalic.woff') format('woff'),
        url('Inter24pt-ExtraBoldItalic.ttf') format('truetype'),
        url('Inter24pt-ExtraBoldItalic.svg#Inter24pt-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-ExtraBold.eot');
    src: url('Inter24pt-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-ExtraBold.woff2') format('woff2'),
        url('Inter24pt-ExtraBold.woff') format('woff'),
        url('Inter24pt-ExtraBold.ttf') format('truetype'),
        url('Inter24pt-ExtraBold.svg#Inter24pt-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-SemiBoldItalic.eot');
    src: url('Inter18pt-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-SemiBoldItalic.woff2') format('woff2'),
        url('Inter18pt-SemiBoldItalic.woff') format('woff'),
        url('Inter18pt-SemiBoldItalic.ttf') format('truetype'),
        url('Inter18pt-SemiBoldItalic.svg#Inter18pt-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-ThinItalic.eot');
    src: url('Inter18pt-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-ThinItalic.woff2') format('woff2'),
        url('Inter18pt-ThinItalic.woff') format('woff'),
        url('Inter18pt-ThinItalic.ttf') format('truetype'),
        url('Inter18pt-ThinItalic.svg#Inter18pt-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 18pt';
    src: url('Inter18pt-Thin.eot');
    src: url('Inter18pt-Thin.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Thin.woff2') format('woff2'),
        url('Inter18pt-Thin.woff') format('woff'),
        url('Inter18pt-Thin.ttf') format('truetype'),
        url('Inter18pt-Thin.svg#Inter18pt-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-BoldItalic.eot');
    src: url('Inter24pt-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-BoldItalic.woff2') format('woff2'),
        url('Inter24pt-BoldItalic.woff') format('woff'),
        url('Inter24pt-BoldItalic.ttf') format('truetype'),
        url('Inter24pt-BoldItalic.svg#Inter24pt-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-Bold.eot');
    src: url('Inter24pt-Bold.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-Bold.woff2') format('woff2'),
        url('Inter24pt-Bold.woff') format('woff'),
        url('Inter24pt-Bold.ttf') format('truetype'),
        url('Inter24pt-Bold.svg#Inter24pt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-BlackItalic.eot');
    src: url('Inter24pt-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-BlackItalic.woff2') format('woff2'),
        url('Inter24pt-BlackItalic.woff') format('woff'),
        url('Inter24pt-BlackItalic.ttf') format('truetype'),
        url('Inter24pt-BlackItalic.svg#Inter24pt-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-ExtraLightItalic.eot');
    src: url('Inter24pt-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-ExtraLightItalic.woff2') format('woff2'),
        url('Inter24pt-ExtraLightItalic.woff') format('woff'),
        url('Inter24pt-ExtraLightItalic.ttf') format('truetype'),
        url('Inter24pt-ExtraLightItalic.svg#Inter24pt-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-LightItalic.eot');
    src: url('Inter24pt-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-LightItalic.woff2') format('woff2'),
        url('Inter24pt-LightItalic.woff') format('woff'),
        url('Inter24pt-LightItalic.ttf') format('truetype'),
        url('Inter24pt-LightItalic.svg#Inter24pt-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-Medium.eot');
    src: url('Inter24pt-Medium.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-Medium.woff2') format('woff2'),
        url('Inter24pt-Medium.woff') format('woff'),
        url('Inter24pt-Medium.ttf') format('truetype'),
        url('Inter24pt-Medium.svg#Inter24pt-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-Light.eot');
    src: url('Inter24pt-Light.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-Light.woff2') format('woff2'),
        url('Inter24pt-Light.woff') format('woff'),
        url('Inter24pt-Light.ttf') format('truetype'),
        url('Inter24pt-Light.svg#Inter24pt-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-ExtraLight.eot');
    src: url('Inter24pt-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-ExtraLight.woff2') format('woff2'),
        url('Inter24pt-ExtraLight.woff') format('woff'),
        url('Inter24pt-ExtraLight.ttf') format('truetype'),
        url('Inter24pt-ExtraLight.svg#Inter24pt-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-Italic.eot');
    src: url('Inter24pt-Italic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-Italic.woff2') format('woff2'),
        url('Inter24pt-Italic.woff') format('woff'),
        url('Inter24pt-Italic.ttf') format('truetype'),
        url('Inter24pt-Italic.svg#Inter24pt-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-MediumItalic.eot');
    src: url('Inter24pt-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-MediumItalic.woff2') format('woff2'),
        url('Inter24pt-MediumItalic.woff') format('woff'),
        url('Inter24pt-MediumItalic.ttf') format('truetype'),
        url('Inter24pt-MediumItalic.svg#Inter24pt-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-BlackItalic.eot');
    src: url('Inter28pt-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-BlackItalic.woff2') format('woff2'),
        url('Inter28pt-BlackItalic.woff') format('woff'),
        url('Inter28pt-BlackItalic.ttf') format('truetype'),
        url('Inter28pt-BlackItalic.svg#Inter28pt-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-SemiBoldItalic.eot');
    src: url('Inter24pt-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-SemiBoldItalic.woff2') format('woff2'),
        url('Inter24pt-SemiBoldItalic.woff') format('woff'),
        url('Inter24pt-SemiBoldItalic.ttf') format('truetype'),
        url('Inter24pt-SemiBoldItalic.svg#Inter24pt-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-Regular.eot');
    src: url('Inter24pt-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-Regular.woff2') format('woff2'),
        url('Inter24pt-Regular.woff') format('woff'),
        url('Inter24pt-Regular.ttf') format('truetype'),
        url('Inter24pt-Regular.svg#Inter24pt-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-ThinItalic.eot');
    src: url('Inter24pt-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-ThinItalic.woff2') format('woff2'),
        url('Inter24pt-ThinItalic.woff') format('woff'),
        url('Inter24pt-ThinItalic.ttf') format('truetype'),
        url('Inter24pt-ThinItalic.svg#Inter24pt-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-SemiBold.eot');
    src: url('Inter24pt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-SemiBold.woff2') format('woff2'),
        url('Inter24pt-SemiBold.woff') format('woff'),
        url('Inter24pt-SemiBold.ttf') format('truetype'),
        url('Inter24pt-SemiBold.svg#Inter24pt-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 24pt';
    src: url('Inter24pt-Thin.eot');
    src: url('Inter24pt-Thin.eot?#iefix') format('embedded-opentype'),
        url('Inter24pt-Thin.woff2') format('woff2'),
        url('Inter24pt-Thin.woff') format('woff'),
        url('Inter24pt-Thin.ttf') format('truetype'),
        url('Inter24pt-Thin.svg#Inter24pt-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-Black.eot');
    src: url('Inter28pt-Black.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-Black.woff2') format('woff2'),
        url('Inter28pt-Black.woff') format('woff'),
        url('Inter28pt-Black.ttf') format('truetype'),
        url('Inter28pt-Black.svg#Inter28pt-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-Bold.eot');
    src: url('Inter28pt-Bold.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-Bold.woff2') format('woff2'),
        url('Inter28pt-Bold.woff') format('woff'),
        url('Inter28pt-Bold.ttf') format('truetype'),
        url('Inter28pt-Bold.svg#Inter28pt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-Medium.eot');
    src: url('Inter28pt-Medium.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-Medium.woff2') format('woff2'),
        url('Inter28pt-Medium.woff') format('woff'),
        url('Inter28pt-Medium.ttf') format('truetype'),
        url('Inter28pt-Medium.svg#Inter28pt-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-ExtraBoldItalic.eot');
    src: url('Inter28pt-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-ExtraBoldItalic.woff2') format('woff2'),
        url('Inter28pt-ExtraBoldItalic.woff') format('woff'),
        url('Inter28pt-ExtraBoldItalic.ttf') format('truetype'),
        url('Inter28pt-ExtraBoldItalic.svg#Inter28pt-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-Light.eot');
    src: url('Inter28pt-Light.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-Light.woff2') format('woff2'),
        url('Inter28pt-Light.woff') format('woff'),
        url('Inter28pt-Light.ttf') format('truetype'),
        url('Inter28pt-Light.svg#Inter28pt-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-ExtraLight.eot');
    src: url('Inter28pt-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-ExtraLight.woff2') format('woff2'),
        url('Inter28pt-ExtraLight.woff') format('woff'),
        url('Inter28pt-ExtraLight.ttf') format('truetype'),
        url('Inter28pt-ExtraLight.svg#Inter28pt-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-LightItalic.eot');
    src: url('Inter28pt-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-LightItalic.woff2') format('woff2'),
        url('Inter28pt-LightItalic.woff') format('woff'),
        url('Inter28pt-LightItalic.ttf') format('truetype'),
        url('Inter28pt-LightItalic.svg#Inter28pt-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-BoldItalic.eot');
    src: url('Inter28pt-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-BoldItalic.woff2') format('woff2'),
        url('Inter28pt-BoldItalic.woff') format('woff'),
        url('Inter28pt-BoldItalic.ttf') format('truetype'),
        url('Inter28pt-BoldItalic.svg#Inter28pt-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-ExtraLightItalic.eot');
    src: url('Inter28pt-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-ExtraLightItalic.woff2') format('woff2'),
        url('Inter28pt-ExtraLightItalic.woff') format('woff'),
        url('Inter28pt-ExtraLightItalic.ttf') format('truetype'),
        url('Inter28pt-ExtraLightItalic.svg#Inter28pt-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-Italic.eot');
    src: url('Inter28pt-Italic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-Italic.woff2') format('woff2'),
        url('Inter28pt-Italic.woff') format('woff'),
        url('Inter28pt-Italic.ttf') format('truetype'),
        url('Inter28pt-Italic.svg#Inter28pt-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-ExtraBold.eot');
    src: url('Inter28pt-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-ExtraBold.woff2') format('woff2'),
        url('Inter28pt-ExtraBold.woff') format('woff'),
        url('Inter28pt-ExtraBold.ttf') format('truetype'),
        url('Inter28pt-ExtraBold.svg#Inter28pt-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-Regular.eot');
    src: url('Inter28pt-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-Regular.woff2') format('woff2'),
        url('Inter28pt-Regular.woff') format('woff'),
        url('Inter28pt-Regular.ttf') format('truetype'),
        url('Inter28pt-Regular.svg#Inter28pt-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-SemiBoldItalic.eot');
    src: url('Inter28pt-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-SemiBoldItalic.woff2') format('woff2'),
        url('Inter28pt-SemiBoldItalic.woff') format('woff'),
        url('Inter28pt-SemiBoldItalic.ttf') format('truetype'),
        url('Inter28pt-SemiBoldItalic.svg#Inter28pt-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-ThinItalic.eot');
    src: url('Inter28pt-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-ThinItalic.woff2') format('woff2'),
        url('Inter28pt-ThinItalic.woff') format('woff'),
        url('Inter28pt-ThinItalic.ttf') format('truetype'),
        url('Inter28pt-ThinItalic.svg#Inter28pt-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-MediumItalic.eot');
    src: url('Inter28pt-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-MediumItalic.woff2') format('woff2'),
        url('Inter28pt-MediumItalic.woff') format('woff'),
        url('Inter28pt-MediumItalic.ttf') format('truetype'),
        url('Inter28pt-MediumItalic.svg#Inter28pt-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-SemiBold.eot');
    src: url('Inter28pt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-SemiBold.woff2') format('woff2'),
        url('Inter28pt-SemiBold.woff') format('woff'),
        url('Inter28pt-SemiBold.ttf') format('truetype'),
        url('Inter28pt-SemiBold.svg#Inter28pt-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter 28pt';
    src: url('Inter28pt-Thin.eot');
    src: url('Inter28pt-Thin.eot?#iefix') format('embedded-opentype'),
        url('Inter28pt-Thin.woff2') format('woff2'),
        url('Inter28pt-Thin.woff') format('woff'),
        url('Inter28pt-Thin.ttf') format('truetype'),
        url('Inter28pt-Thin.svg#Inter28pt-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}