@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/fonts/icomoon.eot?den7ld');
  src: url('../fonts/icomoon/fonts/icomoon.eot?den7ld#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/fonts/icomoon.ttf?den7ld') format('truetype'),
    url('../fonts/icomoon/fonts/icomoon.woff?den7ld') format('woff'),
    url('../fonts/icomoon/fonts/icomoon.svg?den7ld#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icomoon,
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-league:before {
  content: "\e928";
}

.icon-my-team:before {
  content: "\e929";
}

.icon-privacy:before {
  content: "\e926";
}

.icon-support:before {
  content: "\e927";
}

.icon-insta:before {
  content: "\e900";
}

.icon-fb:before {
  content: "\e901";
}

.icon-tw:before {
  content: "\e902";
}

.icon-menu:before {
  content: "\e903";
}

.icon-close:before {
  content: "\e904";
}

.icon-cancel:before {
  content: "\e904";
}

.icon-auto-flash:before {
  content: "\e905";
}

.icon-vinfotech:before {
  content: "\e906";
}

.icon-vinfo:before {
  content: "\e907";
}

.icon-clock:before {
  content: "\e908";
}

.icon-hourglass:before {
  content: "\e90e";
}

.icon-refresh:before {
  content: "\e90a";
}

.icon-previous:before {
  content: "\e909";
}

.icon-next:before {
  content: "\e90b";
}

.icon-down:before {
  content: "\e90c";
}

.icon-up:before {
  content: "\e90d";
}

.icon-filter:before {
  content: "\e90f";
}

.icon-search:before {
  content: "\e910";
}

.icon-check:before {
  content: "\e911";
}

.icon-plus:before {
  content: "\e912";
}

.icon-share:before {
  content: "\e913";
}

.icon-standing:before {
  content: "\e914";
}

.icon-eye:before {
  content: "\e915";
}

.icon-eye-hide:before {
  content: "\e916";
}

.icon-arrow-down:before {
  content: "\e917";
}

.icon-arrow-left:before {
  content: "\e918";
}

.icon-arrow-right:before {
  content: "\e919";
}

.icon-arrow-up:before {
  content: "\e91a";
}

.icon-copy:before {
  content: "\e91b";
}

.icon-about:before {
  content: "\e91c";
}

.icon-terms:before {
  content: "\e91d";
}

.icon-faq:before {
  content: "\e91e";
}

.icon-fixtures:before {
  content: "\e91f";
}

.icon-home:before {
  content: "\e920";
}

.icon-howplay:before {
  content: "\e921";
}

.icon-logout:before {
  content: "\e922";
}

.icon-others:before {
  content: "\e923";
}

.icon-scoring:before {
  content: "\e924";
}

.icon-statistics:before {
  content: "\e925";
}

.icon-apple:before {
  content: "\e92a";
}

.icon-bell:before {
  content: "\e92b";
}

.icon-swap:before {
  content: "\e92c";
}

.icon-setting:before {
  content: "\e92d";
}

.icon-prize:before {
  content: "\e92e";
}

.icon-profile-info:before {
  content: "\e92f";
}

.icon-calender:before {
  content: "\e930";
}

.icon-down-1:before {
  content: "\e931";
}

.icon-up-1:before {
  content: "\e932";
}

.icon-Statistics:before {
  content: "\e933";
}

.icon-Fixture:before {
  content: "\e934";
}

.icon-Tranfers:before {
  content: "\e935";
}

.icon-stopwatch:before {
  content: "\e939";
}

.icon-league-bn:before {
  content: "\e93a";
}

.icon-right:before {
  content: "\e93c";
}

.icon-left:before {
  content: "\e93b";
}

.icon-cs:before {
  content: "\e93e";
}

.icon-bell1:before {
  content: "\e93f";
}

.icon-whatsapp:before {
  content: "\e941";
}

.icon-emai:before {
  content: "\e940";
}

.icon-youtube:before {
  content: "\e942";
}

.icon-alert_ic:before {
  content: "\e943";
}

.icon-twitterx:before {
  content: "\e944";
}