.onboard-modal-header {
    @include clearfix;
    min-height: 184px;
    background-color: rgba($primary, 1);
    padding: 20px 15px;
    border-radius: 2px 2px 0 0;

    .close-btn {
        color: $white;
    }
}

.onboard-modal {
    // height: 90vh;
    overflow: auto;
    margin: auto;

    @media (max-width:1440px) {
        top: 2%;
    }
}

.landing-form-wrap {
    @include clearfix;
    background-color: $white;
}

.landing-form {
    width: 100%;
    max-width: 1093px;
    margin-top: 0;
    min-height: 143px;
    color: $dark;
    font-size: 14px;
    padding-bottom: 30px;

    @include media-breakpoint-down(md) {
        padding: 0 15px 30px;
    }

    .form-switch-wrap {
        margin-top: 25px;
        text-align: center;

        &.sm {
            color: $gray-600;
            font-size: 12px;

            .text-medium {
                color: $dark-color;
            }
        }

        &.float-right {
            float: none !important;
            margin-top: 0;
        }
    }

    .form-group {
        margin-bottom: 20px;
    }

    .fgt-pwd-btn {
        cursor: pointer;
        float: right;
        color: $gray-600;
        font-size: 14px;
        font-weight: $font-weight-light;
    }

    .m-t-20 {
        margin-top: 20px;

        &.sm {
            margin-top: 20px;
        }
    }

    .btn {
        // min-width: 100%;
        // font-size: 12px;
        // padding: 0.4rem 1rem;
    }

    .landing-form-title {
        text-align: center;
        text-transform: uppercase;
        margin: 44px 0 30px;
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
    }


}

.login-social-wrap {
    border-left: none;
    min-height: auto;

    .text-label {
        font-size: 14px;
        color: $gray-700;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    text-align: center;

    .fb-btn,
    .google-btn {
        border-radius: 4px;
        border: none;
        min-width: auto;
        white-space: nowrap;
        width: 44px;
        height: 44px;
        font-size: 14px;
        color: $white;
        text-transform: inherit;
        font-weight: $font-weight-normal;
        position: relative;
        text-align: left;
        box-shadow: none;

        background-color: #3B5998;

        &:not(:disabled):not(.disabled) {

            &:focus,
            &.focus {
                box-shadow: none;
            }
        }

        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            box-shadow: none;
            background-color: #3B5998;
        }

        span {
            display: none;
        }

        .icn {
            position: absolute;
            width: 44px;
            height: 44px;
            left: 0;
            top: 0;
            font-size: 24px;
            padding: 10px;
        }
    }

    .google-btn {
        color: $gray-600;
        background-color: #F5F5F5;
        margin-top: 0;

        .icn {
            padding: 10px;

            img {
                height: 100%;
                float: left;
            }
        }

        &:not(:disabled):not(.disabled):active:focus,
        &:not(:disabled):not(.disabled).active:focus {
            color: $gray-600;
            background-color: #F5F5F5;
        }

        @media (max-width: 945px) {
            margin-top: 0;

            .icn {
                padding: 10px;
            }
        }
    }

    .fb-btn+.google-btn {
        margin-left: 18px;
    }

    @media (max-width: 1170px) {
        padding-left: 30px;
        margin-left: 30px;
    }


    @include media-breakpoint-down(md) {
        // background: red;
        padding-left: 0;
        margin-left: 0;
        border-left: none;
        min-height: auto;
    }

    &.popup {
        .google-btn {
            margin-top: 0;

            .icn {
                padding: 10px;
            }
        }

        .fb-btn+.google-btn {
            margin-left: 18px;
        }

        @media (max-width: 1170px) {
            padding-left: 0;
            margin-left: 0;
        }
    }
}


.forgot-msz {
    @include clearfix;
    padding: 50px 55px;
    text-align: center;

    i {
        font-size: 45px;
        width: 45px;
        display: block;
        margin: 0 auto 27px;
    }

    .text-bold {
        font-size: 18px;
        line-height: 1.4;
    }

    p {
        font-size: 12px;
        margin: 5px 0 0;
    }

    .btn {
        background-color: transparent !important;
        border: 1px solid $dark-color;
        color: $dark-color;
        box-shadow: none !important;
        margin-top: 40px;
    }
}




.flexrow {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.flex-box {
    align-items: center;
    justify-content: center;
    max-width: 376px;
    width: 100%;
    min-height: 300px;
}

.pwd-form-box {
    background-color: $white;
    border-radius: 5px;
    min-height: 300px;
    width: 100%;
    @include clearfix;
    padding: 30px 36px 34px;

}

.reset-pwd-container {
    margin: 15px;

    .brand-logo {
        text-align: center;
        width: 80px;
        display: block;
        margin: 0 auto 24px;
        color: $primary;

        img {
            width: 80px;
            object-fit: contain;
        }
    }
}

.pwd-form-header {
    text-align: center;
    margin-bottom: 30px;
    color: $dark-color;

    h3 {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        margin: 0 0 5px;
    }

    p {
        font-size: 12px;
        margin: 0;
    }
}

.pwd-form-footer {
    padding-top: 36px;
}

.visibility-hide {
    overflow: hidden;
    height: 0;
}

.form-check-signup {
    * {
        cursor: pointer;
    }
}

.new-fix-pws {
    text-align: right !important;
    margin-top: 45px !important;

    .color-blk {
        color: $primary !important;
    }

    @include media-breakpoint-down(md) {
        position: relative;
        top: -44px;
        right: 0px;
    }
}

.tal-su {
    text-align: left !important;

    @include media-breakpoint-down(md) {
        margin-bottom: -25px !important;
    }
}

.flex-sg-form {
    display: flex;
}