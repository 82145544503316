.confirm-transfer-modal {
    .modal-content {
        background: #F2F2F2;
        border: none;
        border-radius: 15px;
        overflow-y: auto;

        .close-btn {
            background-color: $primary;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            color: $white;
            top: -30px;
            right: -30px;
            text-align: center;
            padding: 9px 0 0 2px;
            font-size: 10px;
        }
    }

    .modal-header {
        padding: 16px 0;
        background-color: $primary;
        color: $white;
        border-radius: 15px 15px 0 0;

    }

    .modal-body {
        padding-left: 0;
        padding-right: 0;
    }

    .deadline-clock-wrap {
        background-color: $white;
        padding: 10px 0;
        margin: 0 36px;
        border-radius: 10px;

        .deadline-clock {
            color: #333333;
            justify-content: flex-start;
            text-align: start;

            .icon-hourglass {
                color: $primary;
            }

            >* {
                flex-basis: auto;
                flex-grow: unset;
                max-width: 100%;
            }

            .clock-title {
                opacity: 0.6;
                color: #333333;
                font-size: 12px;
                letter-spacing: -0.1px;
                line-height: 14px;
                font-family: $font-family-Inter;
            }
        }
    }

    .help-txt {
        text-align: center;
        font-size: $font-size-xs;
        padding: 0 36px;
        margin: 15px 0;
    }

    .player-item {
        cursor: default;
        @include text-truncate;

        .player-info {
            padding-right: 10px;
            padding-left: 0;

            &+.thumb-wrap {
                margin-left: 0;
                margin-right: 10px;

                .flag {
                    right: auto;
                    left: -7px;
                }
            }
        }

        .thumb-wrap {
            min-width: 27px;
            max-width: 27px;
            margin-left: 10px;
            margin-right: 0;

            .thumb {
                width: 27px;
                height: 27px;
            }

            .flag {
                bottom: -4px;
            }

            &+.player-info {
                padding-right: 0;
                padding-left: 10px;
            }
        }
    }

    .sec-head {
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-xs;
        height: 26px;
        color: $white;
        background-color: darken($color: $secondary, $amount: 15%);
    }
}

.transfer-player-wrap {
    display: flex;
    width: 100%;
    padding: 0 40px;

    .transfer-player-col {
        // display: grid;
        flex: 1 1;
        // max-width: 50%;
        min-width: 50%;

        >.cell {
            align-items: center;
            flex: 1 1;
            // border-left: solid 1px rgba($gray-400, .5);
            // border-bottom: solid 1px rgba($gray-400, .5);
            display: flex;
            justify-content: flex-end;
            text-align: right;

            &.head {
                display: flex;
                justify-content: center;
                text-align: center;
                font-size: $font-size-xs;
                height: 26px;
                margin-top: 15px;
                color: #333333;
                border-radius: 0 0 10px 10px;
                background-color: $white;
                // background-color: darken($color: $secondary, $amount: 15%);
                border-bottom: none;
                border-left-color: rgba($white, .4);
            }

            padding-left: 10px;
        }

        &:first-child {
            >.cell {
                border-left: none;
                text-align: left;
                padding-left: 0;
                padding-right: 10px;

                &:not(.head) {
                    justify-content: flex-start;
                }
            }
        }
    }
}

.transfer-point-row {
    display: flex;
    border-bottom: solid 1px rgba($gray-400, .5);
    padding: 10px 15px;

    .cell {
        flex: 1 1;
        display: flex;
        justify-content: flex-end;

        &.minw {
            min-width: 70px;
            max-width: 70px;
        }

        &:first-child {
            justify-content: flex-start;
        }
    }
}

.in-out-wrap {
    border-radius: 15px;
    background-color: $white;
    height: 60px;
    margin: 20px 36px;

    .help-txt {
        margin: 0;
        padding: 5px 0 8px 0;
        color: #999999;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
    }

    .in-out {
        height: 29px;
        border-top: 1px solid #ECECEC;
        display: flex;

        img {
            width: 15px;
        }
    }

    .arrow-txt {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            margin-left: 20px;
            color: #333333;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: -0.1px;
            line-height: 14px;
            text-align: center;
        }
    }

    .border-l {
        border-left: 1px solid #ECECEC;
        ;
    }
}

.bottom-captions {
    color: #333333;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}

.points-wrap {
    background: white;
    margin: 0 36px;
    border-radius: 10px;
    overflow: hidden;

    .sec-head {
        background-color: $white;
        color: #999999;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        margin-top: 0;
        border-bottom: 1px solid #ECECEC;
        padding: 5px 0 8px 0;
    }

    .transfer-point-row {
        .cell {
            color: #333333;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 17px;

            b {
                font-weight: 600;
            }
        }
    }
}

.confirm-changes {
    height: 44px;
    width: 220px;
    border-radius: 10px;
}

.footer-cls-modal {
    justify-content: center;
}