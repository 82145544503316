body {
    // background-color: $black;
    // user-select: none;
    background-color: $body-bg;
    font-family: $font-family-Inter;
    font-size: $body-font-size;
    font-weight: $font-weight-normal;

    &:lang('ar') {
        font-family: $font-family-arabic;
    }
}

.teko {
    font-family: $font-family-Inter;
}

.app-container {
    display: flex;
    min-height: 100vh;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main {
    width: 100%;
    flex: 1 1;
    flex-direction: column;
    display: flex;
    background-color: #EFF1F5 !important;
    // background-image: url(../img/hexagon-pattern-bg.svg);
    // background-size: 30%;
    // background: url(../img/hexagon-pattern-bg.svg);
    // background-repeat: no-repeat;
    // background-color: #EFF1F5;
}

a {
    text-decoration: none;
}

.primary-color {
    color: $primary !important;
}

.secondary-color {
    color: $secondary-bg-color !important;
}


.text-normal {
    font-weight: $font-weight-normal;
}

.text-bold {
    font-weight: $font-weight-bold;
}

.text-semibold {
    font-weight: $font-weight-semibold;
}

.text-medium {
    font-weight: $font-weight-medium;
}

.text-black {
    font-weight: $font-weight-black;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;

    >.col,
    >[class*="col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.gutters-5px {
    margin-right: -5px;
    margin-left: -5px;

    >.col,
    >[class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
    }
}

.gutters-10px {
    margin-right: -10px;
    margin-left: -10px;

    >.col,
    >[class*="col-"] {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.gutters-7px {
    margin-right: -7px;
    margin-left: -7px;

    >.col,
    >[class*="col-"] {
        padding-right: 7px;
        padding-left: 7px;
    }
}

.gutters-20px {
    margin-right: -20px;
    margin-left: -20px;

    >.col,
    >[class*="col-"] {
        padding-right: 20px;
        padding-left: 20px;
    }
}

a {
    cursor: pointer;

    &:not([href]) {
        color: $primary;

        &:hover {
            color: darken($primary, 20);
        }
    }
}

.mobile-visible {
    @extend .d-block;
    @extend .d-sm-block;
    @extend .d-md-none;
}

.mobile-hidden {
    @extend .d-none;
    @extend .d-sm-none;
    @extend .d-md-block;
}


.btn-block {
    width: 100%;
    // background-color: $secondary-bg-color;
    color: $primary-fg-color;

    &:hover {
        background-color: darken($secondary-bg-color, 8%);
        color: $primary-fg-color;
    }

}

@media (max-width: 767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
    }

    input[type="submit"].btn-xs-block,
    input[type="reset"].btn-xs-block,
    input[type="button"].btn-xs-block {
        width: 100%;
    }

    .btn-block+.btn-xs-block,
    .btn-xs-block+.btn-block,
    .btn-xs-block+.btn-xs-block {
        margin-top: 0.5rem;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .btn-sm-block {
        display: block;
        width: 100%;
    }

    input[type="submit"].btn-sm-block,
    input[type="reset"].btn-sm-block,
    input[type="button"].btn-sm-block {
        width: 100%;
    }

    .btn-block+.btn-sm-block,
    .btn-sm-block+.btn-block,
    .btn-sm-block+.btn-sm-block {
        margin-top: 0.5rem;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .btn-md-block {
        display: block;
        width: 100%;
    }

    input[type="submit"].btn-md-block,
    input[type="reset"].btn-md-block,
    input[type="button"].btn-md-block {
        width: 100%;
    }

    .btn-block+.btn-md-block,
    .btn-md-block+.btn-block,
    .btn-md-block+.btn-md-block {
        margin-top: 0.5rem;
    }
}

@media (min-width: 1200px) {
    .btn-lg-block {
        display: block;
        width: 100%;
    }

    input[type="submit"].btn-lg-block,
    input[type="reset"].btn-lg-block,
    input[type="button"].btn-lg-block {
        width: 100%;
    }

    .btn-block+.btn-lg-block,
    .btn-lg-block+.btn-block,
    .btn-lg-block+.btn-lg-block {
        margin-top: 0.5rem;
    }
}


.form-group {
    margin-bottom: 15px;
}

.form-control {
    background-color: $white;
    color: $dark-color;
    padding: 10px 1rem 11px;

    &:focus {
        color: $dark-color;
        background-color: $white;
        border-color: $gray-400;
    }
}



.popover {
    .popover-inner {
        color: $dark-color;
    }

    .popover-header {
        color: $dark-color;
        text-align: center;
        font-weight: $font-weight-bold;
        border-bottom: none;
        text-transform: uppercase;
    }

    .popover-body {
        color: $dark-color;
    }
}

.small-container {
    max-width: 680px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.hero-title {
    font-size: $font-size-l;
    font-weight: $font-weight-semibold;
    text-align: center;
    line-height: 1.2;
    padding-bottom: 15px;
    // border-bottom: solid 2px $secondary-bg-color;
    color: $secondary-fg-color;

    .subtitle {
        font-size: $font-size-s;
        font-weight: $font-weight-normal;
        margin: 10px 0 0;
    }
}

.pwd-form-group {
    position: relative;

    .pwd-eye {
        width: 44px;
        height: 44px;
        position: absolute;
        font-size: 20px;
        right: 0;
        top: 0;
        color: $gray-600;
        padding: 12px;
        cursor: pointer;

        &:hover {
            color: $secondary-bg-color;
        }
    }
}

.margin-top-default {
    margin-top: 24px;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.form-lbl {
    height: 15px;
    font-size: 11px;
    margin: 0;
    position: absolute;
    bottom: 0;
    right: 0;
}

.form-lbl-group {
    // margin-bottom: 0 !important;
    // padding-bottom: 15px;
    position: relative;
}

.no-margin-b {
    margin-bottom: 0 !important;
}

.no-padding-v {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}



.global-info-icn {
    font-size: 12px;
    color: $gray-500;
    padding: 4px;
    margin: 6px 5px 0;
    cursor: pointer;
}

.league-info-modal {
    @extend .custom-modal;
    text-align: center;

    .modal-content {
        padding: 20px;
    }

    .icon-about {
        width: 30px;
        height: 30px;
        font-size: 30px;
        color: $gray-999;
        margin: 0 auto 15px;
        display: block;
    }

    .title {
        font-size: 20px;
        line-height: 1.2;
    }

    p {
        font-size: 12px;
        line-height: 20px;
        color: $gray-666;
    }

    &.base {
        background-color: $white;
        max-width: 380px;
        margin: 0 auto 20px;
        padding: 20px 20px 30px;

        p {
            margin-top: 5px;
        }
    }

    .small-container {
        padding: 0;
    }

    .hero-title {
        font-size: $font-size-l;
        padding-bottom: 10px;
        color: $secondary-fg-color;
    }

    .static-page-body {
        padding-top: 10px;

        img {
            max-width: 100%;
        }
    }

    // Popover
    .popover {
        background-color: $modal-bg-color;
        width: 100%;
        max-width: 340px;

        @include media-breakpoint-down(md) {
            max-width: calc(100% - 10px);
        }
    }

    .popover-inner {
        display: block;
        @include clearfix;
        color: $secondary-fg-color !important;
        padding: 15px 20px;
        max-height: 400px;
        overflow-y: auto;
        background-color: $white !important;
        border-radius: 6px;

        .icon-about {
            margin: 30px auto 15px;
        }
    }

    .message-box {
        height: auto;
        max-height: 50vh;
    }
}

.update-profile-modal {
    text-align: center;

    .modal-content {
        padding: 85px 0 0 0;
        border-radius: 15px 15px 20px 20px;

        .modal-body {
            padding: 1rem 3rem;
        }
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        padding: 8px 9px;
        cursor: pointer;
        z-index: 1;
        color: $gray-900;
    }

    .icon-profile-info {
        font-size: 60px;
        color: $gray-col;
        margin: 0 auto 20px;
    }

    .title {
        font-size: 24px;
        line-height: 34px;
        font-weight: $font-weight-black;
        color: $gray-col;
        text-transform: uppercase;
    }

    p {
        font-size: 16px;
        line-height: 20px;
        color: $gray-333;
        padding-bottom: 65px;
    }

    .modal-footer {
        padding: 0;

        .btn {
            margin: 0;
            border-radius: 0 0 15px 15px;
        }
    }



}




// Lists

ol,
ul {
    padding-left: 2rem;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}


.form-check-input {
    background-color: transparent;

    &:checked[type=checkbox] {}
}

.CookieConsent {
    align-items: center !important;
    position: sticky !important;
    border-top: solid 1px $primary;
    background-color: #EFF1F5 !important;

    div {
        color: #333333;
    }

    a {
        color: $secondary-bg-color !important;
    }

    .cookie-consent-btn {
        background-color: $btn-isl !important;
        color: $white !important;
        padding: 5px 30px !important;

        &:hover {
            background-color: $btn-isl-hover !important;
            color: #28293D !important;
        }
    }
}

.modal-backdrop {
    background-color: rgba(#000, .7);
    backdrop-filter: blur(5px);

    &.show {
        opacity: 1;
    }
}

.text-uppercase {
    text-transform: uppercase;
}


/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

*::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
    width: 4px;
    height: 4px;
}

*::-webkit-scrollbar-thumb {
    background-color: $scrollbar-thumb-color;
    border-radius: 20px;
    border: 3px solid $scrollbar-track-color;
    transition: background-color .1s ease-out;
}

.highcharts-credits {
    display: none !important;
}

.m-t-md {
    margin-top: 20px;
    color: $black;
}

.gray-999 {
    color: $gray-999 !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.sticky-header,
.sticky-header-wrap thead {
    position: sticky;
    top: 0;
    /* Don't forget this, required for the stickiness */
    z-index: $zindex-sticky;
    background-color: $body-bg;

    &.top-1 {
        padding-top: 30px;
        top: -15px;
        z-index: calc($zindex-sticky + 1);
    }

    &.top-2 {
        top: 50px;
        z-index: calc($zindex-sticky + 1);
        color: $secondary-fg-color;
    }
}

.sticky-header-wrap {
    thead {
        top: 115px;
    }

    .infinite-scroll-component {
        overflow: inherit !important;
    }
}

.sticky-head-container {
    padding-top: 0;
}

.btn-secondary {
    background-color: $btn-isl;
    color: $white;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.21);
    border: 1px solid $btn-isl;

    &:hover {
        border: 1px solid $btn-isl-hover;
        background-color: $btn-isl-hover;
        color: $primary;
    }

    // &:focus {
    //     background-color: darken($secondary-bg-color, 3%) !important;
    //     color: $primary-fg-color;
    // }
}

.my-custom-content {
    color: $secondary-fg-color;
}

.color-blk {
    color: #333333 !important;
}

.no-data-stats {
    display: flex;
    align-items: center;
    justify-content: center;
}