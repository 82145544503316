.player-card-item {
    padding-top: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 144px;
    height: 165px;
    background-color: #EFF1F5;
    border-radius: 10px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        // margin-top: 5px;
        width: 74%;
        padding-top: 13px;
        height: 148px;
        // zoom: 90%;
    }
}

.player-pos-circle {
    background-color: $secondary-bg-color;
    color: $dark-color;
    position: absolute;
    top: -10px;
    left: 5px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: solid 1px $dark-color;
    text-align: center;
    font-size: 12px;
    font-weight: $font-weight-bold;
}

.plater-thumb-wrap {
    // height: 100px;
    position: relative;
    background-color: #EFF1F5;
    // height: 100px;
    padding: 10px;
    margin: 0;

    &.headshot {
        padding: 0;

        .thumb {
            object-fit: cover;
            object-position: center top;
        }
    }

    .thumb {
        width: 100%;
        // height: 75%;
        object-fit: contain;
        margin: 0;
    }

    .flag {
        position: absolute;
        bottom: 0;
        right: 35px;
        width: 30px;
       // height: 25px;
        object-fit: fill;
        //border-radius: 100%;
        //border: solid 2px #EFF1F5;
    }

    .player-pos {
        // position: absolute;
        // height: 20px;
        // color: $primary-fg-color;
        // background-color: $secondary-bg-color;
        // text-align: center;
        // bottom: -10px;
        // left: 50%;
        // transform: translateX(-50%);
        // border: solid 2px $white;
        // font-size: $font-size-xs;
        // line-height: 1.2;
        // padding: 0 5px;
        // border-radius: 4px;
        position: absolute;
        height: 20px;
        // color: #000;
        /* background-color: #FFCE46; */
        text-align: center;
        right: 5px;
        top: -10px;
        transform: translateX(-50%);
        /* border: solid 2px $white; */
        color: #272828;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
    }
}

.player-card-details {
    padding: 5px 10px;
    font-weight: $font-weight-semibold;
    color: $dark-color;
    text-align: center;
    line-height: 1.2;

    >.title {
        // font-size: $font-size-s;
        // @include text-truncate;
        color: #333333;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
    }

    >.score {
        // font-size: $font-size-l;
        // line-height: 1.2;
        color: #333333;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 19px;
        text-align: center;

        .pts-block {
            padding: 5px 0 0 0;
        }

        .lbl {
            font-size: $font-size-xs;
            font-weight: $font-weight-normal;
            color: $gray-999;
        }
    }
}


.statistics-slider {
    background-color: $white;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    margin-bottom: 3px;

    @include media-breakpoint-down(md) {
        padding: 0;
        padding-left: 15px;
        margin-bottom: 0;

        .slick-slide {
            >div {
                zoom: 80%;
                padding-top: 10px;

                &:first-child {
                    padding-top: 0;
                    // zoom: 80%;
                }
            }
        }
    }

    .slick-slider {
        margin: 0;

        .slick-arrow {
            &::before {
                color: #EE3224;
            }
        }
    }

    // .slick-prev:before, .slick-next:before {
    //     color: $primary;
    // }
}

.stats-player-list {
    padding: 0;
    max-height: 270px;
    overflow-y: auto;

    .player-item {
        color: $white;
        height: 58px;
        border-top-color: rgba($dark-color, .5);

        .cell.xl {
            max-width: 80px;
            min-width: 80px;
        }

        &.head {
            background-color: darken($color: $gray-col, $amount: 3%);
            height: auto;
            font-size: $font-size-xs;
            font-weight: $font-weight-normal;
            color: $gray-500;
            padding: 2px 0;
            border-bottom: solid 1px rgba($dark-color, .5);

            .cell {
                font-size: $font-size-xs;
                font-weight: $font-weight-normal;
            }
        }
    }
}

.gray-box-scroll {
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20px;
        border-radius: 0 0 7px 7px;
        background: linear-gradient(0deg, $dark-color 0%, transparent 100%);
        opacity: .3;
    }

    &::before {
        border-radius: 0;
        bottom: auto;
        top: 45px;
        background: linear-gradient(0deg, transparent 0%, $dark-color 100%);
    }
}

.player-stats-footer {
    margin-top: 24px;
    text-align: center;

    .btn-secondary {
        width: 100%;
        max-width: 335px;
        border-radius: 25px;
        background-color: $secondary-bg-color;
    }
}




.m-top-l {
    margin-top: 20px;
}

.statplayer-tbl {
    width: 100%;
    margin-top: 20px;
    color: $dark-color;

    @include media-breakpoint-down(md) {
        margin-top: 20px;
    }

    thead {
        // color: rgba($secondary-fg-color, 1);
        // font-size: 12px;
        color: #999999;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;

        td {
            cursor: default;
        }

        @include media-breakpoint-down(md) {
            font-size: 11px;
        }

    }

    td {
        cursor: pointer;

        &:not(.player-info) {
            // text-align: center;
            width: 100px;
            padding-left: 0;
            padding-right: 0;
        }

        &.w80 {
            width: 80px;
        }

        &.w110 {
            width: 110px;
        }

        text-align: center;

        &.player-info {
            text-align: left;

            &:lang(ar) {
                text-align: right;
            }
        }


        @include media-breakpoint-down(md) {
            &:not(.player-info) {
                width: 56px;
            }

            &.w80 {
                width: 45px;
            }

            &.w110 {
                width: 45px;
            }

            .dyn-label {
                // width: 45px;
                // @include text-truncate;
                text-overflow: clip;
                max-width: 85px;
            }
        }
    }

    tbody {
        td {
            height: 63px;
            background-color: white;
            padding: 10px;
            // border-top: solid 8px $body-bg;
            color: $dark-color;

            @include media-breakpoint-down(md) {
                // border-top: solid 5px $body-bg;
                // padding: 10px 0;

                &:first-child {
                    padding-left: 10px;
                }
            }
        }

        tr {
            border-top: 1px solid #F0F0F0;
        }
    }

    .figure {
        display: inline-block;
        width: 37px;
        height: 37px;
        border-radius: 100%;
        margin: 0 18px 0 0;
        position: relative;

        &:lang(ar) {
            margin: 0 0 0 18px;
        }

        img {
            border-radius: 100%;
            object-fit: contain;
            object-position: center top;
        }

        .img {
            width: 37px;
            height: 37px;
            margin-top: 10px;
        }

        .flag {
            background-color: white;
            position: absolute;
            width: 25px;
            height: 25px;
            right: -5px;
            bottom: -5px;
            border: solid 2px #EFF1F5;
            &:lang(ar) {
                right: auto;
                left: -5px;
            }
        }
    }

    .inline {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 37px - 18px);
    }

    .player-name {
        font-size: 14px;
        line-height: 1.35;
        font-weight: 600;
        color: $dark-color;
        @include text-truncate;
        width: 295px;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 19px;
            max-width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
    }

    .team-name {
        font-size: 12px;
        color: $gray-999;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            font-size: 11px;
            line-height: 15px;
        }
    }
}

.statistics-container {
    // max-width: 680px;
    // padding-top: 30px !important;
    padding-bottom: 30px !important;

    @include media-breakpoint-down(md) {
        padding-top: 70px;
    }
}

.stats-box-row {
    // padding-top: 30px;

    @media (min-width: 1200px) {
        height: 784px;
    }

    position: relative;


}

.stats-blank-msz {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(#000, .5);
        backdrop-filter: blur(2px);
    }

    span {
        box-shadow: 0 15px 45px 0 rgba($black, .12);
        background-color: rgba($white, .2);
        padding: 10px 20px;
        margin: 0 20px;
        border-radius: 20px;
        position: relative;
        z-index: 1;
    }
}

.stats-box {
    background-color: $white;
    display: flex;
    flex-direction: column;

    border-radius: 8px;
    height: 500px;

    @media (min-width: 1200px) {
        height: 100%;
    }

    &.half {
        margin-top: 24px;

        &:first-child {
            margin-top: 24px;

            @include media-breakpoint-up('lg') {
                margin-top: 0;
            }
        }

        height: 364px;

        @media (min-width: 1200px) {
            height: calc(50% - 12px);
        }
    }

    &.two {
        margin-top: 24px;

        @media (min-width: 768px) {
            margin-top: 0;
        }
    }
}

.stats-box-header {
    display: flex;
    padding: 12px 20px 0;
    width: 100%;
    flex-direction: column;
    // color: $secondary-fg-color;

    // background-color: white;
    .title {
        display: flex;
        justify-content: space-between;
        // align-items: center;

        span {
            // font-family: $font-family-Inter;
            // font-size: 18px;
            text-transform: uppercase;
            // font-weight: $font-weight-bold;
            color: #333333;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            padding: 8px 0 20px;

            // font-weight: 500;
            @include media-breakpoint-down(md) {
                font-size: 16px;
                font-weight: $font-weight-bold;
            }
        }

        i {
            font-size: 20px;
            cursor: pointer;
        }
    }



    .icon-down-1,
    .icon-up-1 {
        cursor: default;
        font-size: 14px !important;
        margin-left: -7px;
        margin-right: 10px;
        color: #E42020;
    }

    .icon-down-1 {
        color: #0CBFB6;
    }

    .week-label {
        font-size: 14px;
    }
}

.stats-box-body {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
    max-height: 100%;
    overflow-y: auto;
    color: #cacaca;
}

.state-item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
        // background-color: darken($color: $gray-col, $amount: 2);
        background-color: darken($color: $white, $amount: 2);

    }

    .cell {

        // display: flex;
        // flex-direction: column;
        &.first {
            width: 38px;
            color: #818181;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 29px;
            // text-align: center;
        }

        &.mid {
            width: 120px;

            .player-name {
                font-size: 14px;
                font-weight: $font-weight-bold;
                color: #333333;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 15px;

                @include text-truncate;

                span {
                    cursor: pointer;
                }
            }

            .player-flag {
                display: inline-block;
                width: 25px;
                height: 25px;
                object-fit: contain;
                margin-right: 5px;

                &:lang(ar) {
                    margin-right: 0;
                    margin-left: 5px;
                }
            }

            .player-pos {
                font-size: 10px;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                padding: 2px 6px;
                // background-color: $secondary-bg-color;
                background-color: $white;
                border: 1px solid #D5D5D5;
                border-radius: 4px;
                color: #333333;
            }
        }

        &.last {
            flex: 1 1;
            position: relative;
            padding-left: 10px;

            &:lang(ar) {
                padding-left: 0;
                padding-right: 10px;
            }

            .graph-cell {
                height: 34px;
                border-radius: 4px;
                background-color: transparent;
                width: 0;
                transition: width .1s ease-out;

                &.primary {
                    background-color: #EAF1FF;
                }

                &.gray {
                    background-color: #EAF1FF;
                }

                .progress-bar {
                    background-color: rgba($secondary-bg-color, .42);
                }
            }

            .value {
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%);
                color: #333333;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .cell {
            font-size: 14px !important;

        }

        .player-name {
            font-size: 12px !important;
            font-weight: 600 !important;
        }

        .player-flag {
            width: 25px !important;
            height: 25px !important;
        }
    }
}

.table-item {
    display: flex;
    padding: 9px 13px;
    align-items: center;
    cursor: pointer;
    transition: background .1s ease-in;

    &:not(.head):hover {
        background-color: darken($color: $white, $amount: 2);
    }

    &.head {
        font-size: 13px;
        color: $gray-999;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        padding-bottom: 10px;
        margin: 0 -20px;
        padding: 0 15px 10px;
        cursor: default;
    }

    .cell {
        &.wd {
            width: 100px;
            color: #999999;
            font-size: 12px;
            letter-spacing: -0.12px;
            line-height: 17px;

            .gray-999 {
                // color: $gray-999;

            }

            b {
                // color: #8b8b8b;
                color: #999999;
                font-size: 12px;
                letter-spacing: -0.12px;
                line-height: 17px;
                font-weight: 500;
            }
        }

        &.first {
            flex: 1 1;
            display: flex;
            align-items: center;
            min-width: 0;
        }
    }

    .player-thumb {
        width: 37px;
        height: 37px;
        position: relative;

        .player-img {
            width: 37px;
            height: 37px;
            object-fit: contain;
            border-radius: 100%;
        }

        .player-flag {
            width: 18px;
            height: 18px;
            object-fit: contain;
            position: absolute;
            bottom: 0;
            right: -9px;
        }
    }

    .player-details {
        flex: 1 1;
        line-height: 1.1;
        padding: 0 10px 0 20px;

        .name {
            color: #333333;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: -0.12px;
            line-height: 15px;
            // font-size: 14px;
            // font-weight: $font-weight-bold;
            // color: #8b8b8b;
            @include text-truncate;
        }

        .team-name {
            // font-size: 11px;
            // color: $gray-999;
            color: #999999;
            font-size: 10px;
            letter-spacing: -0.08px;
            line-height: 12px;

        }
    }
}

.ta-r {
    text-align: right;

    .normal-nav {
        color: $primary;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        text-align: right;
        text-decoration: underline;
    }
}

.gw-dsp {
    color: #333333;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: right;
    padding-top: 8px;
}

// .wfix-tpl {}
.white-stats {
    border-radius: 10px;
    background-color: $white;
    margin-bottom: 20px;

    .small-container {
        width: 644px !important;

        @include media-breakpoint-down(md) {
            width: 100% !important;
        }
    }

    @include media-breakpoint-down(md) {
        width: 92%;
    }
}

.mtb-all-stats {
    margin: 26px 0;
    display: flex;
}

.red-circle {
    margin-right: 10px;
    display: block;
    cursor: pointer;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background-color: #EE3224;
    color: $white;
    padding: 4px 0 0 5px;
    font-size: 11px;
}

.pt-fix {
    padding-top: 5px !important;
}

.last-child {
    width: 95px !important;

    @media (max-width:767px) {
        width: 65px !important;
    }
}

.pfix-container {
    padding: 0 !important;
}

#seperator {
    height: 15px;
}

.stats-head {
    padding-bottom: 15px !important;
}

.pos-rel {
    position: relative;
}

.label-for-dd {
    position: absolute;
    color: #999999;
    z-index: 1;
    left: 10%;
    top: 30%;
    font-size: 12px;

    @include media-breakpoint-down(md) {
        left: 10%;
    }
}

.l-fix {
    left: 15%;
}

.stats-tab {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    border-radius: 5px;
    background-color: #EFF1F5;
    padding: 6px 0;
    height: 34px;

    span {
        // font-size: 15px;
        // font-weight: $font-weight-semibold;
        text-transform: uppercase;
        // flex: 1 1;
        // text-align: center;
        padding: 3px 10px;
        // border-bottom: solid 2px transparent;
        // color: rgba($secondary-fg-color, .6);
        cursor: pointer;
        max-width: fit-content;
        color: #979CA5;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;

        &.selected {
            cursor: default;

            // border-color: $secondary;
            // color: $secondary-fg-color;
            height: 26px;
            min-width: 50px;
            border-radius: 5px;
            background-color: $white;
            box-shadow: -1px 1px 1px 0 #DDDDDD;
            color: $primary;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 20px;
            text-align: center;
        }

        @include media-breakpoint-down(md) {
            font-size: $font-size-xs;
        }
    }
}

.prize-page-tab {
    width: 30%;
    margin: 0 auto 50px;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.min-w-unselect {
    min-width: 110px !important;
    text-transform: uppercase;
}