.leaderboard-component {
    padding-top: 24px;
    padding-bottom: 24px;
}

.leaderboard-user-wrap {
    background-color: $white;
    border-radius: 15px;
    min-height: 680px;
    margin-bottom: 20px;

    .scroll-items {
        overflow-y: auto;
        max-height: 660px;
    }

    @include media-breakpoint-down(md) {
        border-radius: 0px !important;
    }
}

.leaderboard-header {
    display: flex;
    align-items: center;
    // align-items: stretch;
    padding-bottom: 5px;
    margin-bottom: 24px;
    // border-bottom: solid 1px $secondary-bg-color;
    position: relative;
    color: $secondary-fg-color;

    .share-icn {
        display: flex;
        align-items: center;

        .icon-share {
            background-color: $secondary-bg-color;
            color: $dark-color;
            padding: 5px;
            font-size: $font-size-xs;
            margin: 0 10px;
            border-radius: 3px;
            cursor: pointer;
            max-height: 22px;
        }
    }

    .icon-previous {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        display: block;
        padding: 6px 5px;
        font-size: 10px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        background-color: $gradient-right;
        border-radius: 50%;
        transition: background .1s ease-out;
        color: $white;

        &:hover {
            // background-color: $gray-col;
        }

        &:active {
            // background-color: darken($color: $gray-col, $amount: 5%);
        }

        &:lang(ar) {
            transform: rotate(180deg);
        }
    }

    .league-dropdown {
        margin-left: 10px;

        &:lang(ar) {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    .league-dropdown-toggle {
        cursor: pointer;
        font-size: $font-size-l;
        font-weight: $font-weight-bold;
        display: flex;
        align-items: center;

        .icon-down {
            font-size: 12px;
            margin: 0 10px;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 5px;
    }
}

.league-dropdown-menu {
    padding: 10px 0;
    min-width: 343px;
    font-size: $font-size-s;
    overflow: hidden;
    box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.06);
    border: 2px solid $primary;

    .dropdown-header {
        cursor: default;
        // border-bottom: solid 1px $gray-400;
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 12px;
        justify-content: space-between;

        div {
            &:first-child {
                min-width: 160px;

                &:lang(ar) {
                    text-align: right;
                }
            }
        }

        &.top-label {
            // background-color: $secondary-bg-color;
            color: #333333;
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 14px;
            font-style: italic;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 34px;
            text-transform: uppercase;
        }
    }

    .dropdown-item {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;

        div {
            &:first-child {
                // min-width: 60px;
                width: 160px;

                &:lang(ar) {
                    text-align: right;
                }
            }
        }
    }
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #e9ecef;
    color: #232323;
}

.leaderboard-user-item {
    display: flex;
    align-items: center;
    min-height: 65px;
    border-bottom: solid 1px rgba($white, .1);
    cursor: pointer;
    transition: background .1s ease-out;

    &:hover {
        background-color: rgba(#cacaca, 0.2);
    }

    &:active {
        background-color: rgba(#cacaca, 0.2);
    }

    &.head {
        min-height: 28px;
        cursor: default;

        &:hover,
        &:active {
            background: none;
        }

        &+.owner {
            border-top: none;
        }
    }

    &.owner {
        $owner: $white;
        background-color: #EFF1F5;
        color: $dark-color;
        position: relative;

        // &:hover {
        //     background-color: darken($color: $owner, $amount: 8%);
        // }

        &:active {
            background-color: darken($color: $owner, $amount: 12%);
        }
    }

    // &.owner.selected {
    //     background-color: #182054;
    //     color: $dark-color;

    //     &:hover {
    //         background-color: darken($color: #EFF1F5, $amount: 8%);
    //     }

    //     &:active {
    //         background-color: darken($color:$primary, $amount: 12%);
    //     }
    // }

    &.selected {
        color: $white;

        .cell {
            .details {
                .user-name-m {
                    color: $white;
                }
            }
        }
    }

    >* {
        flex: 1 1;
        min-width: 0;
    }

    .cell {
        &.minw {
            min-width: 60px;
            max-width: 100px;
            text-align: center;
            // color: $secondary-fg-color;
        }

        @include media-breakpoint-down(md) {
            .login-profile-toggle {

                .dull-clr-team {
                    // color: #000 !important;
                }
            }
        }

        .lbl {
            font-size: $font-size-xs;
            font-weight: $font-weight-normal;
            color: #999999;
        }
    }

    .login-profile-toggle {
        padding: 10px 0;

        .avtar {
            margin-left: 0;
            margin-right: 10px;
            min-width: 34px;

            &:lang(ar) {
                margin-left: 10px;
                margin-right: 0;
            }
        }

        .details {
            text-align: left;
            min-width: 0;

            &:lang(ar) {
                text-align: right;
            }

            .user-name {
                @include text-truncate;
            }
        }
    }

    .cell {
        color: #818181;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 29px;
        text-align: center;
    }

    .user-rank {
        opacity: 0.48;
        color: #333333;
        font-size: 10px;
        letter-spacing: -0.08px;
        line-height: 12px;
    }

    .bld-txt-lb {
        color: #333333;
        font-size: 14px;
        // font-weight: bold;
        letter-spacing: -0.12px;
        line-height: 17px;
        text-align: right;
    }

    &.selected {
        background-color: $primary;

        .cell,
        .user-rank,
        .bld-txt-lb {
            color: $white;
        }
    }
}

.league-form {
    margin: auto;
    background-color: $white;
    max-width: 645px;
    padding: 30px 20px;

    .hero-title {
        color: $dark-color;
        border: none;
        margin-bottom: 15px;
    }
}



.league-modal-wrap {
    max-width: 380px;
    margin: auto;
}

.join-league-wrap {
    color: $dark-color;
    // height: 186px;
    width: 100%;


    >.title {
        text-align: center;
        text-transform: uppercase;
        margin: 20px 0;
        font-size: $font-size-s;
        font-weight: $font-weight-bold;

        &.sm {
            font-size: $font-size-xs;
            margin: 0 0 10px;
        }
    }

    p {
        text-align: center;
        margin-bottom: 24px;
    }

    .p-t-10 {
        padding-top: 10px;
    }

    .back-btn {
        width: 40px;
        margin: 20px auto 0;
        cursor: pointer;
        text-align: center;
        font-size: 12px;
        text-decoration: underline;
    }
}

.scoring-board {
    background-color: $white;
    padding: 16px 25px;
    border-bottom: solid 1px $gray-col;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;

    .login-profile-toggle {

        .details {
            text-align: left;
            margin-left: 5px;

            &:lang(ar) {
                text-align: right;
                margin-left: 0;
                margin-right: 5px;
            }
        }
    }

    .score-wrap {
        display: flex;
        flex-direction: row;
        position: relative;

        .cell {
            border-left: solid 1px rgba($white, .1);
            border-bottom: solid 1px $body-bg;
            color: $secondary-fg-color;
            padding: 0 20px;
            min-width: 95px;
            min-height: 60px;
            line-height: 1.2;
            flex: 1 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;

            &:lang(ar) {
                border-left: none;
                border-right: solid 1px rgba($white, .1);
            }

            &:first-child {
                border-left: none;
                border-right: none;
            }

            .value {
                font-size: $font-size-m;
                font-weight: $font-weight-semibold;

                &.xl {
                    font-size: $font-size-xl;
                    line-height: 1.2;
                    position: relative;

                    i {
                        position: absolute;
                        font-size: 12px;
                        top: 0;
                        right: -16px;

                        &.icon-arrow-down {
                            color: $red;
                        }

                        &.icon-arrow-up {
                            color: $green;
                        }
                    }
                }
            }

            .lbl {
                font-size: $font-size-s;
                white-space: nowrap;
            }
        }

        @media (max-width:767px) {
            .cell {
                min-width: 85px;
                padding: 0;
            }
        }
    }
}

.gameweek-wrap {
    height: 50px;
    background: linear-gradient(218.77deg, darken($primary, 45%) 0%, $primary 100%);
    border-radius: 7px;

    .gameweek-weekslider {
        margin: 0;
        background: none;
    }

}

.leave-league-btn {
    color: $secondary;
    margin: 0 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        color: $secondary;
        text-decoration: underline;
    }
}

.invite-wrap {
    display: flex;

    .url-box {
        height: 42px;
        flex: 1 1;
        padding: 9px 14px;
        font-size: $font-size-s;
        color: $dark-color;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        min-width: 0;
        @include text-truncate;

        span {}
    }

    .icn {
        width: 42px;
        height: 42px;
        color: $dark-color;
        // border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        margin-left: 5px;
        cursor: pointer;
        font-size: 18px;
        text-align: center;
        padding: 11px 0;
        background-color: $secondary-bg-color;
        transition: background .1s ease-out;

        &:hover {
            background-color: darken($color: $secondary-bg-color, $amount: 8%);
        }

        &:active {
            background-color: darken($color: $secondary, $amount: 12%);
        }
    }
}

.or-div {
    width: 70%;
    position: relative;
    margin: 40px auto;

    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 10px;
        background-color: $white;
        text-transform: uppercase;
        text-align: center;
    }
}

.code-text {
    font-size: $font-size-l;
    font-weight: $font-weight-semibold;
    color: $dark-color;
    text-align: center;
}

.code-icn {
    display: block;
    cursor: pointer;
    width: 42px;
    height: 42px;
    color: $dark-color;
    border-radius: 4px;
    margin-left: 5px;
    font-size: 18px;
    text-align: center;
    padding: 11px 0;
    background-color: $secondary-bg-color;
    transition: background .1s ease-out;
    margin: 10px auto 0;

    &:hover {
        background-color: darken($color: $secondary-bg-color, $amount: 8%);
    }

    &:active {
        background-color: darken($color: $secondary-bg-color, $amount: 12%);
    }

}

.link-view-prize {
    position: absolute;
    top: 0;
    right: 0;
    // text-decoration: underline;
    height: 29px;
    width: 155px;
    border-radius: 5px;
    background-color: $white;
    color: $primary !important;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.13px;
    line-height: 19px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    img {
        margin-right: 10px;
    }

    @include media-breakpoint-down(md) {
        line-height: 12px;
        font-size: 12px;
        padding: 5px;
        height: 32px;
        width: 69px;
        top: 3px;
    }
}


.download-modal-wrapper {
    .store-btn {
        a {
            display: inline-block;
            margin-left: 10px;
            width: 140px;

            &:first-child {
                margin-left: 0;
            }

            img {
                width: 100%;
            }

            @include media-breakpoint-up(sm) {}
        }
    }

    margin-top: 25px;

    .justify-center {
        display: flex;
        justify-content: center;
        flex-direction: column;

        >* {
            width: 100%;
        }
    }

    .title {
        font-weight: $font-weight-black;
        font-family: $font-family-Inter;
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            font-size: 22px;
        }
    }

    .phone-img {
        width: 190px;
        height: 230px;
        position: relative;
        margin: auto;

        >* {
            width: 113px;
        }

        &::before {
            content: '';
            position: absolute;
            width: 190px;
            height: 190px;
            border-radius: 100%;
            background-color: $secondary;
            left: 50%;
            margin-left: -50%;
            top: 50%;
            margin-top: -50%;
            z-index: 0;
        }

        img {
            position: relative;
            z-index: 0;
        }

        @include media-breakpoint-down(md) {
            margin: 20px auto;
        }
    }
}

.download-modal-modal {
    @extend .custom-modal;
    text-align: center;

    .modal-content {
        padding: 20px;
    }

    .icon-about {
        width: 30px;
        height: 30px;
        font-size: 28px;
        color: $gray-999;
        margin: 0 auto 15px;
        display: block;
    }

    .title {
        font-size: 18px;
        line-height: 1.2;
    }

    .modal-body {
        padding: 0;
    }

    &.modal-dialog {
        max-width: 620px;
    }

    .download-modal-wrapper {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.btm-fix {
    border-bottom: none !important;
}

// equalizer animation
$max: 100%;
$wmax:100px;

.equilizer {
    height: $max;
    width: $wmax;
    transform: rotate(180deg);
    cursor: pointer;
}

.bar {
    fill: $primary;
    width: 12px;
    animation: equalize 1.25s steps(25, end) 0s infinite;
}

.bar:nth-child(1) {
    animation-duration: 1.9s;
}

.bar:nth-child(2) {
    animation-duration: 2s;
}

.bar:nth-child(3) {
    animation-duration: 2.3s;
}

.bar:nth-child(4) {
    animation-duration: 2.4s;
}

.bar:nth-child(5) {
    animation-duration: 2.1s;
}

@keyframes equalize {
    0% {
        height: 60px;
    }

    4% {
        height: 50px;
    }

    8% {
        height: 40px;
    }

    12% {
        height: 30px;
    }

    16% {
        height: 20px;
    }

    20% {
        height: 30px;
    }

    24% {
        height: 40px;
    }

    28% {
        height: 10px;
    }

    32% {
        height: 40px;
    }

    36% {
        height: 60px;
    }

    40% {
        height: 20px;
    }

    44% {
        height: 40px;
    }

    48% {
        height: 70px;
    }

    52% {
        height: 30px;
    }

    56% {
        height: 10px;
    }

    60% {
        height: 30px;
    }

    64% {
        height: 50px;
    }

    68% {
        height: 60px;
    }

    72% {
        height: 70px;
    }

    76% {
        height: 80px;
    }

    80% {
        height: 70px;
    }

    84% {
        height: 60px;
    }

    88% {
        height: 50px;
    }

    92% {
        height: 60px;
    }

    96% {
        height: 70px;
    }

    100% {
        height: 80px;
    }
}

.bg-fixclr {
    background: rgba(255, 255, 255, 0.1) !important;
}

.bg-box {
    background: #EFF1F5;
    margin-right: 6px;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center !important;
    border-radius: 10px;
    min-height: 55px !important;

    .value {
        color: #333333;
        font-size: 24px !important;
        font-weight: 700 !important;
        letter-spacing: -0.2px;
        line-height: 29px;
        text-align: center;
    }

    .lbl {
        color: #333333;
        font-size: 12px !important;
        letter-spacing: -0.1px;
        line-height: 14px;
        text-align: center
    }
}

.dl-clr {
    color: #999999 !important;
}

.bld-txt-lb {
    font-weight: 700;
}

.dull-clr-team {
    // color: rgba(255, 255, 255, 0.5) !important;
    opacity: 0.48;
    color: #333333;
    font-size: 10px;
    letter-spacing: -0.08px;
    line-height: 12px;
}

.mb-5p {
    margin-bottom: 10px;
}

.hide-on-sm {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.heading-leagues {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .react-select__control {
        background-color: transparent !important;
        border: none !important;
    }

    .react-select__single-value {
        color: #333333 !important;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        text-align: right;
    }

    .react-select__indicator {
        color: $primary !important;
        padding: 8px 0 8px 8px !important;
    }

    .react-select__value-container {
        padding: 6px 0 6px 1rem;
    }
}

.heading-main {
    color: #000000;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    text-transform: uppercase;

    &::before {
        content: '';
        position: absolute;
        height: 19px;
        width: 239px;
        opacity: 0.15;
        background: linear-gradient(270deg, #F2F2F2 0%, #5852C3 100%);
        top: 1px;
        left: -5px;
    }

    @include media-breakpoint-down(md) {
        font-size: 18px;
    }
}

.p-t-wrap {
    padding: 0 0 25px;
}

.left-wrap {
    background-color: $white;
    border-radius: 10px;
    padding: 20px 0;

    .lineup-leaderboard {
        .title {
            display: flex;
            align-items: center;

            span {
                color: $primary !important;
                font-size: 16px !important;
                font-weight: 600 !important;
                letter-spacing: 0;
                line-height: 20px;
                position: relative;



                &::before {
                    position: absolute;
                    content: '';
                    left: 0px;
                    top: 8px;
                    height: 19px;
                    width: 239px;
                    opacity: 0.15;
                    background: linear-gradient(270deg, #F2F2F2 0%, #5852C3 100%);

                    @include media-breakpoint-down(md) {
                        left: -5px;
                    }
                }
            }

        }
    }

    @include media-breakpoint-down(md) {
        width: 95%;
        margin: 0 auto 20px;
    }
}

.heading-block {
    padding: 10px 18px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
        padding: 10px;
        padding-bottom: 0;

    }

    .title-id {
        color: #333333;
        font-size: 20px;
        font-style: italic;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 34px;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            font-size: 16px;
        }
    }

    #prix {
        color: #555555;
        font-size: 11px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 13px;

        img {
            margin-right: 5px;
        }
    }
}

.right-wrap {
    .mt-40 {
        margin-top: 0;
    }
}

.pointer-league {
    cursor: pointer;
    color: #333333;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    border-bottom: 1px dashed #979797;

    @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 14px;
    }

    &.elipsis-text {
        width: auto;
    }
}

.background-pitch {
    background-color: #182054;

    .img-bg {
        background-image: url(../img/pitch_mrror.png);
        background-position: center;
        background-size: cover;
    }
}

.user-name-m {
    color: #333333;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.13px;
    line-height: 19px;
}

.user-rank {
    opacity: 0.48;
    color: #333333;
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 14px;
}

.gameweek-leaderbd {
    background-color: $white;
    border-radius: 15px 15px 0 0;
    height: 62px;
    width: 100%;

    .gameweek-weekslider {
        .native-scroll {
            .gameweek-inne {
                margin: 0 auto;
                white-space: nowrap;
                overflow-x: auto;
                padding: 0;
                height: 70px;
                overflow-y: hidden;
                background: $white !important;
                border-radius: 10px !important;
            }
        }
    }
}

.join-n-create {
    .modal-body {
        padding: 25px 15px;

        @include media-breakpoint-down(md) {
            padding: 0 15px;
        }
    }

    min-width: 350px;
    color: $white;
}

.join-league-wraps,
.create-league-wraps {
    background-image: url(../img/JoinPL.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 186px;
    display: flex;
    padding-left: 156px;
    flex-direction: column;
    padding-right: 11px;
    justify-content: center;

    @include media-breakpoint-down(md) {
        padding-left: 145px;
    }

    .lgm-tilte {
        margin-bottom: 0;
        color: $white;
        font-size: 14px;
        font-style: italic;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 20px;
        text-align-last: left;
        text-transform: uppercase;
        margin-bottom: 5px;

        @include media-breakpoint-down(md) {
            font-size: 14px;
        }
    }

    .lgm-subtitle {
        color: $white;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 16px;
        text-align: left;
    }

    .btn-secondary {
        border-color: $white !important;
        border: 1px solid $primary;
        background-color: $white;
        color: $primary;
        padding-top: 4px;
        height: 30px;
        width: 170px;
        border-radius: 5px;
        box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.21);

    }
}

.create-league-wraps {
    background-image: url(../img/CreatePL.png);
    padding-right: 145px;
    padding-left: 25px;
    margin-top: 20px;

    @include media-breakpoint-down(md) {
        padding-right: 140px;
        margin-top: 0;
    }
}

.league-modal-bg,
.join-n-create {
    .modal-content {
        background: $white;
        border-radius: 15px;
        border: none !important;

        // overflow: hidden;
        &.dartbg {
            background: #000 url('../img/league-bg.png') no-repeat center;
        }

        color: black;
        text-align: center;
    }

    .sap {
        min-height: 70px;
    }

    .lgm-tilte {
        font-size: 18px;
        font-weight: $font-weight-semibold;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            font-size: 13px;
            margin-bottom: 0;

            &:lang(mal) {
                font-size: 10px;
            }
        }
    }

    .lgm-subtitle {
        font-size: $font-size-s;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            font-size: 12px;
            margin-bottom: 0;
            padding: 0 0 10px 0;
            font-weight: 500;

            &:lang(mal) {
                font-size: 9px;
            }
        }
    }

    .modal-header {
        padding: 15px;
        border-radius: 15px 15px 0 0;
        background-color: $primary;
        color: $white;
        font-size: 14px !important;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;

    }

    .close-btn {
        top: -27px;
        right: -37px;
        border-radius: 50%;
        background: $primary;
        color: $white;

        @include media-breakpoint-down(md) {
            top: -45px;
            right: -5px;
        }
    }
}

.next-popup-jn {
    .modal-content {
        max-width: 380px;
    }

    .modal-body {
        padding: 25px 30px;
    }
}

.input-wrap {
    height: 58px;

    input {
        height: inherit;
        border-radius: 10px;
    }
}

.join-caption-top {
    color: #333333;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 15px auto 40px !important;
    width: 84%;
}

.join-btn-league {
    height: 44px;
    width: 220px;
    border-radius: 10px;
    background-color: $btn-isl;
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.21);
    margin: 50px 0 25px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 22px;
    text-align: center;

    &:hover,
    &:active,
    &:focus {
        background-color: $btn-isl-hover;
        color: #000000;
    }
}

.cl-wrap {
    margin-top: 25px;
    margin-bottom: 25px;

    .white-bg-wrapper {
        border-radius: 10px;
        background-color: $white;

        .form-group {
            height: 58px;

            .form-control {
                height: inherit;

                &::-webkit-input-placeholder,
                ::placeholder,
                ::-ms-input-placeholder,
                ::-moz-placeholder {
                    color: #999999;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 18px;
                }

                border-radius: 10px;
            }

            .react-select__control {
                height: 58px;
                border-radius: 10px;
            }
        }

        .subtitle {
            color: #333333;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            text-align: center;
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: 60px;

        .banner-wrapper {
            margin-right: -12px;
        }
    }
}

.flex-btn {
    display: flex;
    justify-content: center;
    margin-top: 25px;


    .create-cl-btn {
        height: 44px;
        width: 220px;
        border-radius: 10px;
        // background-color: $primary;
        background-color: $btn-isl;
        color: $white;
        box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.21);

        &:hover,
        &:active,
        &:focus {
            background-color: $btn-isl-hover;
            color: #000000;
        }
    }

}

.main-tl-cl {
    text-transform: uppercase;
    color: #333333;
    font-size: 20px;
    font-weight: 900;
    letter-spacing: -0.17px;
    line-height: 24px;
    text-align: center;

    @include media-breakpoint-down(md) {
        font-size: 16px;
    }
}

.form-lbl-group {
    .form-lbl {
        padding-left: 18px;
        padding-bottom: 19px;
    }
}

.share-title {
    font-size: 14px;
    line-height: 17px;
}

.modal-share {
    padding: 25px 30px !important;
    min-width: 450px !important;

    .share-wrapper {
        .top-disc {
            color: #333333;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            text-align: center;
        }
    }

    @include media-breakpoint-down(md) {
        min-width: 93%;

        .modal-content {
            width: 92%;
            margin: 0 auto;
        }
    }
}

.invite-wrapper {
    display: flex;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    background-color: $white;
    box-sizing: border-box;
    height: 58px;
    width: 305px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;

    .link-displ {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 190px;
        margin-right: 20px;
    }

    .copy-box {
        height: 34px;
        width: 54px;
        border-radius: 5px;
        background-color: $primary;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        padding: 8px 6px;
        cursor: pointer;
    }
}

.or-block {
    margin: 38px auto 20px;
    color: #333333;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
}

.League-code-wrap {
    height: 44px;
    width: 305px;
    border-radius: 10px;
    background-color: #F2F2F2;
    margin: 0 auto 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;

    .code-text {
        color: #333333;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 29px;
    }

    .c-ic-img {
        cursor: pointer;
    }
}

.full-league {
    color: #555555;
    font-family: $font-family-Inter;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 13px;
}

.rank-width {
    width: 70px;
    text-align: center;
}

#league-name {
    color: #333333;
    font-family: $font-family-Inter;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}

#ranks {
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    // text-align: right;
}

#arrow-u-d {
    margin-right: 10px;
}

.trophy-img {
    // margin-right: 5px;
    position: absolute;
    left: 4px;
    top: 2px;
}

.class-color {
    background-color: $white !important;

    .score-wrap {
        width: 100% !important;
        justify-content: space-between !important;
    }
}

.tal-l {
    @include media-breakpoint-down(md) {
        text-align: left !important;
        padding-left: 10px;
    }
}

.w-fix-league {
    width: 80%;
}

.month-slider-row {
    .month-slider-sub {
        color: $gray-999;
        font-size: 12px;
        font-weight: 400;
    }
}