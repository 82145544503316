@use 'sass:math';
$boxes: 10;
$durationAll: .8s;
$duration: math.div(-$durationAll,$boxes);
$color: $primary;
  
    
@keyframes go {
    100% {
        background: transparent;
        flex: 10;
        box-shadow: 0 0 0 transparent;
    }
}

.flexbox-loader-wrapper {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    opacity: 0;
    @include transition(transform .15s ease-in-out, $custom-all-transition);
    &.show {
        opacity: 1;
    }
}
.flexbox-loader {
    height: 100%;
    display: flex;
    transform: translateZ(0);
    
    div {
        flex: 1;
        background: #{$color};
        animation: go #{$durationAll} infinite alternate ease;
        box-shadow: 0 0 20px #{$color};
        
        @for $i from 1 through $boxes {
            &:nth-child(#{$i}) {
                animation-delay: $duration * ($boxes - $i);
            }
        }
    }

}


.spinner-static {
    position: relative;
    min-height: 30px;
}
.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    width: 30px;
    height: 30px;
    
    & .path {
      stroke: rgba($blue, .7);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    &.primary {
        .path {
            stroke: $secondary;
        }
    }
    &.black {
        .path {
            stroke: black;
        }
    }
}


@keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
}
  
@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}
  