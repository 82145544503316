$dropdown-hover: rgba($primary, 0.3);

.guidelines-constainer {
    .hero-title {
        text-transform: uppercase;
        padding: 0 15px;
        margin: 30px 0;
        border-left: solid 4px $body-color;
    }

    .small-hero-title {
        text-transform: uppercase;
        font-size: 16px;
        margin: 0 0 30px;

    }
}

.guidelines-component-wrap {
    @include clearfix;
    margin-bottom: 50px;
}



// CHECK & RADIO BUTTON
$form-check-size: 24px !default;

.form-check {
    cursor: pointer;

    &.radio,
    &.check,
    &.selector {
        padding-left: 0;
        line-height: $form-check-size;

        input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -999;
        }

        .form-check-label {
            @include clearfix;
            padding-left: $form-check-size * 1.35;
            min-width: $form-check-size;
            min-height: $form-check-size;
            cursor: pointer;

            span {
                pointer-events: none;

                &:after,
                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    position: absolute;
                    top: 0;
                }

                &:before {
                    width: $form-check-size;
                    height: $form-check-size;
                    border: solid 2px $border-color-form;
                    border-radius: 0;
                    left: 0;
                }

                &:after {
                    width: $form-check-size * .56;
                    height: $form-check-size * .56;
                    background-color: $primary;
                    border-radius: 100%;
                    top: $form-check-size * .22;
                    left: $form-check-size * .22;
                    visibility: hidden;
                }
            }

            input:checked {
                ~span {
                    &:before {
                        border-color: $primary;
                    }

                    &:after {
                        visibility: visible;
                    }
                }
            }

            input:disabled {
                ~span {

                    &:before,
                    &:after {
                        border-color: $disabled-color-200;
                    }

                    &:after {
                        background-color: $disabled-color-200;
                    }
                }
            }

        }

        &.form-check-inline {
            vertical-align: top;
            height: $form-check-size;
            // padding-left: $form-check-size;
            margin-right: 0;
            @include clearfix;

            .form-check-label {
                padding-left: 0;
            }
        }
    }

    &.check,
    &.selector {
        .form-check-label {
            span {
                &:before {
                    border-radius: 6px;
                    background-color: $white;
                    border-color: $white;
                }

                &:after {
                    border-radius: 0;
                    background: none;
                    border: solid $white;
                    border-width: 0 0 2px 2px;

                    top: $form-check-size * .35;
                    left: $form-check-size * .3;

                    width: $form-check-size * .4;
                    height: $form-check-size * .25;
                    transform: rotate(-45deg);
                }
            }

            input:checked {
                ~span {
                    &:before {
                        background-color: $primary;
                    }

                    &:after {
                        visibility: visible;
                    }
                }
            }

            input:disabled {
                ~span {
                    &:before {
                        border-color: $white;
                    }

                    &:after {
                        visibility: visible;
                        background-color: transparent;
                        border-color: $disabled-color-200;
                    }
                }
            }

        }

        &.form-check-inline {
            vertical-align: top;
            height: $form-check-size;
            // padding-left: $form-check-size;
            margin-right: 0;
            @include clearfix;
        }
    }

    &.selector {
        .form-check-label {
            span {
                &:before {
                    border-radius: 0;
                }
            }
        }
    }


    &.radio {
        .form-check-label {
            span {
                &:before {

                    border-radius: 100%;
                }
            }
        }
    }
}


// REACT SELECT 
.react-select {
    .react-select__control {
        min-height: 44px;
        border: none !important;
        box-shadow: none !important;
        border-radius: $border-radius;

        &:hover {
            // border: none !important;
        }
    }

    .react-select__placeholder {
        margin-left: 0;
        margin-right: 0;
        color: $gray-600;
    }

    .react-select__value-container {
        padding: 6px $input-btn-padding-x;

        &.react-select__value-container--has-value {
            color: $gray-700;
        }
    }

    .react-select__indicator-separator {
        background-color: transparent;
    }

    .react-select__menu {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .react-select__menu-list {
        max-height: 240px;
    }

    .react-select__option {
        color: $gray-999 !important;



        &.react-select__option--is-focused {
            background-color: $primary;
            color: $white !important;
        }

        &.react-select__option--is-selected {
            background-color: $primary;
            color: $white !important;
        }
    }

    &.outline {
        .react-select__input {
            color: $primary;
        }

        .react-select__control {
            // background-color: #26252C;
            // background-color: rgba(0,0,0,0.15); ---->
            // border: solid 1px $primary !important;
            border: 1px solid #AEAEAE !important;

        }

        .react-select__placeholder {
            color: $white;
        }

        .react-select__indicator {
            color: $gray-600;
        }

        .react-select__single-value {
            color: #333333 !important;
        }
    }

    &.plain {
        .react-select__input {
            color: $primary;
        }

        .react-select__control {
            background-color: $white;
            border: solid 1px $gray-400 !important;
        }

        .react-select__placeholder {
            color: $gray-400;
        }

        .react-select__indicator {
            color: $gray-400;
        }

        .react-select__single-value {
            // color: #c1c0c0;
            color: #333333;
        }
    }
}


.react-date-picker.form-control {
    padding: 0;
    color: $dark-color;

    button {
        color: $dark-color;
    }

    .react-date-picker__wrapper {
        width: 100%;
        border: none;
    }

    .react-date-picker__calendar-button {
        padding: 0;
        width: 42px;
        height: 45px;
        font-size: 19px;
    }

    .react-date-picker__inputGroup {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: $input-padding-x * .65;
        white-space: nowrap;

        &:lang(ar) {
            padding-left: 2px;
            padding-right: $input-padding-x * .65;
        }
    }

    * {
        outline: none !important;
    }

    .react-calendar__month-view__days__day--weekend {
        color: $gray-700;
    }

    .react-calendar__tile:disabled {
        color: graytext;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
        color: #757575 !important;
    }

    .react-calendar__tile--active {
        color: $white !important;
        background: $secondary-bg-color;
    }
}

.custom-modal {
    border-radius: 5px;
    color: $dark-color;
    // height: 90vh;
    // overflow: auto;

    .modal-content {
        // background-color: $body-bg;
    }

    .modal-logo-center {
        width: 135px;
        height: 80px;
        color: $primary;
        margin: auto;
        display: block;
        font-size: 40px;
        margin-top: 40px;

        img {
            margin: auto;
            display: block;
            height: 100%;
            object-fit: contain;
        }
    }

    .modal-header {
        padding: 29px 36px 0;
        text-align: center;
        border-bottom: none;
    }

    .modal-title {
        display: block;
        width: 100%;
        font-size: 18px;
        line-height: 1.2;
        text-transform: uppercase;
        font-weight: $font-weight-semibold;

        p {
            font-size: 12px;
            font-weight: $font-weight-normal;
            margin: 5px 0 0;
            text-transform: none;
        }
    }

    .modal-body {
        padding: 28px 36px;
    }

    .modal-footer {
        border-top: none;
        padding: 0 36px 43px;

        * {
            margin: 0;
        }
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        padding: 8px 9px;
        cursor: pointer;
        z-index: 1;

        &.left {
            right: auto;
            left: 0;
        }
    }
}

[validationstate="error"] {
    .react-select__control {
        border-color: $red !important;
    }

    .react-select.plain .react-select__control {
        border-color: $red !important;
    }
}

[validationstate="success"] {
    .react-select__control {
        border-color: $green !important;
    }

    .react-select.plain .react-select__control {
        border-color: $green !important;
    }
}

:lang(ar) {
    .custom-modal {

        .close-btn {
            right: auto;
            left: 0;

            &.left {
                left: auto;
                right: 0;
            }
        }
    }
}

.btn {
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    &:disabled {
        background-color: $disabled-btn-bg;
        border-color: $disabled-btn-bg;
        color: #28293D;
        opacity: 1;
    }

    &:focus,
    &:active:focus {
        box-shadow: none;
    }
}

.modal-dialog {
    @include media-breakpoint-down(md) {
        max-width: 335px !important;

        .modal-header {
            .modal-title {
                font-weight: 700 !important;
            }
        }
    }
}

.onboard-modal {
    max-width: 360px !important;
}