@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Black.eot');
    src: url('Exo2-Black.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Black.woff2') format('woff2'),
        url('Exo2-Black.woff') format('woff'),
        url('Exo2-Black.svg#Exo2-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-BlackItalic.eot');
    src: url('Exo2-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-BlackItalic.woff2') format('woff2'),
        url('Exo2-BlackItalic.woff') format('woff'),
        url('Exo2-BlackItalic.svg#Exo2-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Bold.eot');
    src: url('Exo2-Bold.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Bold.woff2') format('woff2'),
        url('Exo2-Bold.woff') format('woff'),
        url('Exo2-Bold.svg#Exo2-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-LightItalic.eot');
    src: url('Exo2-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-LightItalic.woff2') format('woff2'),
        url('Exo2-LightItalic.woff') format('woff'),
        url('Exo2-LightItalic.svg#Exo2-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Light.eot');
    src: url('Exo2-Light.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Light.woff2') format('woff2'),
        url('Exo2-Light.woff') format('woff'),
        url('Exo2-Light.svg#Exo2-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-ExtraBold.eot');
    src: url('Exo2-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ExtraBold.woff2') format('woff2'),
        url('Exo2-ExtraBold.woff') format('woff'),
        url('Exo2-ExtraBold.svg#Exo2-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Medium.eot');
    src: url('Exo2-Medium.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Medium.woff2') format('woff2'),
        url('Exo2-Medium.woff') format('woff'),
        url('Exo2-Medium.svg#Exo2-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-MediumItalic.eot');
    src: url('Exo2-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-MediumItalic.woff2') format('woff2'),
        url('Exo2-MediumItalic.woff') format('woff'),
        url('Exo2-MediumItalic.svg#Exo2-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-ExtraLightItalic.eot');
    src: url('Exo2-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ExtraLightItalic.woff2') format('woff2'),
        url('Exo2-ExtraLightItalic.woff') format('woff'),
        url('Exo2-ExtraLightItalic.svg#Exo2-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-BoldItalic.eot');
    src: url('Exo2-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-BoldItalic.woff2') format('woff2'),
        url('Exo2-BoldItalic.woff') format('woff'),
        url('Exo2-BoldItalic.svg#Exo2-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Italic.eot');
    src: url('Exo2-Italic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Italic.woff2') format('woff2'),
        url('Exo2-Italic.woff') format('woff'),
        url('Exo2-Italic.svg#Exo2-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-ExtraBoldItalic.eot');
    src: url('Exo2-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ExtraBoldItalic.woff2') format('woff2'),
        url('Exo2-ExtraBoldItalic.woff') format('woff'),
        url('Exo2-ExtraBoldItalic.svg#Exo2-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-ExtraLight.eot');
    src: url('Exo2-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ExtraLight.woff2') format('woff2'),
        url('Exo2-ExtraLight.woff') format('woff'),
        url('Exo2-ExtraLight.svg#Exo2-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Thin.eot');
    src: url('Exo2-Thin.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Thin.woff2') format('woff2'),
        url('Exo2-Thin.woff') format('woff'),
        url('Exo2-Thin.svg#Exo2-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-SemiBoldItalic.eot');
    src: url('Exo2-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-SemiBoldItalic.woff2') format('woff2'),
        url('Exo2-SemiBoldItalic.woff') format('woff'),
        url('Exo2-SemiBoldItalic.svg#Exo2-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-ThinItalic.eot');
    src: url('Exo2-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Exo2-ThinItalic.woff2') format('woff2'),
        url('Exo2-ThinItalic.woff') format('woff'),
        url('Exo2-ThinItalic.svg#Exo2-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-SemiBold.eot');
    src: url('Exo2-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Exo2-SemiBold.woff2') format('woff2'),
        url('Exo2-SemiBold.woff') format('woff'),
        url('Exo2-SemiBold.svg#Exo2-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Exo 2';
    src: url('Exo2-Regular.eot');
    src: url('Exo2-Regular.eot?#iefix') format('embedded-opentype'),
        url('Exo2-Regular.woff2') format('woff2'),
        url('Exo2-Regular.woff') format('woff'),
        url('Exo2-Regular.svg#Exo2-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

