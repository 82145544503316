.lineup-info-wrap {
    background-color: $white;
    border-radius: 5px 5px 0 0;
    // min-height: 700px;
}

.titlle-header {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-bottom: solid 1px #D7D7D7;

    .label-text {
        font-size: $font-size-xs;
        color: #333333;
    }

    .value-text {
        color: #333333;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.12px;
        line-height: 17px;
    }
}

.lineup-info-box {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    // background: url(../img/texture-lg.png) no-repeat center;
    // min-height: 300px;
}

.inner-header {
    // display: flex;
    // flex-direction: column;
    // font-family: $font-family-Inter;
    // font-size: $font-size-l;
    // line-height: 1.2;
    // text-transform: uppercase;
    // padding: 0 20px;
    // color: #333333;
    // text-align: center;
    color: #333333;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 15px;
}

.inner-contant {
    display: flex;
    flex-direction: row;
    padding: 20px;

    .flx-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1;
        background-color: #EFF1F5;
        border-radius: 8px;
        padding: 10px;
        margin-left: 10px;
        min-height: 100px;
        justify-content: center;

        &:lang(ar) {
            margin-left: 0;
            margin-right: 10px;
        }

        &:first-child {
            margin-left: 0;
            margin-right: 0;
        }

        .value {
            font-size: 24px;
            font-family: $font-family-Inter;
            font-weight: $font-weight-semibold;
            color: #333333;
            line-height: 1.2;
        }

        .label {
            font-size: $font-size-xs;
            color: rgba($color: #333333, $alpha: .6);
        }
    }
}

.score-grid {
    padding: 20px;
    border-bottom: solid 1px rgba($white, .1);
    text-align: center;

    >.title {
        font-size: $font-size-s;
        font-weight: $font-weight-bold;
        padding: 0 0 16px;
        text-transform: uppercase;
    }
}

.score-grid-content {
    display: flex;
    flex-wrap: wrap;

    .cell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        min-height: 100px;
        line-height: 1.2;
        border: solid 0px rgba($white, .1);

        .value {
            font-size: $font-size-l;
            font-weight: $font-weight-bold;
        }

        .label {
            font-size: $font-size-s;
            color: $gray-400;
            text-transform: capitalize;
        }

        &:first-child {
            border-width: 0 1px 0 0;
        }

        &:nth-child(3) {
            border-width: 1px 1px 0 0;
        }

        &:nth-child(4) {
            border-width: 1px 0 0 0;
        }
    }
}

.lineup-info-leaderboard {
    // padding: 20px 0;
    height: 170px;
    overflow-y: auto;

    @include media-breakpoint-down(md) {
        //height: 135px;
        height: auto;
    }
}

.lineup-leaderboard {
    >.title {
        @include clearfix;

        span {
            display: inline-block;
            // background-color: $secondary-bg-color;
            color: $white;
            font-size: $font-size-s;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            padding: 7px 5px 7px 14px;
            position: relative;

            @include media-breakpoint-down(md) {
                padding-left: 8px !important;
            }

            // padding-left: 5px;
            // &::after {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     right: -8px;
            //     width: 0;
            //     height: 0;
            //     border-style: solid;
            //     border-width: 32px 9px 0 0;
            //     border-color: $secondary-bg-color transparent transparent transparent;
            // }
            &:lang(ar) {
                &::after {
                    right: auto;
                    left: -9px;
                    border-width: 32px 0 0 9px;
                }
            }
        }

    }
}

.leaderboard-list {
    padding: 0 20px;
    color: $secondary-fg-color;

    @include media-breakpoint-down(md) {
        padding: 0 10px;
    }
}

.leaderboard-item {
    display: flex;
    border-top: solid 1px rgba($secondary-fg-color, .1);
    padding: 10px 0;
    max-height: 50px;

    &.head {
        padding: 15px 0 5px;
    }

    &.balnksss {
        display: none;
    }

    &:first-child {
        border-top: none;
    }

    .cell {
        display: flex;
        align-items: center;
        flex: 1 1;
        font-size: $font-size-xs;
        color: #999999;

        // font-weight: $font-weight-bold;
        .lbl {
            font-size: 12px;
            font-weight: $font-weight-normal;
            color: #999999;

            @include media-breakpoint-down(md) {
                font-size: $font-size-xs;
            }
        }

        &.fixw {
            max-width: 40px;
            min-width: 40px;
            place-content: flex-end;
        }

        &.fixw-l {
            font-weight: $font-weight-normal;
            max-width: 100px;
            min-width: 100px;

            &.text-center {
                justify-content: center;

                @include media-breakpoint-down(md) {
                    justify-content: flex-end;
                }
            }

            @media (max-width:767px) {
                min-width: 50px;
            }
        }

        i {
            display: flex;
            width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;

            &.icon-standing {
                color: $secondary-bg-color;
            }
        }
    }

    .icon-share {
        // background-color: $secondary;
        color: $dark-color;
        padding: 5px;
        font-size: $font-size-xs;
        margin: 0 10px;
        border-radius: 3px;
        cursor: pointer;
    }
}

.lineup-info-footer {
    padding: 0 20px 20px;
}

.league-page {
    .leaderboard-list {
        // background-color: $box-color;
        // box-shadow: $gray-box-shadow;
        color: $secondary-fg-color;
        border-radius: 0 4px 4px;
        padding-bottom: 18px;
    }

    .lineup-leaderboard {
        margin-top: 10px;

        &:first-child {
            margin-top: 0;
        }
    }
}

.highcharts-legend-item {
    pointer-events: none;
}

.highcharts-container {

    .highcharts-point,
    .highcharts-halo,
    .highcharts-color-undefined {
        fill: #F7CC44 !important;
    }

    text {
        color: #333333 !important;
        fill: #333333 !important;
    }

    // .highcharts-grid-line{
    //     stroke:rgba($color: $white, $alpha: .05) !important;
    //     // d: path("M 74 9.5 L 406 9.5");
    // }
    // d: path("M 74 84.5 L 406 84.5");
    .highcharts-axis-line {
        stroke: #979797 !important;
    }
}

.pitch-view.sport_id_5 {
    // zoom: 110% !important;

    @include media-breakpoint-down(md) {
        zoom: 1 !important;
        //82% !important;
    }
}

.highcharts-graph {
    stroke: #F7CC44;
}

.prize-tag {
    text-decoration: underline;
    color: $primary !important;
    font-size: 12px !important;
    letter-spacing: 0;
    line-height: 20px;
    cursor: pointer;
}

.no-data-league {
    color: #333333;
}

.title {
    &.n-data {
        display: none !important;
    }
}

.lineup-leaderboard.none {
    display: none;
}

.leaderboard-item.head.n-data {
    // display: none;
    padding-top: 30px !important;
}