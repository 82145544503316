.static-page-body {
    padding: 24px 0 0;
    // color: $secondary-fg-color;

    * {
        font-family: $font-family-Inter !important;
        color: #333 !important;

        @include media-breakpoint-down(md) {
            width: 100% !important;
            height: 100% !important;
        }
    }

    p {
        margin-left: 0 !important;
        margin-right: 0 !important;

        span {
            width: 100% !important;
            height: 100% !important;
        }
    }

    img {
        max-width: 100% !important;
        height: auto;
    }

    /*font[color] {
        color: #000 !important;
        span {
            color: #000 !important;
        }
    }*/
    table {
        width: 100%;
        margin-bottom: 15px;

        td {
            padding: 7px 10px;
            border-bottom: solid 1px #D4D6DF;

            &:first-child {
                width: 70%;
            }
        }

        thead {
            td {
                background-color: #D4D6DF;
                color: #666;
            }
        }
    }

    b,
    strong {
        font-weight: $font-weight-semibold;
    }

    a {
        color: $secondary-bg-color !important;
    }

    .bullet-list {
        list-style: disc;
        padding: 0 30px;
    }

    .decimal-list {
        list-style: decimal;
        padding: 0 30px;
    }

    .no-list {
        padding: 0 30px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $font-weight-semibold;
    }

    h1,
    h2,
    h3 {
        font-size: $font-size-l;
    }

    h4 {
        font-size: $font-size-l;
    }

    h5 {
        font-size: $font-size-m;
    }

    h6 {
        font-size: $font-size-s;
    }

}


.flex-full-height {
    display: flex;
    min-height: 100vh;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.coming-soon {
        background: url('../img/coming-soon-sm.png') no-repeat center bottom;
        background-size: contain;
        align-items: center;
        justify-content: flex-start;

        @include media-breakpoint-up(sm) {
            justify-content: center;
            background: url('../img/coming-soon.png') no-repeat center top;
            background-size: cover;
        }
    }

    .coming-soon-head {
        width: 100%;
        max-width: 992px;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        h2 {
            font-size: 40px;
            line-height: 1;
            font-weight: $font-weight-bold;
            margin: 100px 0 0;
            color: $secondary;

            @include media-breakpoint-up(sm) {
                margin: 0;
                font-size: 94px;
            }
        }

        h3 {
            font-size: 32px;
            line-height: 1;
            font-weight: $font-weight-normal;
            margin: 0 10px;

            @include media-breakpoint-up(sm) {
                font-size: 64px;
            }
        }
    }

    .vcenter {
        padding: 30px;
        text-align: center;

        img {
            max-width: 100%;
            object-fit: contain;
        }

        h2 {
            font-size: 24px;
            font-weight: $font-weight-bold;
            margin: 20px 0 10px;

            @include media-breakpoint-up(sm) {
                margin: 40px 0 20px;
                font-size: 44px;
            }
        }

        p {
            font-size: 18px;
            color: rgba($color: $white, $alpha: .5);

            @include media-breakpoint-up(sm) {
                font-size: 30px;
            }
        }
    }
}

[class*="center-container"] {
    max-width: 1186px;

    [class*="header-title"] {
        line-height: 1.4;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;

        &.text-bold {
            font-weight: $font-weight-bold;
        }
    }

    .header-title-50 {
        font-size: 25px;

        @include media-breakpoint-up(md) {
            font-size: 35px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 50px;
        }
    }

    .header-title-40 {
        font-size: 25px;
        margin-bottom: 15px;
        color: $secondary-fg-color;

        @include media-breakpoint-up(md) {
            font-size: 30px;
            margin-bottom: 25px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 40px;
            margin-bottom: 60px;
        }
    }

    .header-title-32 {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 40px;

        @include media-breakpoint-up(md) {
            font-size: 24px;
            margin-top: 10px;
            margin-bottom: 40px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 32px;
            margin-top: 20px;
            margin-bottom: 60px;
        }
    }
}

.center-container-second {
    padding-top: 50px;
    padding-bottom: 50px;

    @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.center-container-third {
    padding-top: 40px;
    padding-bottom: 30px;

    @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 60px;
    }
}


.fluid-container-row {
    display: flex;
    align-items: center;

    &.top-banner {
        background: url(../img/top-header-bg.jpg) no-repeat center bottom;
        background-size: cover;
        text-align: center;
        min-height: 476px;

        @include media-breakpoint-up(md) {
            min-height: 376px;
        }

        @include media-breakpoint-up(lg) {
            min-height: 576px;
        }
    }

    &.gray-container {
        background-color: $stats-box-bg;
    }

}

.top-header-content {
    p {
        margin: 10px 0 20px;
        font-size: 18px;

        br {
            display: none;
        }

        @include media-breakpoint-up(md) {
            margin: 10px 0;
            font-size: 18px;

            br {
                display: block;
            }
        }

        @include media-breakpoint-up(lg) {
            margin: 20px 0;
            font-size: 22px;
        }
    }
}

.feature-box-wrap {
    display: flex;
    // align-items: center;
    flex-direction: row;
    justify-content: space-around;

    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }

    .cell-wrap {
        flex: 50% 0 0;
        display: flex;
        padding-top: 30px;
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: flex-end;
            padding-right: 15px;

            &:nth-of-type(2n) {
                padding-right: 0;
                padding-left: 15px;
                justify-content: flex-start;
            }
        }

        @include media-breakpoint-up(lg) {
            justify-content: center;
            padding-top: 0;
            flex: 1 1 0;
        }
    }

    .cell {
        // flex: 1 1 0;
        width: 256px;
        height: 352px;
        border: solid 1px $gray-97;
        border-radius: 31px 31px 150px 150px;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;

        .top-cell {
            flex: 1 1;
            display: flex;
            align-items: center;

            p {
                font-size: 20px;
                line-height: 1.2;
                color: $secondary-bg-color;
                margin: 0;
            }
        }

        .figure {
            background-color: $stats-box-bg;
            width: 100%;
            padding-bottom: 100%;
            // position: absolute;
            // bottom: 15px;
            // left: 15px;
            border-radius: 100%;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .product-cell {
        // max-width: 320px;
        padding: 15px;
        text-align: center;
        display: flex;
        flex-direction: column;

        .paragraph {
            max-width: 320px;
            font-size: 16px;
            line-height: 1.5;
            margin: 10px auto 0;
            color: $secondary-fg-color;
        }
    }

    &.scrollable {
        overflow-x: auto;
        justify-content: flex-start;
        flex-wrap: nowrap;
        margin-left: -24px;
        margin-right: -24px;

        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.gamification_img {
    max-width: 100%;
    filter: sepia(60%) hue-rotate(336deg) saturate(320%) contrast(0.5);

    &.hidden-sm {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &.visible-sm {
        display: block;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}

.video_wrapper {
    position: relative;
    padding-bottom: 56.25%;

    /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.benefit-list {
    display: flex;
    flex-wrap: wrap;
}

.benefit-item {
    flex: 1 1;
    border-radius: 10px;
    background-color: #26252C;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 80px;
    min-width: 100%;
    margin: 12px 0;

    @include media-breakpoint-up(md) {
        height: 110px;
        min-width: calc(50% - 24px);
        margin: 12px;
    }

    &::before {
        content: '';
        background-color: $secondary-bg-color;
        width: 80px;
        height: 145px;
        position: absolute;
        top: -30px;
        transform: rotate(25deg);
        z-index: 0;
        left: -32px;

        @include media-breakpoint-up(md) {
            left: -20px;
        }
    }

    &:lang(ar) {
        &::before {
            transform: rotate(154deg);
            left: auto;
            right: -32px;

            @include media-breakpoint-up(md) {
                left: auto;
                right: -20px;
            }
        }
    }

    .num {
        border: solid 1px #E3E3E3;
        background-color: #37363C;
        border-radius: 100%;
        font-weight: $font-weight-bold;
        text-align: center;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        width: 55px;
        min-width: 55px;
        height: 55px;
        margin: 0 15px;
        font-size: 26px;

        @include media-breakpoint-up(md) {
            width: 76px;
            min-width: 76px;
            height: 76px;
            font-size: 36px;
            margin: 0 20px;
        }

        span {
            flex: 1 1;
        }
    }

    .content {
        font-size: 18px;
        padding-right: 20px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
    }
}

.title-stp {
    font-size: $font-size-l;
    font-weight: $font-weight-semibold;
    text-align: center;
    line-height: 1.2;
    padding-bottom: 15px;
    // border-bottom: solid 2px $secondary-bg-color;
    color: #333;
}
.flag-custom-view{
    width: 20px;
    margin-right: 5px;
}