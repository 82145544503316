.notification-container {
    max-width: 536px;
    padding-top: $vertical-gap;
    padding-bottom: $vertical-gap;

    @include media-breakpoint-down(md) {
        padding-top: 0;
    }

    .notification-item {
        background-color: $white;
        border-radius: 4px;
        margin-top: 15px;

        &.unread {
            background-color: #007bff21;
        }
    }
}

.notification-header {
    font-size: 18px;
    border-bottom: solid 1px rgba($gray-97, .22);
    font-weight: $font-weight-bold;
    padding-bottom: 6px;
}

.notification-loader {
    position: relative;
}

.notification-list {
    @include clearfix;

    &.pos-r {
        min-height: 76px;
    }
}

.notification-item {
    @include clearfix;
    font-size: 12px;
    line-height: 1.3;
    padding: 15px;
    border-bottom: solid 1px rgba($gray-97, .22);
    background-color: $primary-fg-color;
    color: #999;

    &:lang(ar) {
        text-align: right;
    }

    a,
    span {
        color: $secondary-bg-color !important;
        font-weight: $font-weight-medium
    }

    p {
        margin: 0;
    }

    small {
        color: $black;
    }

    .icn {
        float: left;
        width: 20px;
        height: 20px;
        font-size: 20px;
        margin: 3px 18px 0 3px;

        &:lang(ar) {
            margin: 3px 3px 0 18px;
            float: right;
        }
    }
}


.notif-triger {
    vertical-align: middle;
    font-size: 18px;
    width: 24px;
    height: 24px;
    display: inline-block;
    padding: 2px;
    cursor: pointer;
}

.setting-header {
    margin-top: 10px;
    padding: 3px 20px;
    background-color: $primary;
    text-align: center;
}

.setting-item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px rgba($color: $gray-col, $alpha: .6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;

    .title {
        font-size: $font-size-m;
        line-height: 1.3;
    }

    .desc {
        font-size: $font-size-xs;
        line-height: 1.4;
    }

    .cell {
        display: flex;
        flex-direction: column;
        cursor: default;
    }
}

.react-switch {
    .react-switch-bg {
        margin: 0 !important;
        height: 26px !important;
        border-radius: 15px !important;
        background-color: #4e4e4e !important;
    }

    &.disabled {
        opacity: 1 !important;
    }

    .react-switch-handle {
        width: 24px !important;
        height: 24px !important;
        top: 1px !important;
        transform: translateX(1px) !important;
    }

    &.react-switch-checked {
        .react-switch-handle {
            transform: translateX(23px) !important;
        }

        .react-switch-bg {
            background-color: $green !important;
        }
    }

}

.notification-dropdown {
    .dropdown {
        z-index: $zindex-fixed;
    }
}

.notification-trigger {
    width: 34px;
    height: 34px;
    font-size: 20px;
    border-radius: 100%;
    padding: 5px 0;
    text-align: center;
    // margin: 0 20px;
    position: relative;

    // background-color: $gray-col;
    color: $white;
    cursor: pointer;
    transition: all .1s ease-out;

    &.active {
        background-color: $secondary-bg-color;
        color: $white;
    }

    .badge-count {
        position: absolute;
        top: 0;
        left: 18px;
        z-index: 1;
        min-width: 18px;
        background-color: $red;
        color: $white;
        font-size: $font-size-xs;
        border-radius: 12px;
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
    }
}

.dark-dropdown {
    background-color: $primary-fg-color;
    border: none;
    box-shadow: $box-shadow-1;

    .dropdown-item {
        color: $secondary-fg-color;

        &:hover,
        &:active,
        &:focus {
            background-color: darken($gray-col, 2%);
        }
    }
}

.notification-content {
    max-height: calc(100vh - 116px);
    overflow: hidden;
    min-width: 366px;
    padding: 0;
    position: relative;
    min-height: 175px;

    @include media-breakpoint-down(md) {
        .show {
            display: block;
            right: 0px;
            left: unset !important;
            transform: translate3d(0, 34px, 0px) !important;
        }

    }

    @extend .dark-dropdown;

    >.head {
        height: 45px;
        padding: 10px 15px;
        border-bottom: solid 1px $body-bg;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            color: $secondary-bg-color;
        }
    }

    .notification-body {
        min-height: 130px;
        max-height: calc(100vh - 116px - 45px);
        overflow: auto;
    }

    .notification-item {
        background-color: transparent;
        color: $black;
        cursor: pointer;
    }
}

.notification-footer {
    display: block;
    position: sticky;
    bottom: 10px;
    left: 50%;
    padding: 5px;
    background-color: $secondary-bg-color;
    color: $white;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    text-align: center;
    width: 78px;
    transform: translateX(-50%);
    cursor: pointer;
    border-radius: 20px;

    &:hover {
        color: #FFF;
        //background-color: darken($secondary-bg-color, 5%);
    }
}

.notification-menu {
    float: right;

    .notification-menu-toggle {
        font-size: 16px;
        width: 24px;
        height: 24px;
        padding: 4px;
        text-align: center;
        border-radius: 100%;
        cursor: pointer;

        &:hover {
            background-color: $gray-col;
        }
    }
}
.mark-read-drp{
    background-color: $primary;
    border-radius: 50px;
    &.show{
        transform: none !important;
    }
    .icon-others{
        position: relative;
        top: -4px;
        font-size: 14px;
    }
    .dropdown-item{
        color: $gray-900 ;
        background-color: transparent !important;
    }
    
}
.noti-header{
    color: $primary;
}
.main-noti-icn{
    .notification-menu-toggle:hover{
        .icon-others{
            color: $white;
        }
    }
    .dropdown-item{
        color: #000;
        &:hover{
            background-color: transparent;
        }
    }
}