.player-card-top {
    @include clearfix;
    min-height: 184px;
    background-color: #EFF1F5;
    padding: 30px;
    position: relative;
    overflow: hidden;

    .player-action-toggle {
        display: block;
        position: absolute;
        top: 14px;
        right: 14px;
        // height: 26px;
        // width: 110px;
        width: 28px;
        height: 28px;
        border-radius: 4px;
        background-color: transparent;
        border: solid 1px $gray-999;
        font-size: 24px;
        padding-top: 1px;
        font-weight: 400;
        line-height: .85;
        text-align: center;
        // padding: 6px 5px;

        &.remove {
            border-color: #EB4A3C;
            background-color: #EB4A3C !important;
            color: $white;
        }
    }

    .thumb-col {
        flex: 0 0 80px;
        max-width: 80px;
    }

    .thumb {
        float: left;
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: $white;
        border: solid 1px #D1D0D0;
        position: relative;
        margin: 0;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        // text-align: center;
        .jersey {
            // width: 56px;
            // display: block;
            // width: 60px;
            // height: 60px;
            // object-fit: cover;
            // object-position: center top;
            // border-radius: 100%;
            // position: absolute;
            // top: 0;
            // left: 0;
            height: 60px;
            width: auto;
        }
    }

    .flag {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
    }
}

.player-name {
    margin: 10px 0 0;
    text-transform: uppercase;
}

.player-stats-tbl-wrap {
    @include clearfix;
    min-height: 55px;
    position: relative;

    .alert {
        margin: 0;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        // top: 45px;
        background: transparent !important;
        border: none !important;
        top: 20px;
    }
}

.player-stats-tbl {
    text-align: center;
    padding: 15px 5px 15px 0;

    .table-txt {
        color: #333333;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
    }

    thead {
        tr {
            td {
                font-weight: 600;
            }
        }
    }

    .text-bold {
        font-size: 16px;
    }

    .text-muted {
        font-size: 12px;
        color: #969696 !important;
    }

    .cell {
        flex: 0 0 94px;
        max-width: 94px;
    }
}

.player-card-body {
    @include clearfix;
    min-height: 250px;
    padding: 0 30px 25px;
    position: relative;
    max-height: 360px;
    overflow-y: auto;

    .nav-tabs {
        position: sticky;
        top: -1;
        z-index: 9999;
        background: white;
        margin: 0px -20px;
    }

    @include media-breakpoint-down(md) {
        padding: 0 20px 15px;
    }
}

.player-card-footer {
    @include clearfix;
    min-height: 36px;
    // background: url(../img/player-card-footer.png) no-repeat center;
    background-size: auto 36px;

    .profile-view-btn {
        display: block;
        margin: auto;
        color: $primary;
        max-width: 133px;
        padding: 10px 0;
        text-align: center;
        line-height: 1.2;
        text-decoration: underline;
        margin-bottom: 20px;
    }
}

.player-role-btn {
    margin: 13px 0;
    height: 44px;
    border: solid 1px $gray-666;
    border-radius: 4px;
    @extend .gutters-7px;
    text-align: center;
    color: $dark-color !important;

    &:first-child {
        margin-top: 82px;
    }

    .col {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .title {
        flex: 0 0 74%;
        max-width: 74%;
        font-size: $font-size-s;

        @media (max-width:767px) {
            flex: 0 0 47%;

            &:lang(mal) {
                font-size: 10px;
            }
        }
    }

    .role-img {
        display: inline-block;
        width: 20px;
        height: 20px;
    }

    .check {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 2.9px;
        background-color: $white;
        vertical-align: middle;
        border: solid 1px $gray-666;
        padding: 4px 3px;
    }

    &.active {
        border-color: $secondary-bg-color;
        color: $white !important;
        background-color: $secondary-bg-color;

        .check {
            border-color: $white;
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 12px;
                height: 6px;
                border: solid $gray-666;
                border-width: 0 0 2px 2px;
                transform: rotate(-45deg);
            }
        }
    }
}

.week-list {
    @include clearfix;
    // padding: 0 5px 15px;
    margin: 25px 0 30px;
    border-radius: 5px;
    background-color: #EFF1F5;
    height: 54px;
    padding-top: 5px;

    .week-item {
        // width: 72px;
        height: 45px;
        border-radius: 2px;
        // background-color: #E7E8EC;
        text-align: center;
        line-height: 1.2;
        padding: 8px 0;
        margin: 0 6px;
        cursor: pointer;
        font-weight: 600;

        .label {
            font-size: 12px;
            text-transform: uppercase;
            // color: $gray-600;
            color: #979CA5;
        }

        .value {
            // color: $dark-color;
            color: #333333;
            // @extend .text-bold;
        }

        &.active {
            background-color: $white;
            border-radius: 5px;

            .label {
                // color: rgba($white, .6);
                color: #979CA5;
            }

            .value {
                // color: $white;
                color: #333333;
            }
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: $body-color;
    }

    .slick-prev {
        left: -17px;
    }

    .slick-next {
        right: -19px;
    }

    .slick-slider {
        margin: 0;
    }
}

.player-stats-tbl {
    width: 100%;
    font-size: 12px;
    line-height: 1.2;
    font-weight: $font-weight-medium;

    .tbl-label {
        width: 210px;
        color: #969696;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        font-weight: 500 !important;
    }

    tr {
        td {
            padding: 15px 0;

            &:first-child {
                text-align: left;
            }
        }
    }

    thead {
        tr {
            // border-bottom: solid 1px rgba($gray-97, .26);
        }
    }

    tfoot {
        tr {
            border-top: solid 1px #E8E8E8;
            padding-top: 20px !important;
        }
    }

    .total-val {
        font-size: 18px;
        font-weight: $font-weight-bold;
    }
}

.team-date-detail {
    float: left;
    margin: 0;
    padding-bottom: 1rem;
}

:lang(ar) {
    .player-card-top {
        .player-action-toggle {
            right: auto;
            left: 14px;
        }

        .thumb {
            float: right;

            .flag {
                right: auto;
                left: 0;
            }
        }
    }

    // .player-role-btn {
    //     &.active {
    //         .check {
    //             &::before {
    //                 transform: rotate(45deg) scaleX(-1);
    //             }
    //         }
    //     }
    // }

    .week-list {
        .week-item {
            width: 92px;
        }

        .slick-prev {
            left: -17px;
        }

        .slick-next {
            right: -19px;
        }

        .slick-slider {
            margin: 0;
        }
    }

    .player-stats-tbl {
        tr {
            td {
                &:first-child {
                    text-align: right;
                }
            }
        }
    }

    .team-date-detail {
        float: right;

        span {
            float: left;
            margin-right: 5px;

            &.text-muted {
                float: right;
                margin-left: 5px;
            }
        }

        .date-time-moment {
            display: inline-block;
        }
    }

    .player-card-footer {
        .profile-view-btn {
            font-size: 10px;
            padding: 12px 0;
        }
    }
}


.stats-match-header {
    // @include clearfix;
    // color: $dark-color;
    // font-weight: $font-weight-bold;
    // padding: 5px 15px;
    // background-color: $gray-300;
    // margin-left: -15px;
    // margin-right: -15px;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-transform: uppercase;

    .team-date-detail {
        // padding: 0;
        padding: 0 0 20px 0;
    }
}

.w-fix-img {
    width: 55% !important;
    margin: 0 auto !important;
}

.row-bg-pcr {
    margin-top: 35px;
    border-radius: 5px;
    background-color: $white;
    width: 70%;

    @media (max-width:767px) {
        width: auto;

        &.row>* {
            padding: 0px !important;
        }
    }
}

.right-big-jersy {
    position: absolute;
    height: 170px;
    right: -50px;
    bottom: 0;

    &:lang(ar) {
        right: unset;
        left: 0;
        transform: scaleX(-1);
    }

    @media (max-width:767px) {
        display: none;
    }
}

.show-arrows-slider {
    .slick-prev {
        left: -22px;

        &:before {
            color: $black;
        }

    }

    .slick-next {
        right: -22px;

        &:before {
            color: $black;
        }
    }

}
.card-fixture-wrap{
    .weeek-block{
        background-color: transparent;
        padding: 15px 0 15px 0;
        font-size: 14px;
    }
    .nav-tabs{
        border-bottom: 0;
        justify-content: center;
        .nav-link{
            color: #979CA5;
            font-size: 14px;
            font-weight: $font-weight-semibold;
            border: 0;
            &.active{
                border:0;
                border-bottom: 2px solid;
                background-color: transparent;
                border-bottom-color: $primary-bg-color;
                color: $primary-bg-color;
            }
            &:hover{
                border-color: $primary-bg-color;
            }
        }
    }
    .fixtures-list{
        background-color: transparent;
        margin: 0 -20px;
       // margin-top: 20px;
    }
    .fixture-item{
        cursor: initial;
        .cell {
            &.mid {
                margin: 0 auto;
                background-color: #e7e7e7;
                .score-capsule{
                    background-color: #e7e7e7;
                    line-height: 11px;
                }
            }
            
        }
        .fx-flag {
            width: 25px;
            height: 25px;
            img{
                width: 25px;
                height: 25px;
            }
        }
        
        .fx-name {
            .mob-l-name{
                display: block;
            }
        }
        
    }
}