
@mixin container-margin-none() {
    margin-left: -$grid-gutter-width * .5;
    margin-right: -$grid-gutter-width * .5;
}

@mixin flexwrap() {
    display: flex;
    flex-wrap: wrap;
    > * {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}


// Live Indication
@-webkit-keyframes live {
    0%, 80%, 100% { -webkit-transform: scale(0.6) }
    40% { -webkit-transform: scale(1.0) }
  }
  @keyframes live {
    0%, 80%, 100% { 
      transform: scale(0.6);
      -webkit-transform: scale(0.6);
    } 40% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
  
@mixin livenow($color) {
    width: 18px;
    display: inline-block;
    vertical-align: top;
    padding: 2px 0 0;
    > div {
        vertical-align: middle;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        position: absolute;
        margin: 0 auto;
        border:3px solid rgba($color,1);
        animation: live 1.4s infinite ease-in-out;
        animation-fill-mode: both;
        &:nth-child(1) {
            background-color:rgba($color,0.3);
            background-color:rgba($color,1);
            animation-delay: -0.1s;
        }
        &:nth-child(2) {
            animation-delay: 0.16s;
        }
        &:nth-child(3) {
            animation-delay: 0.42s;
            border:3px solid rgba($color,0.5);
        }
        &:nth-child(4) {
            border:3px solid rgba($color,1);
            animation-delay: -0.42s;
        }
    }
}
