.stadium-component {
    padding-top: 30px;
    padding-bottom: 0px;
    background-image: url(../img/sf.png);
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(md) {
        padding-top: 75px;
        background-image: none;
        padding-bottom: 20px !important;
    }
}

.pitch-header {
    color: $white;
}


.pitch-header-top {
    min-height: 36px;
    display: flex;
    justify-content: space-between;
    color: $secondary-fg-color;
    align-items: center;

    // > * {
    //     flex: 1 1;
    // }
    .cell {
        display: flex;
        align-items: center;
    }
}

.ph-title {
    font-size: $font-size-l;
    font-weight: $font-weight-bolder;
    font-style: italic;
}

.ph-subtitle {
    font-size: $font-size-s;
}

.pitch-header-bottom {
    border-top: solid 1px $footer-border-color;
    padding: 15px 0;
    color: $secondary-fg-color;
    margin-top: 15px;
    display: flex;

    @include media-breakpoint-down(md) {
        // padding: 15px;
        padding: 15px 0 15px 15px;
        padding-bottom: 35px;
        position: relative;
    }

    >* {
        flex: 1 1;
    }
}

.ph-info-block {
    max-width: 160px;
    display: flex;
    flex-direction: column;

    .ph-label {
        font-size: $font-size-xs;
        opacity: 0.6;

        &:lang(mal) {
            font-size: 10px;
        }

        @include media-breakpoint-down(md) {
            font-size: 10px;
        }
    }

    .ph-value {
        font-size: 16px;
        font-weight: $font-weight-bold;

        @include media-breakpoint-down(md) {
            font-size: $font-size-xs;
        }

        &.flx {
            display: flex;
            // justify-content: space-between;
            align-items: center;

            * {
                flex: 1 1 0;
            }

            .txt {
                font-size: $font-size-xs;
                font-weight: $font-weight-normal;
                color: rgba(#26FF1D, .5);
                text-transform: uppercase;
                min-width: 70%;
            }

            .num {
                color: $secondary;
            }
        }
    }
}

.pitch-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-down(md) {
        // position: relative;


        .formation-trigger {
            position: absolute;
        }
    }

    .ctrl-btn {
        border: none;
        background-color: transparent;
        color: $secondary-fg-color;
        font-size: $font-size-xs2;
        text-transform: uppercase;
        text-align: center;
        padding: 0;
        margin: 0 0 0 20px;

        @include media-breakpoint-down(md) {
            // z-index: 99999999;
        }

        i {
            display: block;
            width: 20px;
            height: 20px;
            font-size: 16px;
            margin: 0 auto 3px;
        }

        &:disabled {
            opacity: .5;
        }

        .ph-label {
            opacity: 0.6;
        }
    }

}


.deadline-clock {
    display: flex;
    align-items: center;

    .icon-hourglass {
        width: 30px;
        height: 30px;
        font-size: 30px;
        margin: 0 8px;
    }

    .clock-title {
        opacity: 0.6;
        color: $white;
        font-size: 12px;
        letter-spacing: -0.1px;
        line-height: 14px;
    }

    .time-value {
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 1.2;
        text-transform: uppercase;

        .warn-col {
            color: $secondary;
        }

        .danger-col {
            color: $red;
        }
    }

    @include media-breakpoint-down(md) {
        .icon-hourglass {
            width: 20px;
            height: 20px;
            font-size: 20px;
        }

        .clock-title {
            font-size: 10px;
        }

        .time-value {
            font-size: 12px;
        }
    }
}

.bottom-pitch-control {
    margin: 10px 0 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
        font-weight: 600;
        // min-width: 280px;
        height: 44px;
        width: 190px;
        border-radius: 10px;
        margin: 0 10px;
        &:lang(mal){
            font-size: 10px;
            padding-top: 7px;
        }
    }

    .text {
        padding: 0 10px;
    }
    &.mob{
     display: none;   
    }
    @include media-breakpoint-down(md) {
        margin: 25px 0 15px;
        &.web{
            display: none;
        }
        &.mob{
            display: block;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            margin-bottom: 0;
            .btn{
                width: 100%;
                border-radius: 0;
                margin: 0;
            }
        }
    }
    
    
}


.cnv-select-img {
    display: block;
    width: 110px;
    margin: 0 auto 28px;
}

.roster-mobile-visible {
    @media (min-width: 992px) {
        display: none !important;
    }
}

.roster-drawer {
    background-color: #26252c;
    // background-color: $gray-900;
    overflow: hidden !important;

    .icon-about {
        display: none;
    }

    .team-name-box {
        display: none;
    }

    .mobile-hidden {
        display: block !important;
    }

    .drawer-close {
        position: absolute;
        right: 0;
        top: 13px;
        width: 40px;
        height: 40px;
        font-size: 18px;
        display: block;
    }

    .team-field-header {
        padding: 20px 0;
        position: relative;

        .info-box {
            padding: 2px 14px 1px;

            &:first-child {
                padding-left: 14px;
            }

            .label {
                font-size: 9px;
            }

            .value {
                font-size: 11px;
            }
        }

        .deadline-clock {
            padding-left: 14px !important;

            img {
                width: 32px;
                height: 32px;
                margin: 0 10px 0 0;
            }

            .label {
                font-size: 9px;
                margin-top: 3px;
            }

            .value {
                font-size: 11px;
            }
        }
    }

    .playerlist-container {
        margin-top: 0;
        margin-bottom: 0;
        border: none;
        padding-bottom: 0;
        max-height: calc(100% - 108px);
        overflow: auto;
        overflow-x: hidden;
    }

    .react-select {
        &.outline {
            .react-select__control {
                @include media-breakpoint-down(md) {
                    background-color: rgba(255, 255, 255, 0.1);

                    // min-height: 36px;
                }
            }

            // @include media-breakpoint-down(md){
            //     background-color:#333333 !important;
            // }
        }
    }

}

.roster-drawer-wrap {
    .playerlist-container {
        position: static;
    }

    .player-list.list,
    .player-list-wrap {
        max-height: none !important;
        // position: absolute;
        // bottom: 0;
        // left: 15px;
        // right: 15px;
        // height: auto;
        // top: 228px;
        // &.filter-open {
        //     top: 393px;
        // }
    }

    .player-list-wrap {
        // margin-top: 0;
        // top: 202px;
        // &.filter-open {
        //     // top: 108px;
        //     top: 367px;
        // }
    }

    .player-list-pagination {
        left: 5px;
    }

    >div {
        top: 55px !important;
    }
}

.roster-trigger {
    position: relative;
    background-image: url(../img/arrow_bg_img.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // clip-path: polygon(18% 39%, 100% 38%, 100% 58%, 17% 59%, 0 49%);
    position: absolute;
    width: 135px;
    height: 30px;
    // background-color: #212121;
    // border-radius: 4px 0 0 4px;
    right: 0;
    top: -23%;
    transform: translateY(-76%);
    text-align: center;
    padding-top: 3px;

    // &::before {
    //     position: absolute;
    //     content: '';
    //     width: 35px;
    //     height: 30px;
    //     // background: $white;
    //     left: -35px;
    //     border-style: solid;
    //     border-width: 15px 26.0px 15px 0;
    //     border-color: transparent $white transparent transparent;
    // }

    @include media-breakpoint-down(md) {
        right: -13px;
        // background: $white;
        top: -16.75%;
    }

    @extend .roster-mobile-visible;

    .label-wrap {
        display: block;
        width: 37px;
        height: 65px;
        padding-top: 10px;

    }

    .label {
        color: $white;
        font-size: 10px;
        transform: rotate(-90deg);
        transform-origin: 26px 26px;
        white-space: nowrap;
        display: block;
        width: 50px;
        height: 37px;
        padding: 11px 5px 0 0;
    }

    .icon-tshirt {
        color: $white;
        font-size: 15px;
        display: block;
        text-align: center;
        padding: 7px 0 5px;
        border-bottom: solid 1px rgba($white, 0.14);
    }

    .icon-previous {
        height: 25px;
        color: $white;
        font-size: 15px;
        display: block;
        text-align: center;
        padding: 5px 0;
    }

    .players-txt {
        color: $primary;
        font-family: $font-family-Inter;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: -0.09px;
        line-height: 13px;
        padding-left: 20px;
    }
}

.roster-mobile-hide {
    @media (max-width: 991px) {
        display: none !important;
    }
}

.pitch-wrap-pos {
    position: relative;

    // @include media-breakpoint-down(md) {
    //     margin-left: -20px;
    // }
}

.formation-trigger {
    // position: absolute;
    // z-index: 1;
    // top: 20px;
    // right: 15px;
    // border-radius: 25px;
    // height: 38px;
    // min-width: 100px;
    // padding: 7px 20px;
    // background-color: $secondary;
    // border: solid 2px $white;
    // color: $black;
    // cursor: pointer;
    // text-align: left;
    // font-size: 18px;
    // line-height: 1.2;
    // user-select: none;
    box-sizing: border-box;
    height: 35px;
    width: 143px;
    border: 1px solid $white;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 34px;
    padding-left: 10px;
    // padding-bottom: 5px;

    &.disabled {
        filter: grayscale(1);
        pointer-events: none;
    }

    &.is_trasfer {
        text-align: center;
        filter: grayscale(1);
        pointer-events: none;
    }

    .icon-down {
        display: block;
        position: absolute;
        right: 9px;
        top: 10px;
        color: $white;
        font-size: 14px;
    }

    @include media-breakpoint-down(md) {
        min-width: 77px;
        height: 30px;
        font-size: 16px;
        line-height: 1.2;
        padding: 4px 14px;
        bottom: 2px;
        left: 15px;

        .icon-down {
            right: 10px;
            top: 10px;
            font-size: 11px;
        }
    }
}

.formation-list {
    @include clearfix;
    text-align: center;

    .formation-item {
        display: inline-block;
        width: 115px;
        height: 143px;
        text-align: center;
        padding: 10px;
        border-radius: 6px;
        border: 1.5px solid #E4E4E4;
        cursor: pointer;
        vertical-align: top;
        margin: 7px;
        position: relative;

        @include media-breakpoint-down(md) {
            margin: 4px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .caption {
            background-color: $secondary-bg-color;
            color: $white;
            border-radius: 0 0 6px 6px;
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: 10px;
            height: 34px;
            padding: 6px 0;
        }

        &.selected {
            color: $white;
            // background-color: $primary;
            // background: linear-gradient(350deg, $primary 28%, $secondary 100%);
            background-color: $secondary-bg-color;
            box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.21);

            .caption {
                background-color: $white;
                color: #212121;
            }
        }
    }
}

.formation-modal {
    @extend .custom-modal;
    user-select: none;

    .modal-content {
        background: #EFF1F5 !important;
        border: none !important;
        border-radius: 12px;

        .icon-cancel {
            // color: #333333 !important;
        }
    }

    // .modal-title {
    //     color: #333 !important;
    // }

    .modal-body {
        padding: 28px 23px;

        @include media-breakpoint-down(md) {
            padding: 20px 0 10px;
        }
    }

    .modal-footer {
        @include media-breakpoint-down(md) {
            padding: 0 36px 30px;
        }
    }

    @media (min-width: 576px) {
        max-width: 435px;
    }

    .close-btn {
        top: -40px;
        right: -40px;
        background-color: $primary;
        border-radius: 50%;
        color: $white;
    }
}

.my-info {
    position: relative;
    background-image: url(../img/arrow_bg_img.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    // clip-path: polygon(18% 39%, 100% 38%, 100% 58%, 17% 59%, 0 49%);
    position: absolute;
    width: 135px;
    height: 30px;
    // background-color: #212121;
    // border-radius: 4px 0 0 4px;
    right: -12px;
    top: -3%;
    transform: translateY(-76%);
    text-align: center;
    padding-top: 3px;

    // &::before {
    //     position: absolute;
    //     content: '';
    //     width: 35px;
    //     height: 30px;
    //     // background: $white;
    //     left: -35px;
    //     border-style: solid;
    //     border-width: 15px 26.0px 15px 0;
    //     border-color: transparent $white transparent transparent;
    // }

    @include media-breakpoint-down(md) {
        // right: -13px;
        // background: $white;
        // top: -3.6%;
    }

    @extend .roster-mobile-visible;

    .label-wrap {
        display: block;
        width: 37px;
        height: 65px;
        padding-top: 10px;

    }

    .label {
        color: $white;
        font-size: 10px;
        transform: rotate(-90deg);
        transform-origin: 26px 26px;
        white-space: nowrap;
        display: block;
        width: 50px;
        height: 37px;
        padding: 11px 5px 0 0;
    }

    .icon-tshirt {
        color: $white;
        font-size: 15px;
        display: block;
        text-align: center;
        padding: 7px 0 5px;
        border-bottom: solid 1px rgba($white, 0.14);
    }

    .icon-previous {
        height: 25px;
        color: $white;
        font-size: 15px;
        display: block;
        text-align: center;
        padding: 5px 0;
    }

    .players-txt {
        color: $primary;
        font-family: $font-family-Inter;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: -0.09px;
        line-height: 13px;
        padding-left: 20px;
    }
}
.disbale-auto{
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}
.add-overflow{
    overflow: hidden !important;
}
.violation-info{
    text-align: center;
    background-color: rgba(0,0,0,0.2);
    color: $white;
    border-radius: 8px;
    max-width: 450px;
    margin: 0 auto 20px;
    border: 1px solid #EB4A3C;
    font-size: 12px;
    padding: 4px 0;
    @media (max-width:767px) {
        font-size: 10px;
    }
}

.share-block-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    .team-share-option{
        text-align: center;
        margin-left: 50px;
        cursor: pointer;
        span{
            display: block;
            text-transform: uppercase;
            font-size: 12px;
            color: $white;
        }
        @media (max-width:767px) {
            margin-left: 25px;
            span{
                font-size: 10px;
            }
            
        }
    }
  }
  .share-team-modal{
    &.plc-modal{
        .modal-content{
            min-width: auto;
            border-radius: 12px;
        }    
    }
    .modal-content{
        padding: 15px;
        background-color: #182054;
        color: $white;
        .title{
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 20px;
        }
        .sharing-options{
            text-align: center;
            &.dis-option{
                opacity: 0.5;
            }
            button{
                background-color:$gradient-right !important;
                width: 36px;
                height: 36px;
                border-radius: 50px;
                border:none;
                margin: 0 10px;
                i{
                    font-size: 16px;
                    margin: 0 auto;
                    vertical-align: middle;
                    display: inline-block;
                    color: $white;
                }
            }
            
        }
    }
  }