// HEADER
$breakpoint-1: 1221px;
$breakpoint-2: 910px;
$breakpoint-3: 991px;
$hdr-height: 60px;

.app-toolbar {
    user-select: none;
    width: 100%;
    // background-color: $secondary;
    min-height: 70px;
    // position: sticky;
    // top: 0;
    // z-index: 1;
    box-shadow: 0 1px 0 0 $gray-col;


    .app-logo {
        display: inline-block;
        height: 50px;
        margin: 8px 0;

        img {
            height: 100%;
            object-fit: contain;
            display: inline-block;
        }
    }

    .border-btn {
        border-radius: 4px;
        display: inline-block;
        font-size: 16px;
        line-height: 1.2;
        font-weight: $font-weight-bold;
        color: $secondary;
        border: solid 1px $secondary;
        width: 130px;
        text-align: center;
        cursor: pointer;
        padding: 12px;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            padding: 8px;
            width: 90px;

            // display: none; // XXX
        }

        &.link {
            width: auto;
            padding: 0;
            margin: 12px 42px 12px 0;
            border-color: transparent;

            @include media-breakpoint-down(md) {
                margin: 12px 24px 12px 0;
            }
        }


    }
}

.top-app-toolbar {
    background-color: $gray-col;
    background-color: $gray-col-light;
    height: 46px;
    min-height: 46px;

    .league-select-dropdown {
        position: inherit;
        float: right;
        font-size: 14px;
        line-height: 1.3;
    }

    .league-select-toggle {
        color: $white;
        cursor: pointer;
        margin: 5px 0;

        .slt-title {
            font-size: 14px;
            color: $secondary;
        }

        .slt-value {
            font-size: 12px;

            span {
                color: $secondary;
            }
        }
    }

    .league-select-content {
        position: fixed !important;
        width: 100%;
        min-width: 100%;
        border-radius: 0;
        top: 0 !important;
        bottom: 0 !important;
        transform: inherit !important;
        padding: 40px 0;
        overflow-y: auto;
        background-color: transparent;

        .icon-close {
            color: $dark;
            padding: 5px;
            font-size: 20px;
            position: absolute;
            top: 5px;
            right: 7px;
            cursor: pointer;
        }
    }
}

.login-profile-toggle {
    display: flex;
    text-align: inherit;

    // color: $secondary-fg-color;
    .avtar {
        width: 34px;
        height: 34px;
        // margin: 0 10px;
        border-radius: 100%;
        overflow: hidden;
        // background-color: $gray-col;
        cursor: pointer;

        img {
            width: 34px;
            height: 34px;
            object-fit: cover;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.2;
        text-align: right;

        .user-name {
            font-size: $font-size-s;
        }

        .user-rank {
            font-size: $font-size-xs;
        }
    }
}

.header-menu-icn {
    display: none;

    @media only screen and (max-width: $breakpoint-3) {
        color: $secondary-fg-color;
        display: block;
        float: right;
        // width: 60px;
        // height: $hdr-height;
        font-size: 25px;
        margin: 19px 8px;
        cursor: pointer;
    }
}

.select-league-wrap {
    .modal-content {
        background-color: #EFF1F5 !important;
        color: $modal-color;
    }

    .close-btn {
        color: $primary;
    }
}


// Default Header #2
.default-header {
    width: 100%;
    min-height: $hdr-height;
    // background: $primary;
    background: linear-gradient(94deg, rgba(37,59,137,1) 31%, rgba(7,21,68,1) 100%);
    box-shadow: 0 .2rem .5rem $black;

    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        z-index: 1049;
    }

    // border-bottom: 1px solid $secondary-bg-color;
}

.site-logo {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: $hdr-height;

    .logo-icon {
        display: flex;
        align-items: center;
        max-width: 120px;
        height: $hdr-height * .7;

        img {
            max-width: 120px;
            height: $hdr-height * .7;
            object-fit: contain;
            object-position: left center;
        }
    }

    &:lang(ar) {
        float: right;
    }

    @include media-breakpoint-down(md) {
        float: none;
        justify-content: space-between;
    }
}

.hdr-main-menu {
    float: left;
    font-size: 0;
    margin-left: 64px;

    &:lang(ar) {
        margin-left: auto;
        margin-right: 64px;
        float: right;
    }

    @media only screen and (max-width: $breakpoint-3) {
        margin-left: 0;
        float: none;
        width: 100%;
        display: none;
    }
}

.hdr-sub-menu {
    float: right;

    &:lang(ar) {
        float: left;
    }

    font-size: 0;

    @media only screen and (max-width: $breakpoint-3) {
        float: none;
        display: none;
    }
}

.global-nav {
    display: flex;
    align-items: center;
    height: $hdr-height;
    line-height: 1.33;

    @media only screen and (max-width: $breakpoint-3) {
        display: block;
        height: auto;
    }

    >a {
        font-size: 14px;
        position: relative;
        padding: 0 15px;
        color: rgba($white, 0.6);
        margin: 0 5px;
        text-transform: uppercase;
        font-weight: 600;

        &:lang(mal) {
            font-size: 10px;
        }

        @media (max-width: $breakpoint-1) {
            margin: 0 10px;
        }

        @media (max-width: $breakpoint-2) {
            margin-left: 10px;
            margin-right: 10px;
        }

        @media only screen and (max-width: $breakpoint-3) {
            display: table;
            width: 100%;
            height: auto;
            margin: 10px 0 20px;
        }

        &:before {
            pointer-events: none;
            display: block;
            content: '';
            height: 3px;
            background-color: #EE3224;
            position: absolute;
            left: 0;
            right: 10px;
            bottom: -20px;
            width: 100%;
            border-radius: 2px;
            opacity: 0;
            transition: opacity .1s ease-out;
        }

        // &::after {
        //     left: calc(100% - 8px);
        //     right: 0;
        // }
        &:lang(ar) {

            &:before,
            &:after {
                left: 10px;
                right: 0;
            }

            &::after {
                left: 0;
                right: calc(100% - 8px);
            }
        }

        &:hover {

            &:before,
            &:after {
                opacity: 1;
            }
        }

        &.active {
            color: $white;

            &:before,
            &:after {
                opacity: 1;
            }
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.hdr-utility {
    float: right;
    display: flex;
    align-items: center;
    height: $hdr-height;

    li {
        margin-left: 16px;
    }

    .league-select-dropdown {
        position: inherit;
        float: right;
        font-size: 14px;
        line-height: 1.3;
    }

    .league-select-toggle {
        color: $white;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        height: stretch;

        .slt-title {
            font-size: 14px;
            list-style: 1.2;
            color: $secondary-bg-color;
            text-align: right;

            &:lang(ar) {
                text-align: left;
            }
        }

        .slt-value {
            font-size: 14px;
            color: $secondary-fg-color;
            display: flex;
            align-items: center;
            font-weight: 600;

            &:lang(mal) {
                font-size: 10px;
            }

            span {
                color: $secondary-bg-color;
            }
        }
    }

    .league-select-content {
        position: fixed !important;
        width: 100%;
        min-width: 100%;
        border-radius: 0;
        top: 0 !important;
        bottom: 0 !important;
        transform: inherit !important;
        padding: 40px 0;
        overflow-y: auto;
        background-color: transparent;

        .icon-close {
            color: $dark;
            padding: 5px;
            font-size: 20px;
            position: absolute;
            top: 5px;
            right: 7px;
            cursor: pointer;
        }
    }

    .border-btn {
        border-radius: 6px;
        display: inline-block;
        font-size: 12px;
        line-height: 1.2;
        // font-weight: $font-weight-semibold;
        color: $btn-isl !important;
        border: solid 1px $btn-isl;
        width: auto;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
        padding: 10px 34px 11px;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            padding: 8px;
            width: 90px;

            // display: none; // XXX
        }
        &:hover{
            border-color: transparent;
            background-color: $btn-isl-hover;
            color: #28293D !important;
        }

        &.link {
            // width: auto;
            // padding: 0;
            margin: 5px 22px 5px 10px;
            // border-color: transparent;

            @include media-breakpoint-down(md) {
                margin: 5px 22px;
            }
        }


    }
    .signUp-updated {
        background: #EE3224;
        color: $white !important;
        border: none !important;
        padding: 12px 34px !important;
        width: auto !important;
        text-transform: uppercase;
        font-size: 12px !important;
        height: 38px;
        line-height: 1 !important;
        font-weight: 600;
    
        &:lang(mal) {
            font-size: 8px !important;
        }
    }
}

.hdr-mobile-dimmed {
    display: none;

    @media only screen and (max-width: $breakpoint-3) {
        display: block;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2000;
        background: rgb($secondary-fg-color, 0.15);
        opacity: 0;
    }
}

.hdr-depth-container {
    @media only screen and (max-width: $breakpoint-3) {
        overflow-x: hidden;
        overflow-y: auto;
        // padding: 54px 24px 0;
        z-index: 2100;
        width: 360px;
        background-color: #EFF1F5;
        opacity: 0;
        position: relative;
        left: 90px;

        &:after {
            // content: '';
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            height: 54px;
            width: 360px;
            background: $white;
            transform: translateZ(0);
            z-index: 2100;
        }
    }
}

.drawer-header {
    display: none;
    z-index: 1;
    background: $mobile-drawer-bg;

    @media only screen and (max-width: $breakpoint-3) {
        display: block;
        opacity: 0;
        position: relative;
        left: 90px;

    }
}

.hdr-menu-wrap {
    @media only screen and (max-width: $breakpoint-3) {
        display: flex;
        overflow: hidden;
        position: fixed;
        left: 0;
        transform: translateX(360px);
        top: 0;
        bottom: 0;
        width: 360px;
        height: 100%;
        z-index: 99999;
        transition: transform .3s cubic-bezier(0.6, 0, 0.4, 1), -webkit-transform .3s cubic-bezier(0.6, 0, 0.4, 1);
    }

    &.open {
        transform: translateX(0);

        &~.hdr-mobile-dimmed {
            visibility: visible;
            opacity: .68;
            transition: opacity .3s cubic-bezier(0.6, 0, 0.4, 1);
        }

        .hdr-depth-container,
        .drawer-header {
            left: 0;
            transition: left .5s cubic-bezier(0.4, 0, 0.2, 1), opacity .5s cubic-bezier(0.4, 0, 0.2, 1) .2s;
            opacity: 1;
            background: #EFF1F5;
        }
    }
    
}




.league-switch-drawer {
    margin-top: 70px;

    &.league-select-dropdown {
        position: relative;
        // float: right;
        font-size: 14px;
        line-height: 1.3;
        padding: 18px 16px 18px 48px;
        border: solid rgba($gray-999, .23);
        border-width: 1px 0;
        z-index: 0;
    }

    .icon-league {
        color: #333333;
        font-size: 18px;
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -9px;
    }

    .league-select-toggle {
        color: $white;
        cursor: pointer;
        margin: 5px 0;

        .slt-title {
            font-size: 14px;
            color: $secondary-bg-color;
        }

        .slt-value {
            font-size: 12px;
            color: #333333;

            span {
                color: $secondary-bg-color;
            }
        }
    }

    .league-select-content {
        position: fixed !important;
        width: 100%;
        min-width: 100%;
        border-radius: 0;
        top: 0 !important;
        bottom: 0 !important;
        transform: inherit !important;
        padding: 40px 0;
        overflow-y: auto;
        background-color: transparent;

        .icon-close {
            color: $dark;
            padding: 5px;
            font-size: 20px;
            position: absolute;
            top: 5px;
            right: 7px;
            cursor: pointer;
        }
    }
}

.profile-dropdown-content {
    min-width: 200px;
    padding-top: 0;

    .profile-info-top {
        min-height: 60px;
        border-radius: 4px 4px 0 0;
        background-color: $secondary-bg-color;
        padding: 10px;
        display: flex;
        align-items: center;

        .avtar {
            float: left;
            border-radius: 100%;
            overflow: hidden;
            background-color: $gray-col;

            img {
                width: 40px;
                height: 40px;
                object-fit: contain;
            }
        }

        .details {
            overflow: hidden;
            line-height: 1.2;
            padding-left: 10px;
            color: $primary-fg-color;

            &:lang(ar) {
                padding-left: unset;
                padding-right: 10px;
                text-align: right;
            }

            .user-name {
                font-weight: $font-weight-bold;
                font-size: $font-size-s;
                max-width: 138px;
                @include text-truncate;
            }

            .user-rank {
                font-size: $font-size-xs;
            }
        }
    }

    .dropdown-item {
        &:lang(ar) {
            text-align: right;
        }
    }

    .show {
        display: block;
        right: 0px;
        left: unset !important;
        transform: translate3d(0, 34px, 0px) !important;
    }
}

// Overriding the default styles

.login-updated {
    text-transform: uppercase;
    color: $white !important;

    &:lang(mal) {
        font-size: 10px !important;
    }
}



.select-league-dd>div {
    background: rgba($white, 0.1) !important;
    border: none !important;
    min-width: 212px;
    border-radius: 6px !important;
}

.select-league-dd>div>div>span {
    background-color: transparent;
}

.dropdown-nw {
    padding: 7px 10px;
    height: 38px;
    width: 220px;
    border-radius: 6px;
    background-color: rgba($white, 0.1);
}

.icon-league-bn {
    font-size: 20px;
    margin-right: 6px;
}

.fz-fix {
    font-size: 11px;
}

.hide-on-bigscr {
    display: none;

    @include media-breakpoint-down(md) {
        display: block;
        z-index: 10000;
    }
}

.dropdown-menu {
    .show {
        display: block;
        right: 0px;
        left: unset !important;
        transform: translate3d(0, 34px, 0px) !important;
    }
}




.login-profile-dropdown {
    .profile-dropdown-content {
        transform: translate3d(-166px, 34px, 0px) !important;

        &:lang(ar) {
            transform: translate3d(0px, 34px, 0px) !important;
        }
    }
}