$font-family-sans-serif: "Source Sans Pro",
"Segoe UI",
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;
$font-family-arabic: 'Droid Arabic Kufi',
"Segoe UI",
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;
$font-family-teko: 'Teko',
"Source Sans Pro";
$font-family-exo2:'Exo 2';
$font-family-Inter:'Inter 18pt';
// $font-fa
// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-666: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #333333 !default;
$black: #000 !default;
$gray-97: #979797 !default;
$gray-999: #999999 !default;
$gray-333: #333333 !default;

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #E42020 !default;
$orange: #fd7e14 !default;
$yellow: #f9c325 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// scss-docs-start theme-color-variables
$primary: #13296F !default;
$secondary: #13296F !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

// Branding
$body-font-size: 14px;
$body-bg: #000;
// $body-bg: $white;  --->
$body-color: $white;
$dark-color: $dark;
$bg-main-screens: $body-bg !default;
$scrollbar-track-color: transparent !default;
$scrollbar-thumb-color: $gray-700 !default;
$primary-bg-color: #27348B;
// $primary-bg-color: $white;   --->
$secondary-bg-color: #27348B;
// $secondary-bg-color: #4F75B6;  --->
$primary-fg-color: #FFFFFF;
// $primary-fg-color: $white;  --->
$secondary-fg-color: $white;
// $secondary-fg-color: rgb(0, 0, 0);  --->
$gradient-right: #EE3224;
$gradient-left: #EE3224;
// $gradient-left: #4d76b6;   --->
// $gradient-right: #4d76b6;   --->
$gray-box-shadow: inset 0 0 1px rgba(37, 37, 37, 0.3);
$primary-border: 0 solid rgba(255, 255, 255, 0.1);
$secondary-border:0 solid rgba(0, 0, 0, 0.1);
$box-color: rgba(#cacaca, 0.2);
$slick-arrow-color: $secondary-bg-color;
$btn-bg-color: $secondary-bg-color;
$modal-color: $secondary-fg-color;


// --------- FOR FOOTER BACKGROUND COLOR ---------- Uncomment According to the need

// $footer-bg: $gray-300;  // --- LIGHT THEME
$footer-bg: #0F0F23; // --- DARK THEME
// $footer-bottom: $secondary-bg-color  // --- LIGHT THEME
$footer-bottom: #242436; // --- DARK THEME



// --------- FOR BOOSTER BACKGROUND COLOR ---------- Uncomment According to the need

// $stats-box-bg: rgba(#cacaca,0.2); // for light
$stats-box-bg: radial-gradient(circle, #606060 0, #272727 100%); //for dark



// --------- FOR FILTER BUTTON AVAILABLE ON MY-TEAM PAGE ---------- Uncomment According to the need

$filter-btn-border: 1px solid rgb($secondary-bg-color, 0.8); // --- DARK THEME
// $filter-btn-border: 1px solid rgb(0,0,0,0.3); // --- LIGHT THEME



// --------- LEAGUE CHANGE MODAL ---------------- Uncomment According to the need

$modal-bg-color:#26252c; // --- DARK THEME
// $modal-bg-color: $white; // --- LIGHT THEME









// --------- MOBILE DRAWER MENU ---------------- Uncomment According to the need

// $mobile-drawer-bg: $body-bg; // LIGHT THEME/ CUSTOMIZE THEME
$mobile-drawer-bg: rgba(40, 40, 40); // DARK THEME

// Fonst Size
$font-size-xs3: 8px;
$font-size-xs2: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-l: 20px;
$font-size-xl: 24px;
$font-size-xl2: 32px;
$font-size-xl3: 40px;
$font-size-xl4: 48px;
$font-size-xl5: 60px;
$font-size-xl6: 72px;
$font-size-xl7: 96px;


$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-medium: 500 !default;
$font-weight-black: 900 !default;

$border-color-form: $primary !default;
$disabled-color-100: #CACACA;
$disabled-color-200: #CDCDCD;

$disabled-btn-bg: #ebebeb !default;
$disabled-btn-color: #616568 !default;

$gray-col: #26252C !default;
$gray-col-light: lighten(desaturate(adjust-hue($gray-col, 1), 3.38), 6.47) !default;



// Modals
$modal-backdrop-bg: #0A0111 !default;
$modal-backdrop-opacity: .65 !default;
$modal-sm: 376px !default;



// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$fontsize-lg : 16px !default;
$fontsize-base : 14px !default;

$input-btn-font-size: $fontsize-base !default;
$input-btn-padding-y: 9px !default;
$input-btn-padding-x: 1rem !default;
$input-btn-focus-width: 0rem !default;

$input-btn-padding-y-sm: .25rem !default;
$input-btn-padding-x-sm: .5rem !default;

$input-btn-padding-y-lg: .844rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $fontsize-base !default;

// $input-border-color:          lighten($body-bg, 10%)  !default;


$input-btn-border-width: 1px !default;
$btn-padding-y: .643rem !default;
$btn-padding-x: 1rem !default;

$btn-padding-y-lg: .822rem !default;
$btn-padding-x-lg: 1.5rem !default;

$btn-font-size-lg: $fontsize-lg !default;
$btn-border-width: 0px !default;

$border-radius: 4px !default;
$border-radius-lg: 4px !default;
$border-radius-sm: 4px !default;

$input-placeholder-color: #66666650 !default;
$border-color-form: $primary !default;
$bg-secondary: $primary !default;

$form-feedback-icon-valid: '' !default;
$form-feedback-icon-invalid: '' !default;
$custom-all-transition: all .15s ease-in-out;

$box-shadow-1: 0 12px 28px 0 rgba(0, 0, 0, 0.2),
0 2px 4px 0 rgba(0, 0, 0, 0.1),
inset 0 0 0 1px rgba(255, 255, 255, 0.05) !default;

//ISL Custom
$btn-isl:  #ED3123 !default;
$btn-isl-hover:  #E2BE5F !default;
$yellow-light: #ffc600 !default;
