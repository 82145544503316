$vertical-gap: 30px;

.fixtures-list-wrap {
    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

.fixtures-list {
    @include clearfix;
    background-color: #F2F2F2;
    border-radius: 0 0 10px 10px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    .fixture-header {
//        margin-top: 25px;
        // margin-bottom: 7px;

        &:first-child {
            margin-top: 0;
        }
    }
}

.fixture-header {
    color: $primary;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 14px;
    text-align: center;
    text-align: center;
    // background-color: $gray-300; ----> for light bg
    background-color: #E7E7E7; // for dark bg
    height: 36px;
    padding: 6px 0;
    position: relative;
  //  border-top: 1px solid rgba(#979797, .15);
    width: 100%;
    margin: 0 auto;

    // &::before,
    // &::after {
    //     position: absolute;
    //     content: '';
    //     box-sizing: border-box;
    //     height: 1.5px;
    //     width: 271.5px;
    //     border: 0.5px solid #979797;
    //     opacity: 0.6;
    //     left: 23%;
    //     bottom: 18px;
    // }

    // &::after {
    //     left: 54%;
    // }
    span {
        position: relative;
        display: inline-block;
       // top: -18px;
      // background-color: #FFFFFF;
        padding: 0 10px;

        @include media-breakpoint-down(md) {
            top: 0;
        }
    }


    @include media-breakpoint-down(md) {
        height: 28px;
        padding: 5px 0;
        font-size: 12px;
        width: 100% !important;
        margin: 0 auto;
    }

    &+.fixture-item {
        border-top: none;
        color: $secondary-fg-color;
    }
}

.fixture-item {
    display: flex;
    padding: 12px 0;
    cursor: pointer;
    border-top: solid 1px #E5E5E5;

    >* {
        flex: 1 1;
    }

    .fx-score-wrap {
        text-align: right;

        small {
            font-weight: $font-weight-normal;
            font-size: 11px;
        }
    }

    .cell {
        display: flex;
        align-items: center;
        color: $secondary-fg-color;

        &.mid {
            max-width: 82px;
            min-width: 82px;
            margin: 0 100px;
            background-color: $white;
            border-radius: 6px;
            max-height: 28px;
            @include media-breakpoint-down(md) {
                margin: 0;
            }
        }

        &:first-child {
            justify-content: flex-end;
        }

        &.last {
            .fx-name {
                text-align: left;
            }

            .fx-score-wrap {
                text-align: left;
            }
        }
    }

    .fx-flag {
        margin: 0 12px;
        width: 36px;
        height: 36px;
        // border-radius: 100%;
        // border: solid 1px $gray-col;
        padding: 2px;

        img {
            width: 36px;
            height: 36px;
            object-fit: contain;

            // border-radius: 100%;
            &.errored {
                object-fit: cover;
            }
        }

        @include media-breakpoint-down(md) {
            width: 25px;
            height: 25px;

            img {
                width: 25px;
                height: 25px;
            }
        }
    }

    .fx-name {
        font-weight: $font-weight-semibold;
        // line-height: 1.3;
        text-align: right;
        min-width: 110px;
        color: #333333;
        font-size: 14px;
        letter-spacing: -0.12px;
        line-height: 17px;
        text-align: right;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            width: 75px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            min-width: 0px;
        }

        &.winner {
            color: $primary;
        }
        
        .mob-l-name{
            display: none;
        }
        @media (max-width:767px) {
            .web-l-name{
                display: none;
            }
            .mob-l-name{
                display: block;
            }
        }
    }

    .score-capsule {
        text-align: center;
        line-height: 17px;
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;
        //height: 20px;
        width: 100%;
        // border: solid 1px #555;
        //border-radius: 10px;
        background-color: $white;
        color: #333333;
        font-size: 20px;
        letter-spacing: -0.17px;
        line-height: 10px;
        text-align: center;
        // position: relative;

        // &:before,
        // &:after {
        //     content: '';
        //     width: 12px;
        //     height: 1px;
        //     background-color: #555;
        //     position: absolute;
        //     top: 50%;
        // }

        // &:before {
        //     left: -12px;
        // }

        // &:after {
        //     right: -12px;
        // }
        @include media-breakpoint-down(md) {
            height: auto;
            font-size: 12px;
        }
    }
    &.active{
        background-color: $white;
    }
}

.fixture-details {
    @include clearfix;

    >.title {
        // height: 34px;
        // font-size: 16px;
        // line-height: 1.3;
        // font-weight: $font-weight-medium;
        // text-align: center;

        // color: $secondary-fg-color;
        // // background-color: $secondary-bg-color;
        // background-color: #1f1f1f;
        // padding: 7px 0;
        // margin-top: 15px;
        color: $primary;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: -0.1px;
        line-height: 14px;
        text-align: center;
        padding: 6px 0;

        @include media-breakpoint-down(md) {
            // height: 28px;
            font-size: 14px;
            line-height: 1.2;
            padding: 6px 0;
        }
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        background-color: $stats-box-bg;

        .cell {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
            padding: 10px 18px;
            // color: rgba($secondary-fg-color, .5);
            border: dashed #979797;
            border-width: 0 0 0 0.031rem;
            color: #333333;
            font-size: 12px;
            letter-spacing: -0.1px;
            line-height: 14px;

            // text-align: right;

            @include media-breakpoint-down(md) {
                font-size: 12px;
            }

            &:first-child {
                border-width: 0 1px 0 0;
                text-align: right;
            }

            div {
                padding-bottom: 15px;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

}

.selected-week-fixture {

    border: 1px solid rgba(151, 151, 151, 0.4);
    border-radius: 4px;
    padding: 15px;
    margin-top: 40px;
    margin-bottom: 40px;

    .fixtures-list-wrap {
        margin-top: 0;
    }
}

.selected-week-fixture-header {
    padding: 15px 0;
    background-color: $primary;
    color: $white;
    font-size: 16px;
    text-align: center;
    line-height: 1.2;
    font-weight: $font-weight-medium;
}

:lang(ar) {
    .fixture-item {
        .cell {
            text-align: left;

            &>* {
                float: left;
            }

            &.mid {
                text-align: center;

                >* {
                    float: none;
                    text-align: center;
                }
            }

            &.last {
                text-align: right;

                &>* {
                    float: right;
                }
            }
        }
    }


    .fixture-details {
        @include clearfix;

        .content {
            .cell {
                border-width: 0 1px 0 0;
                text-align: right;

                &:first-child {
                    border-width: 0 0 0 1px;
                    text-align: left;
                }
            }
        }
    }
}


.fixture-widget {
    padding-right: 12px;
    padding-left: 12px;

    .gameweek-weekslider {
        margin-top: 0;
        margin-bottom: 0 !important;
        margin-right: -12px;
        margin-left: -12px;
    }

    .fixtures-list-wrap {
        max-height: 210px;
        padding: 0;
        overflow-y: auto;
        background-color: #282828;
    }

    .fixture-header {
        background-color: rgba($black, .15);
    }
}

// rgba(255, 255, 255, 0.1)
.pt28-p {
    padding: 25px 0;

    @include media-breakpoint-down(md) {
        padding: 20px;
        padding-top: 75px;

        .native-slider-wrap>div {
            margin-top: 5px !important;
        }
    }
}

.padding-0ds>* {
    padding: 0;
}

.native-slider-wrap {
    background-color: $primary;
    padding: 0 !important;
    border-radius: 8px 8px 0 0 !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.weeek-block {
    padding: 20px 0 25px 0;
    background-color: #F2F2F2;
    color: #333333;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: -0.13px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
}

.game-details {
    box-sizing: border-box;
    // height: 378px;
    width: 100%;
   // border: 1px solid #EFF1F5;
   // border-radius: 10px;
    background-color: #FFFFFF;
    //box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.06);
    margin: 0 auto;
    padding: 20px 0;

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.before-circle,
.before-circle-l {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        box-sizing: border-box;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        left: -22px;
        top: 5px;
        border: 1px solid #979797;
        background-color: $white;
    }
}

.before-circle-l {
    &::after {
        left: auto;
        right: -37px;
    }
}
.self-block {
    max-width: 768px;
    margin: 44px auto 0;
    border-radius: 15px;
    overflow: hidden;
    @media (max-width:767px) {
        margin: 15px auto 0;
    }
}
.ourt-white-block{
    background-color: $white;
    @media (max-width:767px) {
        background-color: transparent;
    }
}