.gameweek-weekslider {
    user-select: none;
    @include clearfix;
    text-align: center;
    margin: 20px 0 0 0;
    height: 50px;
    border-radius: 7px;
    // background: linear-gradient(to left, $gradient-left, $gradient-right);

    &+.pitch-header-bottom {
        margin-top: 0;
        padding-top: 15px;
        border-top: none;
    }

    .week-item {
        height: 50px;
        text-align: center;
        padding: 0 20px;
        margin: 0 6px;
        margin: 0 0;
        min-width: 150px;
        cursor: pointer;
        font-size: 16px;
        font-weight: $font-weight-semibold;
        // color: $white;
        color: $gray-333;
        background: none;
        border-width: 0 0 4px 0;
        border-bottom: solid transparent;

        .week-label {
            display: inline-block;
            position: relative;
            font-size: $font-size-s;
            line-height: 1.3;

            i {
                font-size: $font-size-xs2;
                position: absolute;
                top: 4px;
                right: -16px;
            }
        }

        .week-status {
            font-size: $font-size-xs2;
            line-height: 1.2;
        }

        &.active {
            border-color: $white;
            // height: 45px;
            font-weight: 700;
            // border-bottom: 6px solid white;
        }

    }

    &.native-scroll {
        white-space: nowrap;
        overflow: hidden;
        padding: 0;
        position: relative;
        height: 50px;

        &::before,
        &::after {
            content: '';
            // background: linear-gradient(90deg, rgba(#000, .2) 10%, transparent 100%);
            background: $primary !important;
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            bottom: 0;
            width: 30px;
            height: 50px;
            user-select: none;
            pointer-events: none;
        }

        &::after {
            // background: linear-gradient(-90deg, rgba(#000, .2) 10%, transparent 100%);
            left: auto;
            right: 0;
        }

        .gameweek-inner {
            // width: 90% !important;
            margin: 0 auto;
            white-space: nowrap;
            overflow-x: auto;
            padding: 0;
            height: 70px;
            overflow-y: hidden;
            // background: $primary;
            background: linear-gradient(94deg, rgba(37,59,137,1) 31%, rgba(7,21,68,1) 100%);
            border-radius: 10px 10px 0 0;
        }

        .week-item {
            display: inline-flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            min-width: fit-content;
            margin: 0 35px;

            .week-label {
                opacity: 0.8;
                color: $white;
                font-size: 12px;
                letter-spacing: -0.1px;
                line-height: 14px;
                text-align: center;
                text-transform: uppercase;
                font-weight: normal;
            }

            .week-status {
                text-transform: uppercase;
                opacity: 0.4;
                color: $white;
                font-size: 14px;
                letter-spacing: -0.12px;
                line-height: 17px;
                text-align: center;
                font-weight: normal;
            }

            &.active {
                border-bottom: 4px solid #EB4A3C;

                .week-label {
                    opacity: 1;
                    font-weight: 600;
                }

                .week-status {
                    // color: blue;
                    font-weight: 600;
                    letter-spacing: -0.12px;
                    line-height: 17px;
                    text-align: center;
                    opacity: 1;
                }
            }
            @media (max-width:767px) {
                margin: 0 5px;
            }
        }

    }
}





.react-horizontal-scrolling-menu--inner-wrapper {
    position: relative;

    .react-horizontal-scrolling-menu--arrow-left,
    .react-horizontal-scrolling-menu--arrow-right {
        position: absolute;
        z-index: 1;

        button {
            background: none;
            border: none;
            height: 50px;
            width: 40px;
            color: $white;
            background: rgba(0, 0, 0, 0.1);
            // backdrop-filter: blur(2px);
            transition: background .1s ease-out;

            &:hover {
                background: rgba(0, 0, 0, 0.4);
            }

            &:disabled {
                opacity: 0;
                pointer-events: none;

                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .react-horizontal-scrolling-menu--arrow-left {
        left: 0;
        // button {
        //     background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 10%, transparent 100%);
        // }
    }

    .react-horizontal-scrolling-menu--arrow-right {
        right: 0;
        // button {
        //     background: linear-gradient(-90deg, rgba(0, 0, 0, 0.2) 10%, transparent 100%);
        // }
    }
}

.rts___tabs___container {
    position: relative;
}

.rts___nav___btn___container {
    position: absolute;
    top: 0;
    z-index: 1;

    &:first-child {
        left: 0;
    }

    &:last-child {
        right: 0;
    }

    &:lang(ar) {
        &:first-child {
            left: auto;
            right: 0;
        }

        &:last-child {
            right: auto;
            left: 0;
        }
    }

    button {
        background: $primary;
        border: none;
        height: 50px;
        width: 40px;
        color: $white;
        // background: rgba(0, 0, 0, 0.1);
        right: 0;
        transition: background .1s ease-out;

        &:hover {
            // background: rgba(0, 0, 0, 0.4);
        }

        &:disabled {
            opacity: 0;
            pointer-events: none;

            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.btn-hw-fx {
    padding: 0.75rem 1.5rem !important;
}

.fzfix-sm {
    font-size: 24px !important;
    // font-weight: bold !important;
}

.gameweek-leaderbd {
    background-color: $white;
    border-radius: 15px 15px 0 0;
    height: 62px;
    width: 100%;

    >div>div {
        margin: 0 auto;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0;
        height: 70px;
        overflow-y: hidden;
        background: $white !important;
        border-radius: 10px !important;

        .week-label {
            color: #333 !important;
        }
    }

    .native-scroll::before,
    .native-scroll::after {
        background: $white !important;
    }

    .rts___nav___btn___container {
        button {
            background: $white !important;
            color: #333 !important;
        }
    }

    // background: $white !important;
}