$lb-bg: $gray-col;

.lobby-container {
    max-width: 992px;
}

.lb-scoreboard {
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .lb-logo {
        height: 70px;
        object-fit: contain;
        margin: auto;
        display: block;
    }

    .lb-title {
        font-size: $font-size-l;
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
        margin: 10px 0;
    }

    .scoreboard-grid {
        width: 80%;
        display: flex;
        flex-direction: row;
        background-color: $lb-bg;
        margin: 20px 0 0;
        position: relative;
        border-radius: 4px 4px 0 0;

        .cell {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-left: solid 1px rgba($white, .1);
            border-bottom: solid 1px $body-bg;
            padding: 10px 0;
            line-height: 1.2;
            justify-content: center;

            &:first-child {
                border-left: none;
            }

            .value {
                font-size: $font-size-m;
                font-weight: $font-weight-semibold;

                &.xl {
                    font-size: $font-size-xl2;
                    line-height: 1.2;
                    position: relative;

                    i {
                        position: absolute;
                        font-size: 12px;
                        top: 0;
                        right: -16px;

                        &.icon-arrow-down {
                            color: $red;
                        }

                        &.icon-arrow-up {
                            color: $green;
                        }
                    }
                }
            }

            .lbl {
                font-size: $font-size-s;
            }
        }
    }

    .player-graphic {
        position: absolute;
        bottom: 0;
        bottom: -6px;
        height: 170px;

        img {
            object-fit: contain;
        }

        &.left {
            left: -50px;
        }

        &.right {
            right: -10px;
        }

        @media (max-width:767px) {
            height: 110px;
            bottom: 0;

            &.left {
                left: -72px;
            }

            &.right {
                right: -38px;
            }
        }
    }

    &+.alert {
        margin-top: 16px;
    }

    @media (max-width:1024px) {
        .scoreboard-grid {
            .cell {
                .lbl {
                    font-size: 12px;
                }
            }
        }
    }
}

.gray-box {
    background-color: $box-color;
    min-height: 200px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    @include media-breakpoint-down(md) {
        padding-bottom: 0px;
        background: white;
    }

    &.auto-height {
        min-height: 0;
    }

    &.min-height {
        min-height: 100px;
    }

    &.m-t {
        margin-top: 30px;

        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }

    &.m-b {
        margin-bottom: 24px;
    }

    &.col-height {
        height: 100%;

        &.m-t,
        &.m-b {
            height: calc(100% - 24px);
        }

        &.m-t.m-b {
            height: calc(100% - 24px - 24px);
        }
    }

    &.no-bg {
        background: none;
    }

    .blank-msz {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 114px;

        span {
            background-color: rgba($black, .35);
            padding: 10px 20px;
            border-radius: 20px;
        }
    }

    &.loader {
        background-image: url('../img/spiner.svg');
        background-repeat: no-repeat;
        background-size: 32px;
        background-position: center;
    }

}

.welcome-box {
    position: relative;
    background-color: $lb-bg;
    border-radius: 7px;
    min-height: 100px;
    max-width: 80%;
    margin: 24px auto 0;
    display: flex;
    align-items: center;
    position: relative;

    @include media-breakpoint-down(md) {
        max-width: 100%;
        flex-direction: column;
    }

    .cell {
        flex: 1 1;
        align-items: center;
        justify-content: center;

        // @include media-breakpoint-down(md) {
        //     flex: auto;
        // }
        &.first {
            max-width: 110px;
            min-width: 110px;
            display: flex;

            @include media-breakpoint-down(md) {
                max-width: 100px;
                min-width: 100px;
                padding-top: 20px;
                padding-bottom: 10px;
            }
        }

        &.last {
            max-width: 200px;
            padding: 0 20px;
            white-space: nowrap;

            @include media-breakpoint-down(md) {
                padding: 10px 20px 20px;
            }
        }

        .title {
            font-size: $font-size-l;
            font-weight: $font-weight-semibold;

            @include media-breakpoint-down(md) {
                font-size: $font-size-l;
            }
        }
    }

    .lb-logo {
        height: 70px;
        object-fit: contain;
    }
}

.season-end-message {
    background-color: $red;
    color: $white;
    text-align: center;
    padding: 10px 16px;
}

.gray-box-head {
    min-height: 45px;
    width: 100%;
    // color: $primary-fg-color;
    font-size: $font-size-l;
    // font-style: italic;
    font-weight: $font-weight-semibold;
    // border-bottom: solid 1px rgba($gradient-right, 1);
    padding: 20px 26px 10px;
    border-radius: 7px 7px 0 0;
    background-color: $white;
    // background: linear-gradient(0deg, darken($lb-bg, 10%) 0%, $lb-bg 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background: linear-gradient(to left, $gradient-left, $gradient-right);
    color: $primary-fg-color;

    &.rounded {
        border-radius: 7px;
        cursor: pointer;
    }

    &.lg {
        font-size: $font-size-l;
    }

    a {
        float: right;
        font-size: $font-size-xs;
        font-weight: $font-weight-normal;
        text-decoration: underline;
        color: $primary-fg-color;
    }

    .icn {
        float: right;
    }
}

.gray-box-body {
    @include clearfix;
    padding: 20px;
    flex: 1 1;

    &.no-padding-h {
        padding-left: 0;
        padding-right: 0;
    }

    .slick-slide {
        min-height: 125px;
    }
}




.team-overview-grid {
    overflow: hidden;
    display: grid;
    grid-template-rows: repeat(2, 76px);
    grid-template-columns: repeat(3, 1fr);
    padding: 20px;
    cursor: default;

    @include media-breakpoint-down(md) {
        grid-template-rows: repeat(2, 90px);
        grid-template-columns: repeat(3, 1fr);
    }

    .cell {
        text-align: center;
        color: $secondary-fg-color;
        font: 20px $font-weight-semibold;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        // border: $secondary-border;
        // border-width: 0 0 1px 1px;
        &:nth-child(1) {
            border-left-width: 0;
        }

        &:nth-child(5n) {
            border-left-width: 0;
        }

        &:nth-child(4)~.cell {
            border-bottom-width: 0;
        }

        &:lang(ar) {
            border-width: 0 1px 1px 0;

            &:nth-child(1) {
                border-right-width: 0;
            }

            &:nth-child(5n) {
                border-right-width: 0;
            }
        }

        .value {
            font-size: 22px;
            line-height: 1;
        }

        .text_label {
            font-size: 12px;
            color: rgba($secondary-fg-color, .7);
        }

        @include media-breakpoint-down(md) {
            padding: 22px 0;
            border-width: 0 1px 1px 0;

            &:nth-child(2n) {
                border-right-width: 0;
            }

            &:nth-child(4)~.cell {
                border-bottom-width: 1px;
            }

            &:nth-child(6)~.cell {
                border-bottom-width: 0;
            }
        }

    }
}



.booster-overview-tbl {
    padding: 13px 0;
    cursor: default;
    display: flex;
    // justify-content: space-between;

    @include media-breakpoint-down(md) {
        // overflow: auto;
        flex-wrap: wrap;
        padding: 0;
    }

    .booster-row {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        // background: #000;
        // overflow: hidden;
        // border-top: 1px solid rgba(255, 255, 255, 0.1);
        // padding: 8px 20px;

        &:first-child {
            border-top: none;
        }

        .cell {
            float: left;
            // width: 50%;
            text-align: right;
            // color: $secondary-fg-color;
            // color: rgba(225, 225, 225, 0.6);
            color: #333333;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 19px;

            &:lang(mal) {
                font-size: 9px;
            }


            &:first-child {
                // color: $secondary-fg-color;
                text-align: left;
            }
        }
    }
}

.mini-league-slider {
    height: 260px;
}

.min-league-wraps {

    // margin: 24px 0 0;
    .slick-slider {
        margin: 0;
    }

    img {
        height: 260px;
        max-width: 100%;
    }

    .slick-slide {
        padding-right: 15px;
    }

    &.single-slide {
        .slick-track {
            width: 100% !important;
        }

        .slick-slide {
            padding-right: 0;
            width: 100% !important;
        }
    }
}

.league-wrap {
    margin: 30px 0;
    background: #26252C url('../img/bg-league.png') no-repeat center;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(38, 37, 44, 0.8);
        z-index: 0;
        display: block;
    }

    .league-body {
        padding: 30px 15px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        position: relative;

        h4 {
            font-size: 24px;
            color: $white;
            margin: 0;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
        }

        p {
            color: $gray-999;
            margin: 0;
        }
    }

    .league-footer {
        border-top: 2px solid #37363C;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        font-size: 20px;
        color: $white;
        text-align: center;
        margin-top: 3px;
        position: relative;
        padding: 15px 0;
        z-index: 1;
        font-weight: $font-weight-bold;

        &:after {
            content: '';
            display: block;
            width: 2px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            background-color: #37363C;
        }

    }
}

.mini-league-modal {

    @include media-breakpoint-up(sm) {
        width: 400px;
    }

    @include media-breakpoint-down(md) {
        width: 335px !important;

        .modal-content {
            width: 335px !important;
        }
    }

    .close-btn {
        height: 26px;
        width: 26px;
        padding: 8px 0px;
        top: -25px;
        font-size: 10px;
        right: -25px;
        text-align: center;
        background: $primary;
        border-radius: 50%;
        color: $white;
        font-weight: 400;
    }

    .modal-header {
        // background: linear-gradient(180deg, #FFE620 0%, #F9B604 100%);
        background: $primary;
        box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
        text-align: left;
        padding: 17px 18px;
        border-radius: 15px 15px 0 0;

        .modal-title {
            font-size: 14px;
            color: $white;
        }
    }

    .modal-body {
        background-color: $white;
        padding: 20px 30px 0;
        border-radius: 0 0 20px 20px;

        .mini-banner-image {
            margin-bottom: 30px;

            img {
                max-width: 100%;
            }
        }
    }

    .modal-content {
        background-color: transparent !important;
        border-radius: 15px 15px 20px 20px;
        // overflow: hidden;
    }
}

.mini-league-head {
    color: #333333;

    h4 {
        font-family: $font-family-Inter;
        font-size: 40px;
        line-height: 1.2;
        font-weight: $font-weight-semibold;
        margin: 0 0 12px;
        text-transform: uppercase;

        span {
            display: block;
            color: $yellow;
        }
    }

    p {
        color: #333333;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 37px;

        span {
            color: #B4B4B4;
        }
    }

    .league-name-fix {
        // color: #333333;
        font-size: 20px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 37px;
    }

    .league-disc {
        color: #333333;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 37px;
        margin-bottom: 0;

        span {
            color: #B4B4B4;
        }
    }
}

.prize-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 40px 0;
    width: 100%;
    // li{
    //     width: 74px;
    //     height: 74px;
    //     margin: 50px 0 0;
    //     // position: relative;
    //     img{
    //         // border-radius: 100%;
    //         // max-width: 100%;
    //     }
    //     span{
    //         background-color: $white;
    //         display: block;
    //         width: 24px;
    //         height: 24px;
    //         border-radius: 50%;
    //         border:1px solid $black;
    //         position: absolute;
    //         bottom: -10px;
    //         left: 50%;
    //         transform: translateX(-50%);
    //         text-align: center;
    //         font-family: $font-weight-black;

    //     }
    //     &:nth-child(2){
    //         order: 1;
    //     }
    //     &:nth-child(1){
    //         order: 2;
    //         width: 108px;
    //         height: 108px;
    //         margin: -52px 18px 0 18px;
    //     }
    //     &:nth-child(2){order: 3;}

    // }
    .img-hfix {
        height: 95px;
        // width: 102px;
    }
}

.btn-rounded {
    border-radius: 20px;
}

.mini-league-action {
    text-align: center;

    .btn {
        margin-bottom: 0px;
        min-width: 207px;
        border-radius: 10px !important;
    }
}

.btn-link {
    color: $primary !important;
    text-decoration: underline;
    display: block;
    text-align: center;
    margin-bottom: 20px;
}

.prizes-box {
    background-color: $white;
    padding: 30px;
    border-radius: 6px;
    margin: 30px 0;
    margin-top: 0;
    color: #333333;

    .btn {
        min-width: 188px;
    }

    .prizes-box-head {
        font-family: $font-family-Inter;
        color: $yellow;
        text-transform: uppercase;
        font-size: 24px;
        text-align: center;
        font-family: $font-weight-semibold;
        border-bottom: 1px solid $gray-97;
        line-height: 34px;
        margin: 0;
        padding-bottom: 20px;
    }

    .prize-detail-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        margin-left: 35px;
        // justify-content: center;
        max-height: 550px;
        overflow: auto;

        @media (max-width: 768px) {
            margin-left: 5px;
            margin-top: 20px;
        }

        .prize-detail-box {
            // padding: 30px;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            margin-right: 26px;
            margin-bottom: 25px;

            img {
                max-width: 100%;
                border-radius: 10px 10px 0 0;
                overflow: hidden;
                min-width: 200px;
            }

            span {
                display: block;
                text-align: center;

                &.ribbon-shape {
                    background: url('../img/ribbon-shape.png') no-repeat center;
                    color: $black;
                    font-size: 12px;
                    font-weight: $font-weight-semibold;
                    margin: 10px 0 5px 0;
                    background-size: contain;
                }
            }

        }

        @media (max-width:767px) {
            display: block;

            .prize-detail-box {
                float: left;
                margin: 10px;
                margin: 10px 5px;
                width: calc(50% - 10px);

                img {
                    min-width: 100%;
                }
            }
        }
    }

    @media (max-width:767px) {
        padding: 30px 15px;
        overflow: hidden;
    }
}

.prize-wrap {
    margin: 30px auto;
    padding: 0px !important;
}

.p-fix-prz {
    @include media-breakpoint-down(md) {
        padding: 20px !important;
    }
}

.myteaminfo-box {
    // background-color: $box-color;
    min-height: 336px;
    border-radius: 6px;

    &.height-auto {
        min-height: auto;
    }
}

.deadline-info-wrap {
    // padding: 50px 70px;
    padding: 20px 45px;
    // background: url(../img/texture-lg.png) no-repeat;
    // background-position: center -178px;
    // background-color: rgba(0, 0, 0, 0.15);
    // background: linear-gradient(to left, $gradient-left,$gradient-right); --->
    // background-size: cover;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 19px 20px;
        padding-left: 135px;

        &:lang(mal) {
            padding-left: 115px;
        }

        &.deadline-private {
            flex-wrap: nowrap;
        }

        align-items: flex-start;
    }

    .cell {
        flex: 1 1;

        &.last {
            max-width: 315px;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }

    }

    .title {
        color: $white;
        font-size: 30px;
        font-family: $font-family-Inter;
        font-weight: 800;
        letter-spacing: 1px;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 1.3;
        }

        @media screen and(max-width : 375px) {
            font-size: 17px !important;
        }

    }

    .title:lang(mal) {
        font-size: 16px;
    }

    .sub-title {
        font-size: 18px;
        margin: 0;
        color: $white;
        padding: 5px 0;

        @include media-breakpoint-down(md) {
            font-size: 12px !important;
            line-height: 15px;
            padding-bottom: 0;

        }

        @media screen and (max-width : 350px) {
            &:lang(mal) {
                font-size: 7px !important;
            }
        }
    }

    .trn-btn {
        border-radius: 10px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0 auto;
        display: table;
        color: white;
        text-transform: uppercase;
        background-color: $btn-isl;
        border: 1px solid $btn-isl;
        width: 234px;
        height: 40px;
        // padding-top: 9px;
        text-align: center;
        font-size: 19px;
        font-weight: 600;
        padding: 5px 0 0 0;
        // box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.21);

        @include media-breakpoint-down(md) {
            max-width: 185px;
            font-size: 14px;
            padding-top: 8px;
            margin-top: 10px;
            width: 100%;
            min-width: 185px;
        }

        &:hover {
            background-color: $btn-isl-hover;
            color: $black;
            border-color: $btn-isl-hover;
        }
    }

    position: relative;
    min-height: 190px;
    padding-left: 14.23%;

    &.loader:after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        background-image: url('../img/spiner.svg');
        background-repeat: no-repeat;
        background-size: 32px;
        background-position: center;
    }

}

.deadline-clock-wrap {
    // background-color: rgba($white, 0.1);
    // box-shadow: 0 0 15px rgb(0 0 0 / 15%);
    // width: 280px;
    // height: 68px;
    border-radius: 16px;
    // position: relative;
    // right: -20px;
    // width: 235px;
    // padding: 7px 34px;
    padding: 10px;

    @include media-breakpoint-down(md) {
        // width: 100%;
        margin-top: 20px;
        padding: 0;
    }

    .deadline-clock {
        font-family: 'Bebas';
        @include flexwrap;
        text-align: center;

        .icon-clock {
            font-size: 32px;
            color: #7F96A0;

            &+.time-cell {
                &::before {
                    content: "";
                }
            }
        }

        .time-cell {
            position: relative;
            color: $white;

            // &::before {
            //     position: absolute;
            //     left: -5px;
            //     content: ":";
            //     font-size: 50px;
            //     top: -20px;
            // }

            &:lang(ar) {
                &::before {
                    left: auto;
                    right: -2px;
                }
            }

            &:first-child,
            &.fsc {
                &::before {
                    content: "";
                }
            }

            .val {
                font-size: 48px;
                line-height: 1.1;
                font-weight: bold;
            }

            .lbl {
                font-size: 14px;
                line-height: 1.2;
                // color: #86A7B3;
                color: rgba($color: $white, $alpha: 0.8);
                font-family: 'Inter 18pt';
            }

            @include media-breakpoint-down(md) {
                .val {
                    font-size: 24px;
                }

                .lbl {
                    font-size: 10px;
                }
            }
        }
    }
}

.timer-flex-wrap {
    @include flexwrap;
}

.current-lineup-wrap {
    display: flex;
    flex-direction: column;
    // padding: 30px;
    // width: 50%;
    // background-color: white;
    // margin-top: 20px;
    color: #333333;

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    .clw-head {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // margin: 0 70px;
        // margin: 0 20px;
        padding: 15px 0 0;
        border-top: solid 1px rgba($white, .3);

        @include media-breakpoint-down(md) {
            // flex-direction: column;
            align-items: flex-start;
            padding: 10px 0 0;
        }

        .cell {
            @include media-breakpoint-down(md) {
                text-align: center;
            }

            &.info {
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: space-evenly;
                // height: 240px;
                // background: white;
                // border-radius: 10px;
                // margin-top: 12px;
                // @include media-breakpoint-down(md) {
                //     margin-top: 10px;
                // }
            }
        }

        .lbl {
            // font-size: 24px;
            // font-weight: $font-weight-bold;
            // color: $secondary-bg-color;
            // line-height: 1.2;
            text-transform: uppercase;
            color: #333333;
            font-size: 20px;
            font-style: italic;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 34px;

            @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .week-lbl {
            font-size: 20px;
            line-height: 1.2;
            font-weight: $font-weight-semibold;

            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }

        .info-lbl {
            font-size: 14px;
            font-weight: $font-weight-light;

            @include media-breakpoint-down(md) {
                font-size: 12px;
            }
        }

        .info-val {
            font-size: 24px;
            font-weight: $font-weight-semibold;

            .rank-arrow {
                position: relative;

                i {
                    position: absolute;
                    font-size: 12px;
                    top: 0;
                    right: -16px;

                    &.icon-arrow-down {
                        color: $red;
                    }

                    &.icon-arrow-up {
                        color: $green;
                    }
                }
            }
        }

        .info-box-sm {
            padding: 5px 15px;
            // background: #2f2f2f;
            // background: rgba(255, 255, 255, 0.1);
            background-color: #EFF1F5;
            margin-right: 10px;
            border-radius: 12px;

            &:last-child {
                margin-right: 0px !important;
            }
        }
    }

    .clw-content {
        padding: 30px 30px 0;
        min-height: 240px;

        &.loader {
            background: url(../img/spiner.svg) no-repeat center;
            background-size: 32px;
        }
    }
}

.join-league-banner {
    display: flex;
    flex-direction: column;
    // background-color: $gray-col;
    height: 340px;
    border-radius: 10px;
    // background: #282828;
    background-color: $white;
    overflow: hidden;

    // background: linear-gradient(to left, $gradient-left,$gradient-right);   ---->
    @include media-breakpoint-down(md) {
        height: 198px;
    }

    background-size: cover;
    cursor: pointer;

    &.m-t {
        margin-top: 24px;
    }

    .cell {
        padding: 0;
        color: $black;
        width: 50%;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &.last {
            padding-bottom: 0;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }

    .jlb-top {
        display: flex;
        align-items: center;
        flex: 1 1;
        padding: 0 38px 0 50px;

        @include media-breakpoint-down(md) {
            padding: 20px 25px 0 15px;

            &:lang(mal) {
                padding-top: 0;

                .jlb-title {
                    font-size: 14px !important;
                    width: 190%;
                }

                .jlb-subtitle {
                    font-size: 8px;
                    padding-top: 0;
                    width: 120%;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        &:lang(mal) {
            .jlb-footer {
                min-height: auto !important;

                .fcell {
                    font-size: 10px;
                    padding: 0;
                }
            }
        }
    }

    .jlb-title {
        font-family: $font-family-Inter;
        text-transform: uppercase;
        color: #333333;
        font-size: 30px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 34px;

        &:lang(mal) {
            font-size: 20px;
        }

        @include media-breakpoint-down(md) {
            padding-right: 0;
            line-height: 18px;
            font-size: 20px !important;
        }
    }

    .jlb-subtitle {
        color: #333333;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
        padding-top: 20px;

        @include media-breakpoint-down(md) {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .jlb-graphic {
        display: block;
        height: 248px;
        width: 218px;
        background: url(../img/join-league-gr.png) no-repeat center bottom;
        background-size: cover;

        @include media-breakpoint-down(md) {
            zoom: 50%;
        }
    }

    .jlb-footer {
        flex: 1 1;
        display: flex;
        max-height: 56px;
        min-height: 46px;
        font-size: 16px;
        align-items: center;
        font-family: $font-family-Inter;
        overflow: hidden;
        border-top: solid 2px #F0F0F0;

        @include media-breakpoint-down(md) {
            max-height: 46px;
        }

        .fcell {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            height: 100%;
            border-left: solid 2px #F0F0F0;
            // color: $primary;
            color: $btn-isl;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.13px;
            line-height: 19px;
            text-align: center;
            padding: 18px 0;
            text-transform: uppercase;

            &:hover,
            &:active,
            &:focus {
                background-color: $btn-isl-hover;
                color: #28293D !important;
            }

            @include media-breakpoint-down(md) {
                font-size: 14px;
            }

            &:lang(ar) {
                border-left: none;
                border-right: solid 2px #F0F0F0;

            }

            &:first-child {
                border-left: none;
                border-right: none;
            }
        }
    }
}

.display-flex {
    display: flex;
}

.dummy-league-box {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    background-color: $box-color;
    min-height: 244px;
    margin-top: 24px;
    border-radius: 6px;

    &.bg {
        // background-image: url(../img/texture-sm.png);
        background-repeat: no-repeat;
        background-position: center;
    }

    &.lineup-leaderboard {
        >.title {
            margin: 13px 0 0;

            span {
                color: $white;
            }
        }
    }

    .leaderboard-item .cell {
        font-size: 16px;
        font-weight: $font-weight-semibold;
    }

    .league-info-banner {
        display: flex;
        flex: 1 1;
        align-items: center;
        padding: 0 22px;
        padding-right: 50%;
        font-family: $font-family-Inter;
        font-size: 22px;
        line-height: 1.2;
        color: $secondary-fg-color;

        &.mini-league-bg {
            background-image: url(../img/mini-league-bg.png);
            background-repeat: no-repeat;
            background-position: center right;
            background-size: 55%;
        }

        &.private-league-bg {
            background-image: url(../img/private-league-bg.png);
            background-repeat: no-repeat;
            background-position: center right;
            background-size: 55%;
        }
    }
}

.banner-slider-home {
    .slick-track {
        display: flex;
        align-items: center;

        * {
            &:focus {
                outline: none;
            }
        }

        .slick-slide {
            display: flex;
            padding: 24px 0;

            @media (max-width: 576px) {
                padding: 10px 0;
            }

            >div {
                flex: 1 1;
            }
        }
    }

    .slick-dots {
        bottom: 40px;

        button:before {
            font-size: 12px !important;
            color: $white;
        }

        @media (max-width: 576px) {
            bottom: 20px;
        }
    }

    .youtube-vid {
        display: flex !important;
        height: 100%;
        text-align: center;
        background-color: $gray-col;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-color: red;
            pointer-events: none;
        }

        >iframe {
            display: block;
            margin: auto;
        }
    }
}

.youtube-video-modal {
    .videoWrapper {
        position: relative;
        padding-bottom: 56.25%;
    }

    .videoWrapper .video-modal-poster img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .videoWrapper .video-modal-poster:hover img {
        z-index: 0;
    }

    .videoWrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.btn-w-fx {
    padding: 6px 20px 0 !important;
}

.teal-color {
    color: #198754 !important;
}

.orange-color {
    color: #ffce46 !important;
}

.fdr-parent {
    display: flex;
    justify-content: space-between;

    .fdc {
        min-width: 45px;
        display: flex;
        // flex-direction: column;
        // width: 27%;
        align-items: center;
        // justify-content: space-evenly;
        // background: #E4E7EE;
        // padding-top: 10px;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid rgba(255, 255, 255, 0.25);

        img {
            width: 100%;
        }

        span {
            width: 100% !important;
            background: $white;
            text-align: center;
            color: #333333;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 20px;
        }

        .bold-fonts {
            font-weight: bold;
        }
    }

    .prize-name {
        // background: $white;
        text-align: center;
        margin-top: 5px;
        color: #333333;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: right;
    }

    .black-color-bold {
        font-weight: 600;
    }
}

.modal-body-clr {
    background-color: $white !important;
    padding: 25px !important;
    border-radius: 0px 0px 20px 20px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        // width: 335px !important;
        padding: 0 15px 15px !important;
    }
}

.w-prizes {
    // width: 84.28%;
}

.heading-game-prize {
    display: flex;
    justify-content: space-between;
    // width: 83.28%;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: uppercase;

    @media (max-width:768px) {
        font-size: 16px;
    }
}

.back-ic-w {
    cursor: pointer;
    width: 22px;
    margin-right: 10px;
}

.flex-for-prizes {
    display: flex;
    margin: 15px 0;

    .first-prx {
        box-sizing: border-box;
        // height: 442px;
        height: 100%;
        width: 361px;
        // border: 1px solid #4AA838;
        border-radius: 10px;
        // background-color: $white;
        overflow: hidden;

        img {
            width: 100%;
        }

        .green-tag {
            width: 100%;
            height: 32.27px;
            padding-top: 6px;
            border-radius: 0 0 10px 10px;
            background-color: #4AA838;
            color: $white;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 19px;
            text-align: center;
        }

        .item-name {
            margin-top: 15px;
            // color: #333333;
            font-size: 30px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 36px;
            text-align: center;
            text-transform: uppercase;
        }

        @media (max-width:768px) {
            width: 100%;
        }
    }

    @media (max-width:768px) {
        flex-direction: column;
    }
}

.white-box {
    box-sizing: border-box;
    height: 22.78px;
    width: 100%;
    border: 1px solid #E4E7EE;
    border-radius: 0 0 10px 10px;
    background-color: $white;
    color: $primary;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
    border-top: 0;
    padding-top: 4px;
}

.item-all {
    padding-top: 10px;
    text-transform: uppercase;
    // color: #333333;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17px;
    text-align: center;
}

.img-banner {
    width: 30%;
}

.banner-div {
    width: 84%;
    margin: 0 auto;

    img {
        width: 96% !important;
    }

    @media (max-width:768px) {
        width: 85%;
        margin: 0 auto;

        img {
            width: 100% !important;
        }
    }
}

.view-ul {
    text-transform: capitalize;
    // color: $primary;
    text-decoration: underline;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-align: right;

    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.prize-ds {
    display: flex;
    align-items: center;
}

.gray-box-head {
    text-transform: uppercase;
    font-weight: 600;

    @include media-breakpoint-down(md) {
        font-size: 16px;
        padding-left: 15px;
    }
}

.is-txt {
    @include media-breakpoint-down(md) {
        padding-right: 0 !important;

        .jlb-title {
            font-size: 16px;
        }
    }
}

// .fx-name{
//     @include media-breakpoint-down(md){
//     width: 60px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }}
.colon-l {
    margin-left: 7px;
}

.colon-r,
.colon-l {
    max-width: 10px !important;
    font-size: 48px;
    color: $white;
    font-weight: 700;
    margin-top: -25px;

    @include media-breakpoint-down(md) {
        font-size: 24px;
        margin-top: -10px;
    }
}

.lobby-main-wfix {
    max-width: 1180px;
    margin: 0 auto;
}

.bgc-none {
    background-color: transparent !important;
}

.p-fix-hd {
    @include media-breakpoint-down(md) {
        padding-top: 5px !important;
    }
}

.head-sso-f {
    font-size: 16px !important;
    text-transform: capitalize !important;
}

.gw-score-box,
.gw-score-boxs {
    cursor: pointer;
}