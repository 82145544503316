.select-league-wrap {
    .spinner-static {
        min-height: 200px;
    }

    margin-bottom: 30px;

    .select-league-logo {
        width: 180px;
        height: 50px;
        display: block;
        margin: 20px auto;
        object-fit: contain;
    }

    .modal-content {
        background: $gray-333;
    }
}

.league-header {
    text-align: center;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    color: #333333;
}

.lg-sport-name {
    padding: 10px 0 20px;
    font-weight: bold;
    font-size: $font-size-l;
    line-height: 21px;
    color: #333333;

    @include media-breakpoint-down(md) {
        font-size: $font-size-m;
    }
}

.league-card {
    user-select: none;
    background: $white url('../img/league-card-bg.png') no-repeat;
    min-height: 90px;
    background-size: cover;
    border-radius: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;

    .icon-sports {
        width: 48px;
        height: 48px;
        object-fit: cover;
        margin: 0 15px;
    }

    .leg-title {
        color: #333333;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: -0.17px;
        line-height: 21px;

        @include media-breakpoint-down(md) {
            font-size: 16px !important;
            font-weight: 600 !important;
        }
    }

    .leg-last {
        flex: 1 0 0;
        display: flex;
        justify-content: flex-end;
        padding: 0 15px;
    }

    .playbtn {
        background-color: $secondary-bg-color;
        width: fit-content;
        border-radius: 5px;
        padding: 8px 20px 8px 20px;
        cursor: pointer;
        white-space: nowrap;

        .lets-play-text {
            display: block;
            color: $white;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 15px;
            text-align: center;
            text-transform: uppercase;
        }

        &.disabled {
            background-color: $disabled-btn-bg;

            .lets-play-text {
                color: $white;
            }
        }
    }

    .status-indicator {
        position: absolute;
        top: 5px;
        right: 15px;
        font-size: 12px;
        font-family: $font-weight-semibold;
        color: $dark-color;
        text-transform: uppercase;

        &:lang(ar) {
            right: auto;
            left: 15px;
        }

        &.live {
            .livenow {
                @include livenow($red);
            }
        }

        &.upcoming {
            .livenow {
                @include livenow($primary);
            }
        }

        &.completed {
            color: $gray-999;

            .livenow {
                @include livenow($red);
            }
        }
    }
}


.league-select-content {
    .league-header {
        padding: 0;
        color: $primary;
    }

    .lg-sport-name {
        color: #333333;
    }

    .league-card {
        box-shadow: 0 0 0 1px rgba($dark-color, .2);
    }

    .select-league-wrap {
        margin-bottom: 0px;
    }

}