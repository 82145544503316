$bench-space-around-width: 500px;

.pitch-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    --delay: 0;

    animation: rotate 3s ease-in-out alternate infinite;
    animation-delay: var(--delay);

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    .circular {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 100%;
        background-color: rgba(#000, .4%);
        box-shadow: 0 0 20px 14px rgba(#000, .4%);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

$bgpath: '../img/';
$spid: var(--sportsid);
$bgurl: url("#{$bgpath}#{$spid}/field-bg.png");

.pitch-view {
    position: relative;
    height: 485px;
    width: 90%;
    margin: 0 auto;
    // background: url(../img/field-bg.png) no-repeat center top;
    // background-image: attr(data-background);
    // background: attr(data-background) no-repeat center;
    // background: var(--field-image) no-repeat center;
    // background: url("#{$assetPath}field-bg-5.png") no-repeat center;
    // background-image: url(../img/var(--field-bg));
    // @include background-field('../img/', 'field-bg-5.png');
    // @include background-field('../img/', var(--field-bg));


    // background-image : $bgurl;
    // @include background-field('/img', attr(data-spid));
    // background-repeat: no-repeat;
    // background-position: center;

    // background: var(--field-bg) no-repeat center;

    background: url("../img/field-bg.png") no-repeat center;
    border-radius: 5px;
    background-size: contain !important;

    &.sport_id_5 {
        background: url("../img/field-bg.png") no-repeat center;
    }

    &.sport_id_7 {
        background: url("../img/field-cricket.png") no-repeat center;
    }

    padding: 27px 128px 0;
    // padding: 27px 100px 0;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @include media-breakpoint-down(md) {
        padding: 27px 16px 0;
        // background-size: 540px 512px;
        height: 600px;
        width: 100%;
        // margin: 0 -13px;
        background: url("../img/res-bg-field.png") no-repeat center !important;
        background-size: cover !important;
    }

    @media (max-width:380px) {
        padding: 27px 5px 0;
    }


    .blank-msz {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 114px;

        span {
            background-color: rgba($black, .5);
            padding: 10px 20px;
            border-radius: 30px;
            text-align: center;
            color: $white;
        }
    }
}

.team-row {
    width: 100%;
    max-width: 510px;
    // max-width: 530px;
    margin-left: auto;
    margin-right: auto;
    // padding-bottom: 16px;

    --spance-size: 80px;
    --field-player-height: 110px;
    --spance-size-bench: 100px;
    --highlight-padding: 10px;
    // --highlight-padding-bench: 5px;
    --highlight-padding-bench-height: 110px;


    @include media-breakpoint-down(md) {
        --spance-size: 90px;
        --field-player-height: 110px;
        --spance-size-bench: 80px;
        --highlight-padding: 0;
        --highlight-padding-bench: 5px;
        --highlight-padding-bench-height: 77px;
        height: 140px;
    }

    height: var(--field-player-height);

    .space-around {
        justify-content: space-around;
        height: var(--spance-size);
        display: flex;
        align-items: center;
       // zoom: 95%;

        // padding: 8px 0;
        @include media-breakpoint-down(md) {
            zoom: 1;
        }
    }

    .field-player {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--spance-size);
        height: var(--field-player-height);
        // height: 120px;
        border-radius: 4px;
        margin: 0 4px;
        background-color: transparent;
        border: 1px solid transparent;
        zoom: 95%;
        padding: 6px 0;

        &.rule-violate{
            border: 2px solid #EB4A3C;
            border-radius: 8px;
        }

        &.captian-booster-aaply {
            border: 1px solid hsla(0, 0%, 100%, .3);
            background-color: hsla(0, 0%, 100%, .4);
            box-shadow: 0 0 8px 0 rgb(black, .5);
        }

        position: relative;
        cursor: pointer;

        // padding-top: 7px;
        @include media-breakpoint-down(md) {
            margin: 0 0;
            width:70px;
            zoom: 1;

        }
        @media (max-width:380px) {
            width:65px;
        }

        &.default {
            width: 110px;
            background-color: transparent;
            border-color: transparent;

            @include media-breakpoint-down(md) {
                width: 60px;
                margin: 0 4px;
            }
        }

        .img {
            width: 74px;
            height: 77px;
            margin: 0 auto;
            user-select: none;
            text-align: center;

            .pos-abbr-jersey {
                font-size: 12px;
                text-shadow: 1px 1px 0 rgba(#000, .4);
                font-weight: $font-weight-semibold;
                position: absolute;
                top: 20px;
                left: 0;
                right: 0;
                text-align: center;
            }

            img {
                width: 57px;
                height: 57px;
                // width: 90%;
                // height: 90%;
                object-fit: cover;
                object-position: center top;
            }

            // &.default {
            // img {
            // width: 100%;
            // height: 100%;
            // }   
            // }
            @include media-breakpoint-down(md) {
                width: 45px;
                height: 47px;

                img {
                    width: 45px;
                    height: 47px;
                }
            }
        }

        .pos-abbr {
            text-align: center;
            width: 100%;
            position: absolute;
            top: 18px;
            left: 0;
            font-weight: $font-weight-bold;
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.88);
            color: $white;
            color: #bdbcbc;

            // max-width: 74px;
            @include media-breakpoint-down(md) {
                font-size: 11px;
            }
        }


        .player-role,
        .player-remove,
        .player-add,
        .swap-icon,
        .icon-swap {
            position: absolute;
            top: 6px;
            height: 18px;
            width: 18px;
            border-radius: 3px;
            background-color: $white;
            border: 1px solid rgba($secondary-fg-color, 0.5);
            box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
            padding: 6px 0;
            text-align: center;
            cursor: pointer;
            color: $primary;

            @include media-breakpoint-down(md) {
                top: 0;
            }

        }

        .player-remove {
            background-color: #EB4A3C;
        }

        .player-role {
            border: none;
        }

        .booster-icn {
            position: absolute;
            top: -5px;
            left: -6px;
        }

        .player-add {
            right: var(--highlight-padding);
            font-size: 20px;
            // padding: 7px 0;
            line-height: 0;
        }

        .player-remove {
            right: var(--highlight-padding);
            font-size: 26px;
            line-height: 0;

            &+.player-role {
                top: 25px;
                right: 7px;

                @include media-breakpoint-down(md) {
                    top: 18px;
                }
            }
        }

        .icon-swap,
        .swap-icon {
            left: var(--highlight-padding);
            border-radius: 100%;
            background: none;
            box-shadow: none;
            width: 18px;
            height: 18px;
            // color: $white;
            // font-size: 10px;
            padding: 0;
        }

        .player-role {
            right: var(--highlight-padding);
            font-size: 21px;
            line-height: 0;
            padding: 0;
            height: 15px;
            width: 15px;
            object-fit: cover;
            object-position: center top;
            background-color: transparent;
        }

        &.highlight,
        &.highlight_out {
            background-color: rgba($black, 0.23);
            border-color: rgba(#D0D0D0, 0.42);
        }

        &.opacity {
            opacity: .7;

            // .player-item-caption, svg {
            // }
            .field-player {
                cursor: not-allowed;
            }

            .icon-swap,
            .swap-icon {
                cursor: pointer;
            }
        }

        &.highlight {

            .icon-swap,
            .swap-icon {
                pointer-events: none;
            }
        }

        &.highlight_out {
            background-color: rgba($black, 0.5);
            // border-color: rgba($black, 0);
            border: 1px solid #EB4A3C;
        }
    }

    .player-item-caption {

        min-height: 20px;
        border-radius: $border-radius;
        // background-color: rgba(#000, .4);
        text-align: center;
        position: absolute;
        top: 53px;
        // left: 20px !important;
        // left: var(--highlight-padding);
        // right: var(--highlight-padding);
        color: $white;
        font-weight: $font-weight-black;
        overflow: hidden;
        line-height: 1.3;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 0;
        height: 47px;
        width: 70px;
        border-radius: 3px;
        background-color: #444444;

        &.default {
            font-size: 11px;
            top: 54px;

            @include media-breakpoint-down(md) {
                top: 47px;
            }
        }

        .lg {
            // height: 22px;
            height: stretch;
            display: flex;
            justify-content: center;
            flex-direction: column;
            min-width: 0;
            color: $white;
            font-size: 12px;
            font-weight: 700;
           // letter-spacing: -0.08px;
            line-height: 12px;
            text-align: center;
            background-color: rgba(#000, .4);
           // @include text-truncate;
            padding: 0 7px;
           // overflow: hidden;
            >span {
              //  @include text-truncate;
              overflow: hidden;
                @media (max-width:767px) {
                    text-overflow: unset;
                }
            }
        }

        .small {
            border-top: 1px solid rgba($white, .2);
            color: $white;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: -0.06px;
            line-height: 8px;
            text-align: center;
            height: 22px;
            display: flex;
            justify-content: center;
            background-color: rgba(#000, .4);
            padding-top: 2px;
        }

        .order {
            font-size: 11px;
            line-height: 17px;
            color: $white;
            display: flex;
            justify-content: center;
            height: 16px;
            margin-top: 2px;
            &.red {
                background-color: rgba($red, 1);
                font-size: 9px;
            }
            &.gray {
                background-color: rgba($gray-999, 1);
            }
            &.yellow {
                background-color: rgba($yellow-light, 1);
                color: $black;
            }
        }

        &.bench-caption {
            background-color: transparent;
            left: 13px !important;
            background: rgba($black, 0.8);
            height: 42px;

            .lg {
                background-color: rgba(#000, .4);
                color: $body-color;
            }

            .small {
                // background-color: $secondary;
                border-radius: 0 0 $border-radius $border-radius;
                color: $white !important;
            }

            .order {
                margin-top: 4px;
                border-radius: 4px;

                // &.yellow {
                //     background-color: rgba(#000, .4);
                //     color: $secondary;
                // }
                &.red {
                    background-color: rgba($red, 1);
                }
                &.gray {
                    background-color: rgba($gray-999, 1);
                }
                &.yellow {
                    background-color: rgba($yellow-light, 1);
                }
            }

            @include media-breakpoint-down(md) {
                left: 4px !important;
            }
        }

        @include media-breakpoint-down(md) {
            top: 44px;
            width: 65px;
            .lg {
                font-size: 11px;
                padding: 3px 5px;
                height: 18px;
            }

            .small {
                font-size: 8px;
                height: 14px;
                padding: 2px 0;
            }
        }
    }

    .bench-space-around {
        .space-around {
            .pos-caption {
                left: 13px !important;
            }
        }
    }

    &.bench-space-around {
        user-select: none;
        max-width: 97%;
        margin: auto;
        padding: 0;
        height: auto;
        // border-radius: 4px;
        // background-color: #6ACC98;
        background-image: url(../img/bench-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        border: solid 1px transparent;
        transition: all .1s ease-out;
        margin-top: -14px;
        // margin-top: -2px;

        padding: 14px;

        .swap-icon {
            left: 0 !important;
        }

        .player-remove {
            right: 0 !important;
        }

        @include media-breakpoint-down(md) {
            padding: 0 10px;
            // margin-top: 25px;
            // zoom: 82%;
            max-width: 100% !important;
            // margin-top: 0;
            background-color: #6ACC98;
            background-image: none !important;
        }

        >div {
            background-image: url(../img/bench_img.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
            width: 55%;
            margin: 0 auto;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .space-around {
            // margin-top: 30px; 
            height: var(--highlight-padding-bench-height);

            @include media-breakpoint-down(md) {
                // margin-top: 50px; 
            }

            @include media-breakpoint-down(md) {
                // margin-top: 0; 
            }
        }

        >.space-around {
            height: auto;
            margin: 6px auto;

            @include media-breakpoint-down(md) {
                height: 125px;
            }
        }

        .field-player {
            margin: 0;
            width: var(--spance-size-bench);
            height: var(--highlight-padding-bench-height);
           // zoom: 75%;
        }

        .player-remove {
            // right: var(--highlight-padding-bench);

        }

        .icon-swap,
        .swap-icon {
            // left: var(--highlight-padding-bench);

        }

        .player-item-caption {
            left: var(--highlight-padding-bench);
            right: var(--highlight-padding-bench);
        }

        &.active {
            >.space-around {
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                background-color: rgba($white, .2);
                border-color: rgba($white, .14);

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: -1px;
                    left: -1px;

                    width: 46px;
                    height: 46px;
                    border-top: solid 23px rgba($white, 1);
                    border-left: solid 23px rgba($white, 1);
                    border-right: solid 23px transparent;
                    border-bottom: solid 23px transparent;
                    border-radius: 4px 0 0;
                    pointer-events: none;
                }

                &::after {
                    border: none;
                    background: url('../img/bench-booster.png') no-repeat 7px 5px;
                    background-size: 14px auto;
                }
            }

        }
    }
}

.bench-field-sap {
    text-align: center;
    position: relative;
    color: $white;
    font-weight: 700;
    z-index: 1;
    height: 30px;

    // &::before {
    //     width: 100%;
    //     left: 50%;
    //     max-width: 498px;
    //     transform: translateX(-50%);
    //     display: block;
    //     position: absolute;
    //     top: 13px;
    //     height: 1px;
    //     background-color: rgba($gray-97, 0.5);
    //     content: '';
    // }

    span {
        // position: absolute;
        // display: block;
        // top: 4px;
        // left: 9%;
        // width: 60px;
        // background-color: $body-bg;
        transform: translateX(-50%);
        opacity: 0.4;
        color: $white;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        text-transform: uppercase;
    }

    @include media-breakpoint-down(md) {
        // display: none;
        margin-bottom: 8px !important;
        display: none;

        // margin-top: -26px;
        &::before {
            width: 80%;
        }

        span {}
    }
}

.single-player-pitch-view {
    @extend .pitch-view;
    height: 400px;
    background: url(../img/dart-field.png) no-repeat top center;
    position: relative;
    padding: 77px 0 30px;
    // padding: 77px 120px 30px;
    display: flex;
    // justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    align-content: flex-end;

    @include media-breakpoint-up(xl) {
        padding: 77px 180px 20px;
    }

    @media (min-width: 1400px) {
        padding: 77px 180px 20px;
    }

    @media (max-width: 1399px) {
        padding: 77px 120px 20px;
    }

    @media (max-width: 1199px) {
        padding: 77px 60px 20px;
    }

    @include media-breakpoint-down(lg) {
        padding: 77px 100px 30px;
        height: 400px;
    }

    @include media-breakpoint-down(md) {
        padding: 77px 0 30px;
    }

    @include media-breakpoint-down(md) {
        padding: 77px 20px 30px;
        margin-left: -12px;
        margin-right: -12px;
    }

    // > .team-row-wrap {
    //     max-width: 500px;
    // }
    // > .row-wrap {
    //     width: 100%;
    //     max-width: 500px;
    // }

    .field-wall {
        background: url(../img/field-wall.png) no-repeat center;
        min-height: 77px;
        width: 100%;
        max-width: 380px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px 0;

        .league-title {
            flex: 1 1;
            text-align: center;
            color: $white;
            font-size: $font-size-s;
            font-weight: $font-weight-bold;
            align-items: center;
            display: flex;
        }

        .field-wall-bottom {
            display: flex;
            align-items: center;

            .wall-logo {
                padding: 0 15px;

                img {
                    max-height: 36px;
                }
            }

            .small-adv {
                img {
                    max-height: 26px;
                }
            }
        }
    }
}

.team-row-wrap {
    // @extend .team-row;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1 1 100%;

    &.space-between {
        justify-content: space-between;
    }

    --spance-size: 110px;
    --field-player-height: 100px;
    --spance-size-bench: 100px;
    --highlight-padding: 10px;
    --highlight-padding-bench: 5px;
    --highlight-padding-bench-height: 100px;


    @include media-breakpoint-down(md) {
        --spance-size: 70px;
        --field-player-height: 77px;
        --spance-size-bench: 80px;
        --highlight-padding: 0;
        --highlight-padding-bench: 5px;
        --highlight-padding-bench-height: 100px;
    }

    // height: var(--field-player-height);
    .space-around {
        justify-content: space-around;
        // height: var(--spance-size);
        display: flex;
        align-items: center;
        // padding: 8px 0;
    }

    .field-player {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--spance-size);
        height: var(--field-player-height);
        // height: 120px;
        border-radius: 4px;
        margin: 0 4px;

        background-color: transparent;
        border: 1px solid transparent;



        padding: 6px 0;

        &.captian-booster-aaply {
            border: 1px solid hsla(0, 0%, 100%, .3);
            background-color: hsla(0, 0%, 100%, .4);
            box-shadow: 0 0 8px 0 rgb(black, .5);
        }

        position: relative;
        cursor: pointer;

        // padding-top: 7px;
        @include media-breakpoint-down(md) {
            margin: 0 2px;
        }

        &.default {
            width: 110px;
            background-color: transparent;
            border-color: transparent;

            @include media-breakpoint-down(md) {
                width: 60px;
                margin: 0 4px;
            }
        }

        .img {
            width: 74px;
            height: 77px;
            margin: 0 auto;
            user-select: none;
            text-align: center;

            img {
                width: 57px;
                height: 57px;
                // width: 90%;
                // height: 90%;
                object-fit: cover;
                object-position: center top;
            }

            // &.default {
            // img {
            // width: 100%;
            // height: 100%;
            // }   
            // }
            @include media-breakpoint-down(md) {
                width: 45px;
                height: 47px;

                img {
                    width: 45px;
                    height: 47px;
                }
            }
        }

        .pos-abbr {
            text-align: center;
            width: 100%;
            position: absolute;
            top: 18px;
            left: 0;
            font-weight: $font-weight-bold;
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.88);
            color: $white;
            color: #bdbcbc;

            // max-width: 74px;
            @include media-breakpoint-down(md) {
                font-size: 11px;
            }
        }

        .player-role,
        .player-remove,
        .player-add,
        .swap-icon,
        .icon-swap {
            position: absolute;
            top: 0;
            height: 12px;
            width: 12px;
            border-radius: 3px;
            background-color: $white;
            box-shadow: 0 0 5px black;
            padding: 4px 0;
            text-align: center;
            cursor: pointer;
            color: $red;

            @include media-breakpoint-down(md) {
                top: 0;
            }

        }

        .booster-icn {
            position: absolute;
            top: -5px;
            left: -6px;
        }

        .player-add {
            right: var(--highlight-padding);
            font-size: 16px;
            // padding: 7px 0;
            line-height: 0;
        }

        .player-remove {
            right: var(--highlight-padding);
            font-size: 26px;
            line-height: 0;

            &+.player-role {
                top: 25px;

                @include media-breakpoint-down(md) {
                    top: 18px;
                }
            }
        }

        .icon-swap,
        .swap-icon {
            left: var(--highlight-padding);
            border-radius: 100%;
            background: none;
            box-shadow: none;
            width: 18px;
            height: 18px;
            // color: $white;
            // font-size: 10px;
            padding: 0;
        }

        .player-role {
            right: var(--highlight-padding);
            font-size: 21px;
            line-height: 0;
            padding: 0;
            height: 15px;
            width: 15px;
            object-fit: cover;
            object-position: center top;
            background-color: transparent;
        }

        &.highlight,
        &.highlight_out {
            background-color: rgba($black, 0.23);
            border-color: rgba(#D0D0D0, 0.42);
        }

        &.opacity {
            opacity: .7;

            // .player-item-caption, svg {
            // }
            .field-player {
                cursor: not-allowed;
            }

            .icon-swap,
            .swap-icon {
                cursor: pointer;
            }
        }

        &.highlight {

            .icon-swap,
            .swap-icon {
                pointer-events: none;
            }
        }

        &.highlight_out {
            background-color: rgba($black, 0.5);
            border-color: rgba($black, 0);
        }
    }

    .player-item-caption {

        min-height: 20px;
        border-radius: $border-radius;
        background-color: rgba(#000, .4);
        text-align: center;
        position: absolute;
        top: 53px;
        left: var(--highlight-padding);
        right: var(--highlight-padding);
        color: $white;
        font-weight: $font-weight-medium;
        overflow: hidden;
        line-height: 1.3;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 0;

        &.default {
            font-size: 11px;
            top: 54px;

            @include media-breakpoint-down(md) {
                top: 47px;
            }
        }

        .lg {
            font-size: $font-size-xs;
            font-weight: $font-weight-semibold;
            height: 22px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            min-width: 0;
            @include text-truncate;
        }

        .small {
            font-size: 11px;
            height: 16px;
            display: flex;
            justify-content: center;
            background-color: rgba(#000, .4);
        }

        .order {
            font-size: 11px;
            color: $white;
            display: flex;
            justify-content: center;
            height: 17px;

            &.red {
                background-color: rgba($red, 1);
            }
        }

        &.bench-caption {
            background-color: transparent;

            .lg {
                color: $body-color;
                background-color: rgba(#000, .4);
            }

            .small {
                background-color: $primary;
                border-radius: 0 0 $border-radius $border-radius;
            }

            .order {
                font-size: 11px;
                color: $white;
                padding-top: 4px;
            }
        }

        @include media-breakpoint-down(md) {
            top: 44px;

            .lg {
                font-size: 11px;
                padding: 3px 5px;
                height: 18px;
            }

            .small {
                font-size: 8px;
                height: 14px;
                padding: 3px 0;
            }
        }
    }

    &.bench-space-around {
        max-width: 500px;
        margin: auto;
        padding: 0;

        @include media-breakpoint-down(md) {
            padding: 0 10px 0;
            // padding: 34px 10px 0;
            // height: 110px;
            // background-color: #E8E8E8;
            // margin-bottom: 44px;
        }

        .space-around {
            // margin-top: 30px; 
            height: var(--highlight-padding-bench-height);

            @include media-breakpoint-down(md) {
                // margin-top: 50px; 
            }

            @include media-breakpoint-down(md) {
                // margin-top: 0; 
            }
        }

        >.space-around {
            height: auto;
            margin-top: 0;
        }

        .field-player {
            margin: 0;
            width: var(--spance-size-bench);
            height: var(--highlight-padding-bench-height);
        }

        .player-remove {
            right: var(--highlight-padding-bench);
            box-shadow: 0 0 2px $secondary-fg-color;

        }

        .icon-swap,
        .swap-icon {
            left: var(--highlight-padding-bench);

        }

        .player-item-caption {
            left: var(--highlight-padding-bench);
            right: var(--highlight-padding-bench);
        }
    }
}

.bench-view {
    background-color: red;
}

.team-cell-wrap {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: center;
}

.player-caption-filter {
    @include flexwrap;
    max-width: $bench-space-around-width;
    text-align: center;
    background-color: $gray-col;
    color: $white;
    margin: 15px auto 0;
    border-radius: 2px;
    overflow: hidden;

    .cell {
        padding: 10px;
        border-bottom: solid 1px transparent;
        cursor: pointer;

        &.active {
            border-bottom-color: $secondary;
            cursor: default;
        }
    }
}

.field-wall-banner {
    position: absolute;
    height: 40px;
    width: 100%;
    left: 0;
    top: -5px;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
        top: 10px;
    }

    .cell {
        flex: 1 1;
        max-width: 130px;
        border-radius: 6px;
    }
}

.player-remove {
    // background-color: #EB4A3C !important;
    color: $white !important;
    border: none !important;
}

.login-updated {
    @include media-breakpoint-down(md) {
        padding-bottom: 55px;

        .formation-trigger {
            bottom: 12px;
        }
    }
}

.home-view {
    .team-row {
        --field-player-height: 85px !important;

        >.space-around {
            zoom: 1;
        }
    }

    .bench-space-around {
        background-image: none !important;

        .field-player {
            zoom: 1;
        }

        @include media-breakpoint-down(md) {
            background-color: transparent !important;
        }
    }
}

.mt-pitchvw {
    margin-top: -15%;

    @include media-breakpoint-down(md) {
        margin-top: -3%;
    }
}

.fallback-pitch {
    background-image: url(../img/fallback_bg.png);
    height: 588px !important;
    width: 100% !important;
}
.is-res-cls{
    @media (max-width:767px) {
        height: 400px;
        overflow: auto;
    }
}
.loader-gif{
    display: flex;
    justify-content: center;
}