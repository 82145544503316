// #182054
.home-ground {
    // height: 100vh;
    width: 100%;
    background: #182054;
    // background-image: url(../img/home-ground@2x.png);

    // padding-top: 180px;


    .ground-bg-main {
        // height: 100%;
        width: 100%;
        padding: 30px 0px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(../img/home-bgm.png);
        background-size: contain;
        height: auto;
        background-repeat: no-repeat;
        background-position: center bottom;

        @include media-breakpoint-down(md) {
            padding-top: 35px;
            padding-bottom: 0;
            background-image: none;
        }

        .myteam-area-home {
            margin-right: 25px;
            width: 100%;
            // height: calc(100vh - 110px);

            .left-sect-header {
                width: 100%;
                // border-bottom: 1px solid rgba($white, 0.12);
                padding: 15px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;

                // height: ;
                // opacity: 0.12;
                // background-color: $white;
                @include media-breakpoint-down(md) {
                    padding: 15px;
                }
            }

            @include media-breakpoint-down(md) {
                margin-right: 0;
                // padding: 15px;
            }
        }

        .slmain_img {
            height: inherit;
        }
    }

    @include media-breakpoint-down(md) {
        background-image: none;
    }
}

.header-lst-txt {
    color: $white;
    font-size: 24px;
    font-style: italic;
    font-weight: 900;
    letter-spacing: -0.2px;
    line-height: 29px;

    &:lang(mal) {
        font-size: 18px;
    }

    .info-ic {
        margin-left: 5px;
        font-size: 12px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 14px;
    }
}

.prl-bg-clr {
    // background-color: #EFF1F5 !important;
    // background-image: url(../img/hexagon-pattern-bg.svg);
    // background-size: 100%;

    @include media-breakpoint-down(md) {
        padding-top: 45px;
    }
}

.pr-lobby-main {
    width: 70%;
    margin: 0 auto;

    @include media-breakpoint-down(xxl) {
        width: 84%;
    }
}

.background-change {
    // background-image: url(../img/scoreImage.png);
    background-image: url('../img/breadcrumb-bg-mob.png');
    background-size: cover;
    height: 190px;

    @include media-breakpoint-down(md) {
        background-image: url(../img/responsivex3.png);
        height: 286px;
    }

    .cell {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}

.icon-stopwatch {
    font-size: 54px;
    color: rgba($color: $white, $alpha: 0.8);
    margin-right: 10px;

    @include media-breakpoint-down(md) {
        font-size: 35px;
        margin-right: 5px;
    }
}

.overview-coll {
    height: 240px;
    background: white;
    border-radius: 10px;
    margin-top: 12px;
    padding: 20px 30px;

    @include media-breakpoint-down(md) {
        height: 100%;
        width: 100%;
        padding: 15px;
    }
}

.booster-im {
    width: auto;
    height: 32px;
}

.flx-booster {
    display: flex;

    @include media-breakpoint-down(md) {
        padding-top: 10px;
    }
}

.booster-name {
    color: $primary;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 19px;
}

.bg-blues {
    // bg

}

.black-gbs {
    opacity: 0.5;
}

.booster-labels {
    width: 100px;
    color: $gray-900;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
}

.mt20 {
    margin-top: 20px;
}

.title-ob {
    margin-bottom: 15px;
    color: $primary;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}

.title-for-dashboard {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    .title-name {
        color: #000000;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 20px;
        text-transform: uppercase;
        position: relative;

        @include media-breakpoint-down(md) {
            font-size: 18px;
        }

        &::after {
            position: absolute;
            content: '';
            height: 19px;
            width: 239px;
            opacity: 0.15;
            background: linear-gradient(270deg, #F2F2F2 0%, #5852C3 100%);
            left: -5px;
            bottom: -1px;

            @include media-breakpoint-down(md) {
                left: -11px;
            }
        }
    }

    .link-howtoplay {
        color: $primary;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
        text-align: right;
        text-decoration: underline;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            font-size: 12px;
        }
    }
}

.mr-10p {
    margin-right: 10px;
    // &:last-child {
    //     margin-right: 0;
    // }
}

.mx-h-fix {
    height: 240px;
    margin-top: 12px;
}

.label-h-fix {
    height: 34px;
}

.joined-league {
    margin-top: 30px;
    height: 340px;
    width: 100%;
    background: $white;
    padding: 22px 26px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;

    @include media-breakpoint-down(md) {
        margin-top: 20px;
        padding: 20px 15px;
        // justify-content: space-evenly;
        height: 300px;

        &:lang(mal) {
            height: auto;
        }
    }
}

.new-join-btm {
    border-bottom: 3px solid #EE3224;
    // border-radius: 2px;
    ;
}

.banner-wrap-join {
    height: 240px;
    margin-top: 12px;
    // background-color: $white;
    border-radius: 10px;

    .slick-slider {
        margin-right: -12px;
    }

    .slick-slide>div {
        margin-right: 10px;
    }

    @include media-breakpoint-down(md) {
        height: 130px;
        margin-top: 0;
    }
}

.prizes-joined-text {
    color: $gray-900;
    font-family: $font-family-Inter;
    font-size: 20px;
    font-style: italic;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 34px;
    text-transform: uppercase;
    width: 100%;

    @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
    }
}

.view-all-pr {
    // height: 40px;
    // width: 234px;
    border: 1px solid $btn-isl;
    border-radius: 10px;
    background-color: $white;
    color: $btn-isl;
    font-family: $font-family-Inter;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.15px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 45px;

    &:hover {
        background-color: $btn-isl-hover;
        color: $black;
        border-color: $btn-isl-hover;
    }

    @include media-breakpoint-down(md) {
        font-size: 12px;
        padding: 7px 35px;
        border-radius: 6px;
    }
}

.w-100pc {
    width: 100%;
}

.ovrview-wrap {
    display: flex;
    justify-content: space-between;

    .overview-card {
        height: 66px;
        width: 32%;
        border-radius: 10px;
        background-color: #EFF1F5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .overview-main {
            color: $gray-900;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: -0.2px;
            line-height: 29px;
            text-align: center;

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 19px;
            }
        }

        .sub-title-overview {
            color: #999999;
            font-size: 14px;
            letter-spacing: -0.12px;
            line-height: 17px;
            text-align: center;


            @include media-breakpoint-down(md) {
                font-size: 12px;
                letter-spacing: -0.1px;
                line-height: 14px;
                text-align: center;
                width: 65px;

                &:lang(mal) {
                    font-size: 8px;
                    width: auto;
                }
            }
        }
    }
}

.spcl-prizes {
    color: $primary;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 34px;
    text-align: center;
    text-decoration: underline;

    @include media-breakpoint-down(md) {
        font-size: 12px;
        font-style: normal !important;
        font-style: normal !important;
    }

    &:hover {
        color: $primary;
    }
}

.joined-container {
    width: 100%;

    .classic-joined {
        position: relative;
        text-transform: uppercase;
        width: 100%;
        color: $primary;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;


        &::after {
            position: absolute;
            content: "";
            height: 19px;
            width: 239px;
            opacity: 0.15;
            left: -25px;
            top: 1px;
            background: linear-gradient(270deg, #F2F2F2 0%, #5852C3 100%);

            @include media-breakpoint-down(md) {
                left: -15px;
            }
        }

        span {
            margin-left: 5px;
            color: #555555;
            font-size: 11px;
            letter-spacing: 0;
            line-height: 13px;
            text-transform: capitalize;

            @include media-breakpoint-down(md) {
                position: absolute;
                top: 4px;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: 14px;

            span {
                font-size: 10px;
            }
        }
    }
}

.title-joined {
    display: flex;
    padding-top: 7px;

    span {
        &:first-child {
            width: 85% !important;
            display: block;
        }

        color: #999999;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;

        @include media-breakpoint-down(md) {
            font-size: 10px;
            &:first-child {
                width: 71% !important;
                display: block;
            }   
        }
    }
}

.joined-global-wrap {
    color: $gray-900;

    .leaderboard-item {
        .title-block {
            width: 82% !important;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 20px;

            @include media-breakpoint-down(md) {
                font-size: 14px;
                width: 70% !important;
            }

            span {
                border-bottom: 1px dashed #979797;
                cursor: pointer;
            }
        }

        .rank-block {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            width: 60px;

            @include media-breakpoint-down(md) {
                font-size: 14px;
            }

            // text-align: right;

        }
    }
}

.app-banner {
    width: 100%;
    height: 146px;
    border-radius: 10px;
    background-color: $white;
    padding: 30px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -25px 0 30px;

    .text-section {
        display: flex;
        flex-direction: column;
        width: 47.641%;
        color: $gray-900;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;

        .title-main {
            color: $gray-900;
            font-size: 30px;
            font-style: italic;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 34px;
            text-transform: uppercase;
            margin-bottom: 13px;
        }
    }

    .image-section {
        img {
            width: 177px;
            cursor: pointer;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            }

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    @include media-breakpoint-down(md) {
        height: 176px;
        margin: 0 0 20px;
        padding: 15px;
        flex-direction: column;

        .text-section {
            width: 100%;

            .title-main {
                color: #000000;
                font-size: 14px;
                font-style: italic;
                font-weight: 900;
                letter-spacing: 0;
                line-height: 20px;
            }

            .sub-tl {
                color: $gray-900;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 18px;
            }
        }

        .image-section {
            display: flex;

            img {
                width: 140px;
            }
        }
    }
}

.mt-40 {
    margin-top: 40px;

    @include media-breakpoint-down(md) {
        margin-top: 20px;
    }
}


.banner-wrap-join {

    .slick-slider {
        .slick-dots {
            bottom: -10px;

            li {
                height: 6px;
                width: 38px;

                button {
                    height: 6px;
                    width: 38px;

                    &::before,
                    &::after {
                        content: "";
                        background: #CFCFCF;
                        height: 6px;
                        width: 38px;
                        border-radius: 6px;
                    }
                }
            }

            .slick-active {
                button {

                    &::before,
                    &::after {
                        background: #EE3224 !important;
                    }
                }
            }

        }
    }

}

.white-bg-home {
    background-color: $white;
    width: 100%;
    height: 100%;
    padding-top: 40px;
    padding-bottom: 64px;
}

.htp-wrap {
    padding: 45px 0 155px 0;

    @include media-breakpoint-down(md) {
        padding: 0 0 15px;
    }

    .htp-title {
        text-transform: uppercase;
        color: $gray-900;
        font-size: 40px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 34px;
        text-align: center;

        &:lang(mal) {
            font-size: 30px;
        }



        .tpcl,
        .game-win {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                height: 6px;
                width: 94px;
                border-radius: 3px;
                background-color: #EE3224;
                bottom: -11px;
                left: 33%;
            }
        }

        .game-win {
            &::after {
                left: 40%;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: 24px;

            .tpcl,
            .game-win {
                &::after {
                    width: 48px;
                    height: 3px;
                    // left: ;
                    bottom: -5px;
                }
            }


        }
    }
}

.all-stats-btn {
    color: $primary !important;
    font-size: 14px !important;
    letter-spacing: 0;
    line-height: 34px;
    text-align: right;
    text-transform: capitalize !important;
    text-decoration: none !important;

    @include media-breakpoint-down(md) {
        font-size: 12px;
    }
}

.nodata-view {
    justify-content: center;
    color: #999999;
    font-size: 16px;
    // font-weight: bold;
    letter-spacing: -0.2px;
    line-height: 29px;
    text-align: center;
}

.background-htp {
    background-image: url('../img/dashboard_example.png');
    height: 522px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin-top: 45px;
    position: relative;

    @include media-breakpoint-down(md) {
        margin-top: 25px;
    }

    .slider-block {
        bottom: -86px;
        right: 20px;
        position: absolute;
        height: 300px;
        width: 545px;
        opacity: 0.88;
        border-radius: 15px;
        background-color: #000000;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
        padding: 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .titles-sld {
            color: $white;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 34px;
            padding-bottom: 20px;
            text-transform: uppercase;
        }

        .disc-wrap {

            color: $white;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 24px;

            .discriptions-sld {
                padding-bottom: 20px;
            }

            .click-disc {
                padding-bottom: 40px;
            }
        }

        .bottom-navs {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .navigation-section {
                &:lang(ar) {
                    text-align: left;
                }

                .navlink-click {
                    color: $white;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 34px;
                    text-decoration: underline;
                    font-weight: 300;
                }
            }

            .arrow-section {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                color: $white;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 17px;
                text-align: center;
                font-weight: 300;

                .arrow-wrap {
                    width: 41px;
                    height: 41px;

                    img {
                        cursor: pointer;
                    }

                    &:first-child {
                        margin-right: 15px;
                    }

                    &:last-child {
                        margin-left: 15px;
                    }

                    .end {
                        filter: grayscale(1);
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            right: auto;
            bottom: 0px;
            width: 100%;
            height: auto;
            padding: 15px;
            border-radius: 8px;
            z-index: 100000;


            .titles-sld {
                font-size: 20px;
                font-weight: 600;
            }

            .disc-wrap {
                font-size: 14px;
            }

            .navlink-click {
                font-size: 12px !important;
            }

        }
    }
}

.available-league-banner {
    cursor: pointer;

    img {
        width: 100%;
    }
}


// pitch view updated for home

.pitch-header-updated {
    display: flex;
    color: $white;
    justify-content: space-between;
    margin-top: 15px;
}


.left-header-wrap {
    display: flex;

    .mr-10 {
        margin-right: 55px;
    }
}

.modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    // background-color: #191919;
}

.color-win {
    background: #EFF1F5;
}

.home-banner-wrapper {
    margin-top: 55px;
    margin-bottom: 47px;
}

.win-pb {
    padding: 35px 0 !important;

    @include media-breakpoint-down(md) {
        padding: 180px 0 35px !important;
    }

}

.prizes-btn {
    margin: 0 auto;
    width: fit-content;

    a {
        .view-all-btn {
            box-sizing: border-box;
            height: 40px;
            width: 234px;
            border: 1px solid $btn-isl;
            border-radius: 10px;
            background-color: $white;
            color: $btn-isl;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: -0.15px;
            line-height: 22px;
            text-align: center;
            padding: 8px;
            text-transform: uppercase;

            &:lang(mal) {
                font-size: 12px;
            }

            &:hover {
                background-color: $btn-isl-hover;
                border-color: $btn-isl-hover;
                color: $black;
            }
        }
    }
}

.game-features {
    padding-top: 60px !important;
    padding-bottom: 0px !important;

    .parent {
        padding-top: 65px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 36px;
        grid-row-gap: 30px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 30px !important;

        .parent {
            padding-top: 25px;
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 15px;
        }
    }
}

.main-grid-block {
    // width: 30%;

    .title-box {
        height: 100px;
        border-radius: 10px 10px 0 0;
        background-image: url('../img/breadcrumb-bg-mob.webp');
        padding: 18px 30px 14px;

        &:lang(mal) {
            height: 110px;
        }

        span {
            display: block;
            width: 216px;
            color: $white;
            font-family: $font-family-Inter;
            font-size: 26px;
            font-style: italic;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 30px;

            &:lang(mal) {
                font-size: 16px;
            }

            &:lang(ben) {
                width: 100%;
            }

        }

        &:nth-child(3) {
            width: 186px;
        }
    }

    .subtitle-box {
        box-sizing: border-box;
        min-height: 125px;
        border: 1px solid #EFF1F5;
        border-radius: 0 0 10px 10px;
        background-color: $white;
        box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.06);
        padding: 19px 30px 23px;
        color: $gray-900;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;
    }

    @include media-breakpoint-down(md) {
        .title-box {
            height: 40px;
            padding: 10px 17px 9px;


            span {
                width: fit-content;
                font-size: 16px;
                line-height: 18px;
            }
        }

        .subtitle-box {
            height: 75px;
            padding: 10px 17px 12px;
            font-size: 14px;
            line-height: 16px;
        }
    }
}


.fix-height-downloads {
    position: relative;
    height: 508px;
    display: flex;
    width: 90%;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 110px;

    &:lang(ar) {
        justify-content: flex-start;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
    }

    .left-img {
        height: 619px;
        width: 584.67px;
        position: absolute;
        top: -46px;
        left: 0;
    }

    .l-img2 {
        display: none;
    }

    .text-container-downloadables {
        width: 550px;

        h1 {
            color: $gray-900;
            text-transform: uppercase;
            padding-bottom: 30px;
            font-size: 40px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 34px;

            &:lang(mal) {
                font-size: 28px;
            }
        }

        p {
            color: $gray-900;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 30px;
            padding-bottom: 39px;

            &:lang(mal) {
                font-size: 16px;
            }
        }

        .image-section {
            img {
                width: 177px;
                cursor: pointer;

                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
                }

                &:first-child {
                    margin-right: 10px;
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        height: 258px;
        margin-bottom: 30px;

        .left-img {
            display: none;
        }

        .l-img2 {
            display: block;
            position: absolute;
            top: -30px;
            width: 248px;
        }

        .image-section {
            img {
                width: 121px !important;

                &:first-child {
                    margin-right: 0 !important;
                    margin-bottom: 10px;
                }
            }
        }

        .text-container-downloadables {
            padding-left: 150px;
            width: 100%;

            h1 {
                padding-bottom: 10px;
                line-height: 20px;
                font-size: 14px;
            }

            p {
                font-size: 12px;
                line-height: 16px;
                padding-bottom: 0;
            }
        }

    }
}

.pbfix {
    padding-top: 60px !important;
    padding-bottom: 135px !important;

    @include media-breakpoint-down(md) {
        padding-top: 25px !important;
        padding-bottom: 35px !important;
    }
}

.color-code {}

.global-prizes {
    flex-direction: column;
    align-items: unset;
    margin-top: 0;
    height: 375px;
    overflow: auto;
}

.th-prizes-joinpopup {
    display: flex;
    justify-content: space-between;
    color: #999999;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    position: relative;
    top: 0px;

    &:first-child {
        padding-left: 9px;
    }
}

.formation-data {
    span {
        padding-left: 12px;
        letter-spacing: 3px;
    }
}

.white-color {
    background: #E4E7EE;
    padding: 1px 0 40px 0;

    @include media-breakpoint-down(md) {
        width: 100%;
        overflow: hidden;
        padding-top: 20px;
    }
}

.Selected-gw {
    color: $gray-900;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.13px;
    line-height: 19px;
    text-align: center;
    padding: 10px;
}

.fixture-mt {
    //border-top: none !important;

    .cell {
        color: $gray-900;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.12px;
        line-height: 17px;
        text-align: right;

        .fx-name {
            color: $gray-900 !important;
        }
    }
}

.dl-clrs {
    // color: #cacaca !important;
    position: relative;
    background-color: #E7E7E7;
    color: #27348B !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    letter-spacing: -0.1px;
    line-height: 14px !important;
    text-align: center;
    padding: 6px 0;

    span {
        //background: #F2F2F2;
    }

    // &::before,
    // &::after {
    //     position: absolute;
    //     content: '';
    //     height: 1.5px;
    //     width: 191.5px;
    //     border: 0.5px solid #979797;
    //     opacity: 0.6;
    //     left: 17.7%;
    //     bottom: 18px;
    // }

    // &::after {
    //     right: 17.7%;
    //     left: auto;
    // }

}

.white-fixture-wrap {
    border-radius: 4px;
    padding: 20px 0;
    background-color: #F2F2F2;

    @include media-breakpoint-down(md) {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 20px;

        .row>* {
            padding: 0 !important;
        }
    }
}

.content-list {
    .fixture-details {
        .content {
            .cell {
                color: $gray-900;
                font-size: 14px;
                font-weight: bold;
                letter-spacing: -0.12px;
                line-height: 17px;
                // text-align: right;
            }
        }
    }
}

.top-stick {
    a {
        img {
            margin-top: 0;
        }
    }
}



.hfx {
    margin: 0;
}

.container {
    max-width: 1180px !important;
    margin: 30px auto 0 !important;
    padding: 0;


    @media(min-width: 1400px) {
        max-width: 1180px !important;
    }

    @include media-breakpoint-down(md) {
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        padding-top: 55px;
    }
}

.main-myteam {
    background-color: #182054;
}

.container-home-wrap {
    @include media-breakpoint-down(md) {
        margin: 0 !important;
        padding-top: 0 !important;
        padding-right: var(--bs-gutter-x, 0.9rem);
        padding-left: var(--bs-gutter-x, 0.9rem);
    }
}

.stt-btn {
    bottom: 15px;
    right: 15px;
    position: fixed;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $primary;
    cursor: pointer;
    // padding: 9px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;

    &:hover {
        background-color: $white;
        border: 2px solid $primary;
        color: $primary;
    }
}

@keyframes tipUp {
    0% {
        transform: translateY(50px) rotateZ(45deg);
    }

    100% {
        transform: translateY(-70px) rotateZ(45deg);
    }
}

@keyframes lineUp {
    0% {
        transform: translateY(50px);
    }

    100% {
        transform: translateY(-70px);
    }
}



.home-view {
    position: relative;
    // right: -157px;
    // bottom: -110px;
    // zoom: 80%;
    // zoom: 70%;
    // right: -230px;
    // bottom: -234px;
    zoom: 78%;
    right: -20%;
    bottom: -235px;
    //pointer-events: none;

    .bench-space-around {
        margin-top: 15px !important;
    }

    @include media-breakpoint-down(md) {
        right: -6px !important;
        bottom: 0 !important;
        zoom: normal !important;
        background-image: url(../img/home_ground.png);
        background-position: center;
        padding: 55px 0;
        background-size: cover;
        margin-top: 20px;
        margin-bottom: 10px;
        // left: 28%;
        margin-left: 0;
    }

    // @media (max-width: 1440px) {
    //     zoom: 80%;
    //     right: -185px;
    //     bottom: -125px;

    // }

    .team-row {
        @include media-breakpoint-down(md) {
            --spance-size: 70px;
            --field-player-height: 110px !important;
            --spance-size-bench: 80px;
            --highlight-padding: 0;
            --highlight-padding-bench: 5px;
            --highlight-padding-bench-height: 77px;
        }
    }
}

.home-save-btn {
    width: fit-content;
    margin: 0 auto;

    button {
        position: absolute;
        height: 44px;
        width: 190px;
        border-radius: 10px;
        // bottom: -135px;
        // margin-left: 43px;
        bottom: -210px;
        margin-left: 60px;


        @include media-breakpoint-down(md) {
            bottom: -138px;
            margin-left: 0;
            position: unset;
        }
    }

}

.pitch-with-submit {
    position: relative;
}



//prizes
.prize-main-wrapper {
    width: 100%;
    border-radius: 10px;
    background-color: $white;
    padding: 54px 100px 65px;
    color: $gray-900;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
        padding: 25px 15px 0;
    }
}

.bold-cls {
    display: block;
    width: 65%;
    margin: 0 auto;
    font-weight: 800;
    text-align: center;
    color: $gray-900;
    font-size: 20px;
    letter-spacing: -0.17px;
    line-height: 24px;
    text-align: center;
    border-top: 1.5px solid rgba($color: #999999, $alpha: 0.3);

    span {
        display: inline-block;
        position: relative;
        background-color: $white;
        top: -15px;
        padding: 0 10px;
        text-transform: uppercase;
    }

    @include media-breakpoint-down(md) {
        font-size: 16px;
        font-weight: 700;
        // max-width: 335px !important;
        width: auto;
    }
}

.prize-ct-wrap {
    margin: 0 auto;
    text-align: center;
    padding-top: 15px !important;

    @include media-breakpoint-down(md) {
        max-width: 335px !important;
    }
}

.top-pl-head {
    color: $gray-900;
    font-size: 20px;
    font-style: italic;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 34px;
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 20px;
    }
}

.gw-score-boxs {
    display: none;

    @include media-breakpoint-down(md) {
        position: relative;
        height: 108px;
        width: 100%;
        border-radius: 10px;
        background-color: $white;
        padding: 15px 20px;
        color: $gray-900;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // margin: 0 auto 15px;


        .green-dot {
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: #0BBFB6;
            display: block;
            margin-right: 7px;
        }

        .gw-txt {
            color: $gray-900;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;
        }

        .eq-img {
            position: absolute;
            right: 15px;
            top: 11px;
            cursor: pointer;
        }

        .Score-block {
            color: $primary;
            font-size: 40px;
            font-weight: 800;
            letter-spacing: 0;
            line-height: 20px;
        }

        .gr-dot-main {
            position: absolute;
            right: 21px;
            display: flex;
            align-items: center;
            width: fit-content;
            bottom: 10px;
            color: $gray-900;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 34px;
            text-align: right;

            @include media-breakpoint-down(md) {
                right: 12px;
                bottom: 3px;
            }
        }

        .pt-txt {
            color: $gray-900;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 15px;
        }
    }
}

.gw-score-box {
    position: relative;
    height: 137px;
    width: 359px;
    border-radius: 10px;
    background-color: $white;
    padding: 20px 15px 20px 24px;
    color: $gray-900;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 25px;

    &:lang(ar) {
        margin-right: 0;
        margin-left: 25px;

        .eq-img {
            left: 15px;
            right: auto;
        }

        .gr-dot-main {
            right: auto;
            left: 21px;
        }
    }

    @include media-breakpoint-down(md) {
        display: none;
    }

    .green-dot {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: #0BBFB6;
        display: block;
        margin-right: 7px;
    }

    .gw-txt {
        color: $gray-900;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
    }

    .eq-img {
        position: absolute;
        right: 15px;
        top: 11px;
        cursor: pointer;
    }

    .Score-block {
        color: #5853C3;
        font-size: 48px;
        font-weight: 800;
        letter-spacing: 0;
        line-height: 20px;
    }

    .gr-dot-main {
        position: absolute;
        right: 21px;
        display: flex;
        align-items: center;
        width: fit-content;
        bottom: 10px;
        color: $gray-900;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 34px;
        text-align: right;
    }

    .pt-txt {
        color: $gray-900;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 15px;
    }
}

.deadline-wraps {
    margin-left: 40px;

    @include media-breakpoint-down(Sm) {
        margin-left: 0;
    }

    .title {
        font-size: 24px;
        text-transform: capitalize;
        font-weight: 700;
        line-height: 1;

        @include media-breakpoint-down(Sm) {
            font-size: 20px;
            letter-spacing: 0;
            line-height: 15px;
        }
    }

    .sub-title {
        font-size: 16px;

        // padding: 0;
        b {
            font-weight: 600;
        }
    }
}

.gray-cls {
    filter: grayscale(100%);
}

.blue-arrs {
    text-decoration: none;
    color: $primary;
    font-size: 14px;
}

.decoration {
    text-decoration: underline;
}

.btn-shadows {
    box-shadow: 0 3px 0 0 rgba(0, 0, 0, 0.21);
}

.blue-box-autopick {
    height: 45px;
    width: 70px;
    border-radius: 5px;
    background-color: $btn-isl !important;
    padding: 6px !important;

    &:hover {
        background-color: $btn-isl-hover !important;
        color: #28293D;
    }

    &:lang(mal) {
        .icon-auto-flash {
            display: none;
        }

        width: auto;
    }
}


.prz-wrap {
    display: flex;
    justify-content: space-around;
    // align-items: center;

    .min-max-prz {

        // min-width: 20px;
        // text-align: center;

    }

    .prz-name {
        width: 220px;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // text-align: end;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        text-transform: capitalize;
        text-align: right;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            font-size: 12px;
            margin-bottom: 5px;
        }
    }
}

.m-fix {
    margin: 0 auto;
    padding-bottom: 0;

    span {
        color: #999999;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: right;
    }
}

.mw-32p {
    width: 65px;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
        height: auto !important;
        margin: 5px 0;
    }
}

.bold-txt {
    font-weight: 700;
}

.prize-distribution-main {
    margin-bottom: 20px;
}

.week-names {
    margin-bottom: 20px;
}

.bottom-gw-disc {
    font-weight: normal;
    color: #999999;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    margin-top: -15px;
    // margin-bottom: 15px;

    @include media-breakpoint-down(md) {
        font-size: 12px;
    }
}

.banner-img-block {
    margin-top: 20px;
}



.m-fix-update {
    margin: 0 auto;
}

.border-update {
    .react-select__control {
        border-radius: 8px !important;
        color: #D0D0D0;
        border: 1px solid #d0d0d0 !important;
    }

    .react-select__single-value {
        color: #999999 !important;
    }
}

.m-b-fix {
    padding-bottom: 10px;
}

.flex-for-vlp {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fw-fix {
    font-weight: normal;
    color: $primary;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 34px;
    text-align: right;
    text-transform: capitalize;
}

.icon-arrows {
    height: 12px;
    margin-right: 5px !important;
}

.down-arrow-cls {
    border: 4px solid #E42020;
    width: 12px;
    height: 12px;
    border-top: none;
    border-left: none;
    transform: rotate(45deg);
}

.up-arrow-cls {
    border: 4px solid #0BBFB6;
    width: 12px;
    height: 12px;
    border-top: none;
    border-left: none;
    transform: rotate(-135deg);
}



.pd-fix {
    margin: 0;
}

#padding-fx-b {
    padding-bottom: 20px;

    @include media-breakpoint-down(md) {
        padding-bottom: 5px;
    }
}

.bt-match {
    //width: 485px;
    //border-top: 1px solid rgba($color: #979797, $alpha: 0.3);
    margin: 0 auto;

    span {
        position: relative;
        //  top: -10px;
        padding: 0 10px;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        margin: 0 auto;
    }
}



#rank-itm {
    margin-left: 10px;
    color: $gray-900;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}

.booster-block {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;


    #booster-block {
        cursor: pointer;
        height: 55.17px;
        width: 174.71px;
        border-radius: 9px;
        background-color: $white;
        display: flex;
        padding: 10px;
        align-items: center;
        // justify-content: space-between;

        img {
            margin-right: 10px;
            height: 32px;
            // width: 32px;
        }

        #bstr-details {
            width: 115px;
            color: $gray-900;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 19px;
            display: flex;
            flex-direction: column;

            .name-bstr {
                font-weight: bold;
            }
        }
    }
}

.grayscale-bx {
    filter: grayscale(100%);
    background-color: #EFF1F5 !important;
}

.h-fix-pp {
    height: 100%;
}

.w-fix-ribbons {
    min-width: 70px !important;
}

.d-flx-menu-top {
    display: flex;
    justify-content: space-between;
}

.ics-clr {
    color: $gray-900 !important;
    font-size: 20px !important;
}

.pfix-row {
    @include media-breakpoint-down(md) {
        margin: 0 !important;
    }
}

.fixt-bne {
    @include media-breakpoint-down(md) {
        margin-right: -20px;
    }
}

.hide-in-sm {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

.league-wk-name {
    @include media-breakpoint-down(md) {
        flex-direction: column;

        align-items: flex-start !important;

    }
}

.tls {
    @include media-breakpoint-down(md) {
        font-size: 10px !important;
    }
}

.multi-banner-wrap {
    @include media-breakpoint-down(md) {
        margin-right: -12px;
    }
}


@include media-breakpoint-down(sm) {
    .container-up {
        padding-top: 40px;

        .heading-leagues {
            align-items: center;

            .heading-main {
                font-size: 18px;

                &::before {
                    left: -12px;
                }
            }

            .react-select__single-value {
                font-size: 12px;
            }
        }
    }
}


.pr-t {
    position: relative;

    #trophy-ics {
        position: absolute;
        right: 5px;

        @include media-breakpoint-down(md) {
            right: -20px;
        }
    }
}


.flex-dc {
    display: flex;
    align-items: center;
}

@include media-breakpoint-down(sm) {
    .leaderboard-component {
        padding-top: 70px;
    }

    .leaderboard-header {
        margin-top: 6px;
    }

    #arrow-hide {
        display: none;
    }


    .roster-drawer {
        background-color: #182054 !important;
        width: 100% !important;
        max-width: 100% !important;
    }

    // .flex-dc {
    //     display: flex;
    //     align-items: center;
    // }

    .stats-head {
        display: flex;
        justify-content: space-between !important;

        .heading-main {
            font-size: 18px;
        }

        .ta-r {
            width: fit-content;

            .normal-nav {
                font-size: 12px;
            }
        }
    }

    #fit-cont {
        width: fit-content;
    }

    .heading-main {
        &::before {
            left: -12px;

            @include media-breakpoint-down(md) {
                left: -20px;
            }
        }
    }

    .clr-change {
        color: #C6C6C6;
    }

    .m-fix-update {
        width: 100% !important;
    }

    #mt-ids {
        margin-top: 25px;
    }

    .pt-cls {
        padding-top: 50px;
    }


    .red-circle {
        display: none;
    }

    .pl-fix {
        padding-left: 8px;
        font-size: 18px;
    }

    .mtb-all-stats {
        margin-bottom: 15px;
    }

    .wfix-btn {
        width: 144px !important;
        padding: 0 !important;

        @include media-breakpoint-down(md) {
            &:lang(mal) {
                font-size: 10px;
            }
        }
    }

    .home-save-btn {
        padding-bottom: 15px;
    }

    .hide-cl {
        display: none;
    }

    .white-bg-home {
        padding-top: 10px;
    }

    .mr-23d {
        margin-right: -15px;
    }
}

.box-effect {
    // background-color: white !important;
    animation: pulse 2s infinite;
}



@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba($white, 0.8);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba($white, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba($white, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba($white, 0.6);
        box-shadow: 0 0 0 0 rgba($white, 0.6);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba($white, 0);
        box-shadow: 0 0 0 10px rgba($white, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba($white, 0);
        box-shadow: 0 0 0 0 rgba($white, 0);
    }
}

.hide-cl {
    display: none;
}

.def-formation-btn {
    height: 44px;
    width: 190px;
    border-radius: 10px;
    margin: 0 auto;
    background-color: $btn-isl;

    &:hover,
    &:active,
    &:focus {
        background-color: $btn-isl-hover !important;
        color: #28293D !important;
    }
}

.jl-pb20 {
    padding-bottom: 20px;

    @include media-breakpoint-down(md) {
        padding-bottom: 0;
    }
}

.schedule-update {
    font-size: 12px !important;
    letter-spacing: -0.1px !important;

    img {
        margin-right: 5px;
    }
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
    --bs-gutter-x: 3rem !important;

    >* {
        padding: 0px !important;
    }

    @include media-breakpoint-down(md) {
        --bs-gutter-x: 1.5rem !important;
        margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
        margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;

        >* {
            padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
            padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
        }
    }
}

.row-leagues {

    >* {
        // padding-right: 0 !important;
        padding-left: calc(3rem * 0.5) !important;
    }

    @include media-breakpoint-down(md) {
        --bs-gutter-x: 1.5rem !important;
        margin-right: calc(-0.5 * var(--bs-gutter-x)) !important;
        margin-left: calc(-0.5 * var(--bs-gutter-x)) !important;

        >* {
            padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
            padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
        }
    }
}

.md-design {
    @include media-breakpoint-down(md) {
        >* {
            padding: 0 !important;
        }
    }
}

.multi-banner-wrap {
    padding-bottom: 10px;
}

.h-fix-fxhead {
    height: 26px !important;
}

@include media-breakpoint-up(md) {

    .stats-box-row,
    .gutter-fix {
        >* {
            padding-right: calc(1.5rem * 0.5) !important;
            padding-left: calc(1.5rem * 0.5) !important;

            &:first-child {
                padding-left: 0 !important;
            }

            &:last-child {
                padding-right: 0 !important;
            }

            &:lang(ar) {
                &:first-child {
                    padding-right: 0 !important;
                    padding-left: calc(1.5rem * 0.5) !important;
                }

                &:last-child {
                    padding-left: 0 !important;
                    padding-right: calc(1.5rem * 0.5) !important;
                }
            }
        }
    }
}

.mb-f {
    margin-bottom: 25px;

    @include media-breakpoint-down(md) {
        margin-bottom: 20px;
    }
}

.btn-fix {
    button {
        margin: 0 auto !important;
    }
}

.formation-modal-head {
    background-color: $primary !important;
    color: $white !important;
    padding: 15px 36px !important;
    border-radius: 12px 12px 0 0 !important;
}

.onboarding-gutter-fix {
    >* {
        padding-right: calc(1.5rem * 0.5) !important;
        padding-left: calc(1.5rem * 0.5) !important;
    }
}

.mb-fix {
    margin-bottom: 30px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-10 {
    margin-right: -10px;
}

.update-prf {
    width: 50%;
    color: $white !important;


    a {
        color: inherit !important;
    }

    &:hover {
        background-color: #182054 !important;
        border: none;
    }
}

.lbtn {
    border-radius: 0 0 0 20px !important;
    border-right: 1px solid $white !important;

}

.rbtn {
    border-radius: 0 0 20px 0 !important;
    border-left: 1px solid $white !important;

}

.mt-cls {
    margin-top: 30px;

    @include media-breakpoint-down(md) {
        margin-top: 20px;
    }
}

.pos-update {
    position: absolute;
    right: -20px;
}

.zero-pd {
    .form-control {
        width: 90%;
    }

    .react-select__control {
        width: 90%;
    }
}

.p-fix-join {
    padding-top: 15px !important;
}

.color-win {
    .container {
        padding-top: 0 !important;
    }
}

.game-features-cont {
    padding-top: 0 !important;
    margin-top: -5px !important;
}

.fxt-container {
    padding-top: 0 !important;
    margin-top: 30px !important;
}

.leaderboard-component {
    @include media-breakpoint-down(md) {
        padding-top: 30px !important;
    }
}

.white-stats {
    @include media-breakpoint-down(md) {
        padding-top: 0 !important;
    }
}

.lobby-container {
    @include media-breakpoint-down(md) {
        margin-top: 60px !important;
    }
}

.player-role-btn {
    .col {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
}

.no-data-view {
    color: $gray-900;
    font-size: 24px;
    text-align: center;
}

.row-fix {
    margin-right: 0 !important;
}


.htp-slider {
    position: relative;
    padding-top: 42px;

    .disc-block {
        position: absolute;
        height: 300px;
        width: 545px;
        opacity: 0.88;
        border-radius: 15px;
        background-color: #000000;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.25);
        bottom: -86px;
        right: 26px;
        padding: 30px;

        &:lang(mal),
        &:lang(ben) {
            height: 325px;
        }

        .grayscale1 {
            filter: grayscale(1);
        }

        .slick-slider {

            .slick-prev,
            .slick-next {
                width: 41px !important;
                height: auto !important;
                margin-top: 22% !important;

                &:lang(mal) {
                    margin-top: 24% !important;
                }
            }

            .slick-prev {
                left: 71% !important;
            }

            .slick-next {
                right: 0px !important;
            }
        }

        .slider-title {
            color: $white;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 34px;
            text-transform: uppercase;
            padding-bottom: 10px;

            &:lang(mal) {
                font-size: 18px;
            }
        }

        .slider-count {
            position: absolute;
            z-index: 1000;
            bottom: 17px;
            right: 85px;
            font-size: 14px;
        }

        .slide-disc {
            color: $white;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 24px;
            padding: 5px 0;

            &:lang(mal) {
                font-size: 10px;
            }
        }

        .clr-wt {
            a {
                color: $white !important;
                text-decoration: underline;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .disc-block {
            width: 100%;
            right: 0;
            padding: 15px;
            bottom: -36%;

            .slider-title {
                font-size: 20px;
                line-height: 34px;
            }

            .slide-disc {
                font-size: 14px;
                letter-spacing: 0;
                line-height: 20px;
            }




            .slick-slider {


                .slick-prev,
                .slick-next {
                    margin-top: 31% !important;

                    @media (max-width:400px) {
                        margin-top: 36% !important;
                    }
                }

                .slick-prev {
                    left: 62% !important;
                }
            }

            .slider-count {
                bottom: 40px !important;
                right: 70px !important;

                @media (max-width:400px) {
                    right: 18% !important;
                    bottom: 9% !important;
                }
            }

            .clr-wt {
                padding-top: 7px;
            }
        }

        .slide-img {
            width: 100% !important;
        }

    }
}

.btm-banner-container {
    @include media-breakpoint-down(md) {
        padding-top: 0 !important;
    }
}

.lineup-alter {
    padding-bottom: 35px !important;
}

.pbfix-tms {
    padding: 15px !important;
}

.img-width {
    width: 100%;
}

.highcharts-container {
    width: 90% !important;
}

.p-fix-leagues>* {
    &:first-child {
        padding-left: 0rem !important;
        height: 100%;

        @include media-breakpoint-down(md) {
            padding-left: 5px !important;
            padding-bottom: 15px !important;
        }
    }
}

.my-team-gtf>* {
    &:last-child {
        padding-left: 1.75rem !important;

        @include media-breakpoint-down(md) {
            padding-left: 0.75rem !important;
        }
    }
}

.color-3s {
    color: $gray-900 !important;
}

.m-fix {
    margin: 0 auto;
    min-width: 620px;

    @include media-breakpoint-down(md) {
        min-width: 300px;
    }
}

.home-edit {
    top: -30px !important;

    @include media-breakpoint-down(md) {
        top: -7px !important;
    }

    .cell {
        max-width: 150px;
    }
}

.pd-fix-rwlg>* {
    &:first-child {
        padding-left: 0 !important;
    }
}

.dn-home {
    @include media-breakpoint-down(md) {
        height: 5px;
        padding-top: 0 !important;
    }
}

.pt-fx {
    @include media-breakpoint-down(md) {
        padding-top: 30px !important;
    }
}

.h230-p {
    @include media-breakpoint-down(md) {
        height: 230px !important;
    }
}

.pt28-p {
    padding: 25px 0 !important;

    @include media-breakpoint-down(md) {
        padding: 20px !important;
        padding-top: 45px !important;

        .native-slider-wrap>div {
            margin-top: 5px !important;
        }
    }
}

.roster-drawer-wrap>div {
    top: 0 !important;
}

.custom-modal {
    .close-btn {
        @include media-breakpoint-down(md) {
            top: -30px !important;
            right: -15px !important;
        }
    }
}

.mid-gap-createlg>* {
    &:first-child {
        padding-right: 10px !important;
    }

    &:last-child {
        padding-left: 10px !important;
    }
}

.jl-txt {
    width: 100%;
    background-color: white;
    text-align: center;
    color: $gray-900;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 14px;
    text-transform: uppercase;
}

.dash-league-mtym {
    width: 100%;
    padding: 20px;
    zoom: 78%;
    background-color: white;
    border-radius: 0 0 5px 5px !important;

    .lineup-info-leaderboard {
        height: 100% !important;
    }
}

.d-block {
    display: block;
}

.plc-modal {
    .modal-content {
        min-width: 475px;
        border-radius: 15px;

        @include media-breakpoint-down(md) {
            min-width: 335px;
        }

        .player-card-top {
            border-radius: 15px 15px 0 0 !important;
        }

        // overflow: hidden;

        .close-btn {
            height: 26px;
            width: 26px;
            background-color: $primary;
            border-radius: 50%;
            color: $white;
            right: -30px;
            left: unset;
            padding: 6px;
            top: -30px;

            @include media-breakpoint-down(md) {
                top: -20px !important;
                right: -5px !important;
                background-color: transparent;
                color: $white;
            }
        }
    }
}

.pup-fix {
    @include media-breakpoint-down(md) {
        padding-top: 0 !important;
    }
}

.language-change-block {
    display: flex;
    flex-direction: column;

    .footer-link-list {
        display: flex;
        align-items: center;

        span {
            padding: 0 10px;

            &:first-child {
                padding-left: 0 !important;
            }
        }


    }
}

.img-revert {
    &:lang(ar) {
        transform: scaleX(-1)
    }
}

.disable-scrolling {
    overflow: hidden !important;
}

.hide-img {
    display: none;
}

.elipsis-text {
    width: 135px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wfix {
    max-width: 1180px;
    margin: 0 auto;
    padding-bottom: 18px !important;
}

.color-change {
    color: $primary;

    &:hover {
        color: black;
        background-color: $red;
    }
}

.transparent-img {
    opacity: 0;
}

.red-txt {
    color: $red;
    font-size: 10px;
    position: absolute;
}

.main-myteam {
    .stadium-component {
        margin-bottom: 30px;
    }
}

.week-prz {
    height: 100%;
}

.winners-container {
    border: 1px solid #E4E7EE;
    // animation-fill-mode: forwards;
    width: 65%;
    // height: 100vh;
    margin: 0 auto;
    border-radius: 16px;
    padding: 30px 40px 23px;

    .title-win {
        color: $gray-900;
        text-transform: uppercase;
        font-size: 18px;
        font-style: italic;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 20px;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        padding: 20px 20px 13px;
    }
}

.dflex-table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .table-headings {
        opacity: 0.6;
        color: $gray-900;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: right;
    }

    .table-left {
        color: $gray-900;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        min-width: 25px;
        text-align: center;
    }

    .table-right {
        color: $gray-900;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 20px;
        text-align: right;
    }
}

.heads {
    margin: 0px 0 20px;
}

.btm-arrow-st {
    text-align: center;

    img {
        cursor: pointer;
    }
}

.fluid-winners {
    width: 100%;
    margin-top: 25px;
}

.reverse-arrows {
    transform: rotate(180deg);
}

.hw-fix {
    >div {
        margin-bottom: 30px;
    }

    >&:last-child {
        margin-bottom: 0 !important;
    }
}

.slider-container-wn {
    margin-top: 20px;
    // padding: 12px 0;
    position: relative;

    @include media-breakpoint-down(md) {
        margin: 20px -15px 0;
    }

    &::before,
    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        opacity: 0.16;
        bottom: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 49.43%, rgba(0, 0, 0, 0) 100%);
    }

    &::before {
        top: 1px;
    }
}

.month-names {
    opacity: 0.8;
    color: #333333;
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.selected-mth {
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: #EB4A3C;
        bottom: 0;
        left: 0;
    }
}

.display-hide {
    display: none !important;
}

.mt-30p {
    margin-top: -30px;
}

.fz-sml {
    // font-size: 13px;
}