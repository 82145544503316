.profile-container {
    color: $dark-color;
    max-width: 780px;
    min-height: 500px;
    margin: 30px auto;
    border-radius: 4px;
    background-color: $stats-box-bg;
    position: relative;

    // z-index: $zindex-fixed - 1;
    @include media-breakpoint-down(md) {
        margin-top: 0;
        border-radius: 0;
        @include container-margin-none;
    }
}

.profile-top-area {
    @include clearfix;
    height: 304px;
    height: 100%; // Coin update
    position: relative;
    padding: 102px 23px 50px 23px;
    background: $primary;

    @include media-breakpoint-down(md) {
        padding-top: 125px;
    }

    .top-bg {
        border-radius: 3px 3px 0 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 208px;
        // background: $secondary;
        background: linear-gradient(to left, $secondary, $secondary);
        background-size: cover;

        // z-index: -1;
        @include media-breakpoint-down(md) {
            border-radius: 0;
            background-size: auto;
        }
    }

    .icnedit {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        background: url(../img/edit-ic.png) no-repeat center;
        background-size: 20px;

        @include media-breakpoint-down(md) {
            // display: none;
        }
    }
}

.profile-form {
    max-width: 638px;
    margin: 53px auto 0;
    padding: 0 23px;

    .custom-gutter {
        width: 94%;
        margin-right: -7px;
        margin-left: -7px;

        >.col,
        >[class*="col-"] {
            padding-right: 7px;
            padding-left: 7px;
        }
    }

    .change-pwd-btn {
        height: 44px;
        width: 90%;
        border: 0.94px solid #D5D5D5;
        border-radius: 4px;
        background-color: #CACACA;
        margin-bottom: 1rem;
        color: rgba($gray-999, 0.5);
        padding: 8px 12px;

        .overflow-hidden {
            padding: 4px 0;
        }

        .btn-sm {
            min-width: 60px;
            float: right;
            font-size: 12px;
            text-transform: capitalize;
            box-shadow: none !important;
            background-color: $secondary-bg-color !important;
        }
    }

    .submit-btn {
        max-width: 300px;
        margin: 40px auto 54px;

        @include media-breakpoint-down(xs) {
            max-width: 100%;
        }
    }

    &.p-b {
        padding-bottom: 40px;
    }
}

.profile-details-wrap {
    display: block;
    max-width: 486px;
    margin: auto;
    height: 201px;
    height: 140px; // Coin update
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.21);
    line-height: 1.3;
    position: relative;
    padding-top: 75px;

    .fullname {
        height: 24px;
        text-align: center;
        font-size: 18px;
        font-weight: $font-weight-bold;
    }

    .username {
        height: 16px;
        text-align: center;
        font-size: 12px;
        color: $gray-999;
    }

    .profile-avtar {
        width: 110px;
        height: 110px;
        border-radius: 100%;
        background-color: $dark-color;
        box-shadow: 0 0 0 0.5px $dark-color;
        position: relative;
        position: absolute;
        top: -55px;
        left: 50%;
        margin-left: -55px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
        }

        .camera-ic {
            display: block;
            width: 26px;
            height: 26px;
            border-radius: 100%;
            background: url(../img/camera-ic.png) no-repeat;
            background-size: cover;
            position: absolute;
            bottom: -13px;
            left: 50%;
            margin-left: -13px;
            cursor: pointer;
        }
    }

}

.profile-heading {
    height: 30px;
    background-color: $primary;
    font-size: 12px;
    font-weight: $font-weight-medium;
    color: $white !important;
    text-align: center;
    text-transform: uppercase;
    padding: 7px 0;
    // margin: 32px 0;
    margin-bottom: 20px;

    .profile-form,
    &+.profile-form {
        margin-top: 0;
    }

    .profile-form-title {
        // color: $gray-666;
        // font-size: 16px;
    }
}

.profile-row {
    padding-bottom: 22px;

    .label-text {
        font-size: 14px;
        // font-weight: 600;
        color: $gray-999;
        min-height: 18px;
    }

    .value-text {
        font-size: 16px;
        font-weight: $font-weight-semibold;
        color: #333333;
        min-height: 21px;

        &.gender-label {
            text-transform: capitalize;
        }
    }
}

.profile-back-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
    color: $white !important;
    font-size: 20px;
    text-align: center;
    padding: 4px 0;

    @include media-breakpoint-down(md) {
        z-index: $zindex-fixed;
        font-size: 25px;
        position: fixed;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        font-size: 20px;
        padding: 16px 0;
        text-align: center;

        &.white {
            color: $body-color !important;
        }
    }
}



.profile-page {
    @include media-breakpoint-down(md) {
        body {
            background-color: $white;

            .profile-container {
                box-shadow: none;
            }
        }
    }

    @include media-breakpoint-down(md) {

        .mobile-header,
        .header-placeholder {
            display: none !important;
        }
    }

    .profile-toolbar {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 15px;
        z-index: $zindex-fixed;
        color: $white;
        background-size: auto;
        transition: background .1s ease-out;
        // background: $secondary;
        background: linear-gradient(218.77deg, darken($primary, 45%) 0%, $primary 100%);
        height: 50px;

        &.white {
            background: $white;
            color: $body-color;
            border-bottom: solid 1px rgba(102, 102, 102, 0.12);
        }

        .editicon {
            background: url(../img/edit-ic.png) no-repeat center;
            background-size: 22px;
        }

        .icon-arrow-back {
            color: $white;
        }
    }

    .profile-top-area {
        padding-top: 120px;
    }

    .bi-icon {
        float: right;
        width: 24px;
        height: 24px;
        background: url(../img/profile-edit.png) no-repeat center;
        margin: -5px 0;
        background-size: 12px;
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
        }
    }
}

.profile-view-mode {
    color: $gray-999;
    background-color: $white;
    border-radius: 0 0 10px 10px;
    overflow: hidden;

    .profile-form {
        max-width: 534px;
    }
}

:lang(ar) {
    .profile-top-area {
        .icnedit {
            right: auto;
            left: 10px;
        }
    }

    .profile-form {
        .change-pwd-btn {

            .btn-sm {
                float: left;

            }
        }
    }

    .profile-details-wrap {
        .coin-wrap {
            .coin-box {
                float: right;
            }

            .add-coin-btn {
                right: auto;
                left: 20px;
                float: right;

                img {
                    margin-right: auto;
                    margin-left: 3px;
                }
            }
        }
    }

    .profile-back-btn {
        left: auto;
        right: 10px;
        transform: scaleX(-1);

        @include media-breakpoint-down(md) {
            left: auto;
            right: 0;
        }
    }

    .profile-page {
        .bi-icon {
            float: left;
        }
    }
}

.input-mobile-small {
    font-size: 10px;
    line-height: 13px;
    display: block;
    margin-top: 4px;
}